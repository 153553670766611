import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import clsx from 'clsx';

import { joiResolver } from '@hookform/resolvers/joi';
import { useEffect } from 'react';
import { Title } from '@/components/Typography/Title';
import { TextInput } from '@/components/TextInput';
import { Button } from '@/components/Button';
import { CircularTitle } from '@/components/CircularTitle';
import { CircularRequired } from '@/components/CircularRequired';
import { AuthContent } from '@/components/AuthContent';
import {
  AcceptInvitationFormData,
  acceptInvitationFormSchema,
} from '@/forms/acceptInvitationForm';
import { useAcceptInvitationMutation, useGetInvitationQuery } from '../../api/authApi';
import { ROUTES } from '@/constants/routes';
import { showErrorToast } from '@/utils/toast';

const AcceptInvitation = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const [acceptInvitation, { isLoading, isSuccess }] = useAcceptInvitationMutation();

  const {
    data: invitationDetails,
    isLoading: isLoadingInvitation,
    isError: isLoadingInvitationError,
  } = useGetInvitationQuery({
    token: token ?? '',
  });

  const { handleSubmit, control } = useForm<AcceptInvitationFormData>({
    resolver: joiResolver(acceptInvitationFormSchema),
    values: {
      email: invitationDetails?.email ?? '',
      name: invitationDetails?.name ?? '',
      surname: invitationDetails?.surname ?? '',
      password: '',
      confirmPassword: '',
    },
    defaultValues: {
      name: '',
      surname: '',
      email: '',
      password: '',
      confirmPassword: '',
    },
  });

  useEffect(() => {
    if (isSuccess) {
      navigate(ROUTES.VERIFIED_ACCOUNT);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isLoadingInvitationError) {
      showErrorToast(t('CreateAccount.errors.notFound'));
    }
  }, [isLoadingInvitationError]);

  const onSubmit = (data: AcceptInvitationFormData) => {
    acceptInvitation({
      password: data.password,
      token: token ?? '',
    });
  };

  return (
    <div className="flex justify-end relative">
      <CircularTitle>{t('CreateAccount.createAccount')}</CircularTitle>
      <CircularRequired />
      <AuthContent>
        <div className="background_page_content border-t-3 border-t-red-primary">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div
              className={clsx(
                'grid-cols-1 grid grid-rows-auto w-full',
                'xl:gap-3px xl:flex-row pt-0'
              )}
            >
              <Title
                level="h2"
                className="px-25px py-7.5 md:px-7.5 xl:py-[42px] xl:px-10"
              >
                {t('CreateAccount.personalInfo')}
              </Title>
              <div
                className={clsx(
                  'grid-cols-1 grid grid-rows-auto w-full xl:grid-cols-2 gap-3px'
                )}
              >
                <TextInput
                  label={t('CreateAccount.labels.name')}
                  name="name"
                  disabled
                  control={control}
                />
                <TextInput
                  label={t('CreateAccount.labels.surname')}
                  name="surname"
                  disabled
                  control={control}
                />
              </div>

              <Title
                level="h2"
                className="px-25px py-7.5 md:px-7.5 xl:py-[42px] xl:px-10 text-left"
              >
                {t('CreateAccount.loginInfo')}
              </Title>
              <TextInput
                label={t('CreateAccount.labels.email')}
                name="email"
                disabled
                control={control}
              />
              <div
                className={clsx(
                  'grid-cols-1 grid grid-rows-auto w-full xl:grid-cols-2 gap-3px'
                )}
              >
                <TextInput
                  label={t('CreateAccount.labels.password')}
                  name="password"
                  isPassword
                  isRequired
                  control={control}
                />
                <TextInput
                  label={t('CreateAccount.labels.confirmPassword')}
                  name="confirmPassword"
                  isPassword
                  isRequired
                  control={control}
                />
              </div>
            </div>
            <Button
              isSubmit
              className="w-full"
              disabled={isLoading || isLoadingInvitation || !!isLoadingInvitationError}
            >
              {t('SignIn.createAccount')}
            </Button>
          </form>
        </div>
      </AuthContent>
    </div>
  );
};

export default AcceptInvitation;

import clsx from 'clsx';
import { Spinner } from '@/components/Spinner';

export interface ICarsStatsProps {
  icon: string;
  value: number | undefined;
  mainText: string;
  subText?: string;
  isLoading?: boolean;
}

const CardStats = ({
  icon,
  value,
  mainText,
  subText,
  isLoading,
}: ICarsStatsProps): JSX.Element => {
  const vals = value?.toString().split('.') || [];

  return (
    <div
      className={clsx(
        'bg-white-primary h-full py-[20px] xl:py-[40px]',
        'flex flex-col justify-center items-center'
      )}
    >
      <img src={icon} width="48" height="48" alt="" />

      <div className=" py-[10px]">
        {isLoading ? (
          <Spinner size="medium" />
        ) : (
          <>
            {vals[0] && <span className="cardstat_number">{vals[0]}</span>}
            {vals[1] && <span className="cardstat_fraction">.{vals[1]}</span>}
          </>
        )}
        {subText && <span className="cardstat_unit p-[2px]">{subText}</span>}
      </div>
      <div className={clsx('cardstat_maintext w-full text-center')}>{mainText}</div>
    </div>
  );
};

export default CardStats;

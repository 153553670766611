import React, { useEffect, useRef, useState } from 'react';
import { ControllerProps, FieldValues, Controller } from 'react-hook-form';
import clsx from 'clsx';
import { Listbox } from '@headlessui/react';
import { useDebouncedCallback } from 'use-debounce';
import { ReactComponent as ChevronDown } from '@/assets/icons/chevron-down.svg';
import { Option } from '@/components/SelectInput/SelectInput';
import { Spinner } from '@/components/Spinner';

interface IProps<T extends FieldValues> extends Omit<ControllerProps<T>, 'render'> {
  label: string;
  placeholder: string;
  onChangeHandler: (str: string) => boolean;
  options: Option[];
  isRequired?: boolean;
  debounced?: boolean;
  isLoading?: boolean;
  resetField?: () => void;
}

const timeout = 700;

const DynamicListbox = <T extends FieldValues>({
  name,
  label,
  placeholder,
  onChangeHandler,
  options,
  isRequired,
  isLoading,
  control,
  debounced,
  resetField,
}: IProps<T>): JSX.Element => {
  const [searchValue, setSearchValue] = useState('');
  const [isOptionsVisible, setIsOptionsVisible] = useState(false);
  const optionsListRef = useRef<HTMLDivElement | null>(null);

  const debouncedOnChange = useDebouncedCallback(e => {
    onChangeHandler(e.target.value);
  }, timeout);

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (debounced) {
      debouncedOnChange(e);
    } else {
      onChangeHandler(e.target.value);
    }

    setSearchValue(e.target.value);
    if (!e.target.value && resetField) {
      resetField();
    }
    setIsOptionsVisible(true);
  };

  const getTitleValue = (str: string) =>
    options?.find((item: Option) => item.value === str)?.label;

  const onOpenList = (open: boolean) => {
    if (!open) {
      const selected = onChangeHandler(searchValue ?? '');
      if (!selected) {
        setSearchValue('');
        if (resetField) resetField();
      }
    }
    setIsOptionsVisible(!open);
  };

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (
        isOptionsVisible &&
        optionsListRef.current &&
        !optionsListRef.current.contains(event.target)
      ) {
        const selected = onChangeHandler(searchValue ?? '');
        if (!selected) {
          setSearchValue('');
          if (resetField) resetField();
        }
        setIsOptionsVisible(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [optionsListRef, isOptionsVisible, searchValue]);

  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        useEffect(() => {
          setSearchValue(getTitleValue(value) || '');
          setIsOptionsVisible(false);
        }, [value]);

        return (
          <Listbox value={value} onChange={onChange}>
            {({ open }) => (
              <>
                <label
                  htmlFor={name}
                  className={clsx(
                    'input_label',
                    'relative py-3 px-25px md:px-7.5 xl:px-10 xl:py-4 text-left block',
                    'border-white-primary',
                    (open || isOptionsVisible) && 'background_tetriary shadow-xl',
                    !open && !isOptionsVisible && 'bg-white-primary'
                  )}
                >
                  <p className="truncate">{label}</p>
                  {isRequired && '*'}
                  <div className="flex items-center w-full">
                    <input
                      value={searchValue}
                      name={name}
                      id={name}
                      type="text"
                      className={clsx(
                        'font_primary_base',
                        'w-full h-8 mt-1 outline-none bg-transparent',
                        'xl:h-12 pr-[5px]'
                      )}
                      onChange={handleChangeInput}
                      placeholder={placeholder}
                    />

                    <Listbox.Button
                      className={clsx(
                        'listbox_button_caption',
                        'outline-none bg-transparent flex items-center justify-between',
                        'relative mt-1 h-8 xl:h-12'
                      )}
                      onClick={() => onOpenList(open)}
                    >
                      <ChevronDown className={clsx(open && 'rotate-180')} />
                    </Listbox.Button>
                  </div>

                  <div
                    className={clsx(
                      !isOptionsVisible && 'hidden',
                      'absolute top-[100%] pt-0 px-25px md:px-7.5 xl:px-10 left-0',
                      'background_tetriary xl:pr-[30px] w-full z-50',
                      'xl:translate-y-[-6%] translate-y-[-4%]',
                      open && 'shadow-xl'
                    )}
                    ref={optionsListRef}
                  >
                    <Listbox.Options
                      static
                      className={clsx(
                        'relative overflow-auto max-h-[250px] mb-7.5',
                        'xl:scrollbar xl:pr-[19px]'
                      )}
                    >
                      {isLoading ? (
                        <Spinner size="small" />
                      ) : (
                        options?.map((item, id) => (
                          <Listbox.Option
                            key={id}
                            value={item.value}
                            className={clsx(
                              'font_primary_base',
                              'py-18px border-b-gray-tetriary border-b-[1px]',
                              'hover:border-red-primary cursor-pointer',
                              'hover:text-red-primary z-50 normal-case truncate'
                            )}
                            onClick={() => {
                              setSearchValue(getTitleValue(item.value) || '');
                              setIsOptionsVisible(false);
                            }}
                          >
                            {item.label}
                          </Listbox.Option>
                        ))
                      )}
                    </Listbox.Options>
                  </div>
                </label>
                {!!error && (
                  <div
                    className={clsx(
                      'bg-red-tetriary border-l-3 text-13px pl-25px border-red-primary',
                      'md:pl-7.5 py-2.5 md:text-16px xl:pl-12.5 xl:text-18px',
                      'text-red-secondary text-left'
                    )}
                  >
                    {error.message}
                  </div>
                )}
              </>
            )}
          </Listbox>
        );
      }}
    />
  );
};

export default DynamicListbox;

import { useTranslation } from 'react-i18next';
import { Modal } from './Modal';

interface PhotoPreviewModalProps {
  photo: string;
  title: string;
  isOpen: boolean;
  onClose: () => void;
}

export const PhotoPreviewModal = ({
  photo,
  title,
  isOpen,
  onClose,
}: PhotoPreviewModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="small">
      <img
        src={`data:image;base64, ${photo}`}
        className="w-auto max-w-[90%] max-h-[90%] object-cover m-auto mb-[48px]"
        alt={t('Signatories.imageLabel')}
      />
    </Modal>
  );
};

import clsx from 'clsx';
import React from 'react';
import { Button } from '@/components/Button';

interface DetailsBlockProps {
  label: string;
  text?: string | null;
  labelClassName?: string;
  textClassName?: string;
  children?: React.ReactNode;
  contentClassName?: string;
  action?: () => void;
  actionLabel?: string;
}

export const DetailsBlock = ({
  label,
  text,
  labelClassName,
  textClassName,
  children,
  contentClassName,
  action,
  actionLabel,
}: DetailsBlockProps) => {
  return (
    <div
      className={clsx(
        'bg-white-primary mb-[3px] last:mb-0 pb-4',
        'border-b-3 border-b-white-secondary',
        'sm:border-r-0 xl:border-r-0',
        'md:border-r-3 md:border-r-white-secondary',
        'xl:border-r-3 xl:border-r-white-secondary',
        contentClassName
      )}
    >
      <p
        className={clsx(
          'input_label !leading-6',
          'py-3 px-25px md:px-7.5 text-left block xl:px-10 xl:py-4',
          labelClassName
        )}
      >
        {label}
      </p>
      <div
        className={clsx(
          'font_primary_base',
          'w-full !h-auto mt-1 bg-transparent break-all',
          'md:px-7.5 text-left xl:px-10 pr-[5px] px-25px',
          textClassName
        )}
      >
        {text || children}
      </div>
      {action && (
        <div className="px-25px md:px-7.5 xl:px-10 mt-[20px] mb-[10px]">
          <Button
            type="button"
            className={clsx('button_caption !py-[5px] print-hide')}
            onClick={() => action()}
          >
            {actionLabel}
          </Button>
        </div>
      )}
    </div>
  );
};

import Joi from 'joi';

export interface TransferToUserData {
  userId: string;
  userToken: string;
}

export const transferToUserFormSchema = Joi.object({
  userId: Joi.string().allow('').trim(),
  userToken: Joi.string().allow('').trim(),
}).required();

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Title } from '@/components/Typography/Title';
import { SharedDocumentSinglePartyPhoto } from '@/components/SingleParty/SinglePartyPhoto';
import { getConvertedDateTimestamp } from '@/utils/dates';
import { TableCellPartyStatus } from '@/components/TableCells/TableCells';
import { SharedDocument } from '@/types/document';

type SingleDocumentDetailsParams = {
  token: string;
  activeDocument: SharedDocument;
};

const SharedDocumentSignatures = ({
  token,
  activeDocument,
}: SingleDocumentDetailsParams) => {
  const { t } = useTranslation();

  return (
    <div className="bg-white-primary flex flex-1 flex-col">
      <Title level="h4" className="content-layout background_page_content">
        {t('SingleDocumentDetails.labels.parties')}
      </Title>
      <div
        className={clsx(
          'bg-white-primary flex-1',
          'block max-2xl:h-[150px] lg:min-h-[750px]'
        )}
      >
        {activeDocument.signatories?.map((party, idx) => (
          <div
            className={clsx(
              'flex justify-between items-start py-[40px] 2xl:px-7.5 3xl:px-10',
              'text_primary border-b-3 border-b-white-secondary relative',
              'max-sm:flex-col max-xl:px-7.5 max-sm:p-[25px] bg-white-primary',
              'max-2xl:px-10'
            )}
            key={idx}
          >
            <div
              className={clsx(
                'w-[150px] h-[150px] self-center background_secondary max-sm:mb-4',
                'max-sm:self-start max-sm:w-[107px] max-sm:h-[107px]',
                'relative min-w-[160px]'
              )}
            >
              {party.photo && (
                <SharedDocumentSinglePartyPhoto
                  token={token}
                  subDocumentId={activeDocument.id}
                  email={party.email}
                  signatoryName={`${party.name} ${party.surname}`}
                />
              )}
            </div>

            <div
              className={clsx(
                'max-2xl:ml-[40px] 3xl:ml-[40px] 2xl:ml-[20px] mr-auto max-sm:m-0',
                'max-sm:w-full'
              )}
            >
              <h5
                className={clsx('party_name_title', 'mb-[15px] break-all max-w-[250px]')}
              >
                {party.name} {party.surname}
              </h5>
              <div
                className={clsx(
                  '3xl:max-w-fit w-full mb-[20px] background_secondary py-[7px] px-[10px]',
                  'max-sm:w-full max-sm:truncate 2xl:max-w-[190px] overflow-hidden',
                  'text-ellipsis'
                )}
              >
                <span className="text_primary text-15px">{party.email}</span>
              </div>
              <div className="flex text-15px">
                <div className="font-bold leading-7 mr-[6px]">
                  <p>{t('PartyApproval.requested')}:</p>
                  <p>{t('PartyApproval.verified')}:</p>
                </div>
                <div className="leading-7">
                  <p>{getConvertedDateTimestamp(party.requestedAt)}</p>
                  {party.signedAt ? (
                    <p>{getConvertedDateTimestamp(party.signedAt)}</p>
                  ) : (
                    '-'
                  )}
                </div>
              </div>
            </div>
            <div className="flex flex-col items-end max-sm:absolute right-6 max-sm:top-5">
              <div
                className={clsx(
                  'flex items-center mb-[21px] mt-[6px] text-right',
                  'max-sm:mb-[17px]'
                )}
              >
                <TableCellPartyStatus status={party.status} isExtended />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SharedDocumentSignatures;

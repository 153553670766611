import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';

import { ReactComponent as Hamburger } from '@/assets/icons/hamburger.svg';
import { ReactComponent as CloseIcon } from '@/assets/icons/close-big.svg';
import { DashboardSidebar } from '../DashboardSidebar';
import { useGetUserDataQuery } from '@/api/userApi';
import { acronym } from '@/utils/acronym';
import { Title } from '../Typography/Title';
import { useIsMobile, useLockBodyScroll } from '@/utils/hooks';
import SearchInput from '@/components/SearchInput/SearchInput';

const DashboradMobileMenu = () => {
  // const { t } = useTranslation();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const isMobile = useIsMobile();

  const location = useLocation();

  const { data: profileDetails } = useGetUserDataQuery();

  useEffect(() => {
    setIsMenuOpen(false);
  }, [location]);

  const handleButtonClick = () => setIsMenuOpen(isOpen => !isOpen);

  useLockBodyScroll(isMenuOpen && isMobile);

  return (
    <div className="xl:hidden">
      <button
        type="button"
        className="cursor-pointer xl:hidden"
        onClick={handleButtonClick}
        aria-label="Menu"
      >
        <Hamburger className="w-7.5 sm:w-10 fill-gray-primary hover:fill-red-primary" />
      </button>
      {isMenuOpen && (
        <div
          className={clsx(
            'fixed flex top-0 bottom-0 left-0 right-0 z-10',
            'max-md:flex-col-reverse background_primary justify-end'
          )}
        >
          <DashboardSidebar />
          <span className="md:hidden">
            <SearchInput mode="sidebar" />
          </span>
          <div
            className={clsx(
              'flex text-white-primary flex-1 px-10 flex-col',
              'justify-start items-center md:border-l-[1px] mb-11 px-[25px]',
              'max-sm:mb-0 md:mb-[55px] border-gray-tetriary max-sm:flex-initial',
              'sm:px-10'
            )}
          >
            <div
              className={clsx(
                'flex flex-row items-center md:flex-col w-full max-md:mb-10',
                'md:mt-[200px] max-md:mt-[60px]'
              )}
            >
              <div
                className={clsx(
                  'background_tetriary flex items-center justify-center mr-5 md:mr-0',
                  'rounded-full w-16 h-16 md:w-32 md:h-32 text-[23px] md:mb-8'
                )}
              >
                <span
                  className="inline-block px-3 py-[10px]
                    font-bold text_primary uppercase"
                >
                  {acronym(profileDetails?.name, profileDetails?.surname)}
                </span>
              </div>
              <Title
                level="h4"
                className={clsx(
                  'dashboard_mobile_menu_title',
                  'text-center md:mb-32',
                  'max-md:text-left'
                )}
              >
                <span
                  className={clsx(
                    'block md:inline-block break-all whitespace-nowrap',
                    'overflow-hidden text-ellipsis max-w-[300px]'
                  )}
                >
                  {profileDetails?.name}
                </span>{' '}
                <span
                  className={clsx(
                    'block md:inline-block break-all whitespace-nowrap',
                    'overflow-hidden text-ellipsis max-w-[300px]'
                  )}
                >
                  {profileDetails?.surname}
                </span>
              </Title>
            </div>
            <span className="max-md:hidden xl:hidden w-full">
              <SearchInput mode="sidebar" />
            </span>
          </div>
          <button
            onClick={handleButtonClick}
            type="button"
            className="fixed sm:right-10 right-25px top-12.5 max-md:top-[26px]"
            aria-label="Close"
          >
            <CloseIcon className="fill-white-primary max-md:w-[18px]" />
          </button>
        </div>
      )}
    </div>
  );
};

export default DashboradMobileMenu;

import { Routes, Route, Navigate } from 'react-router-dom';
import { ROUTES } from '@/constants/routes';
import AdminDashboard from '@/pages/admin/AdminDashboard';
import Users from '@/pages/admin/Users';
import Statistics from '@/pages/admin/Statistics';
import Activity from '@/pages/admin/Activity';
import Logout from '@/pages/auth/Logout';
import Dashboard from '@/pages/dashboard/Dashboard';
import DashboardView from '@/pages/dashboard/DashboardView';
import Help from '@/pages/system/Help';
import UserDocuments from '@/pages/documents/UserDocuments';
import CreateDocument from '@/pages/documents/CreateDocument';
import SingleDocumentDetails from '@/pages/documents/SingleDocumentDetails';
import Version from '@/pages/system/Version';
import Account from '@/pages/user/Account';
import { useGetUserDataQuery } from '@/api/userApi';
import AllDocuments from '@/pages/admin/AllDocuments';
import UserDetails from '@/pages/admin/UserDetails';
import Invitations from '@/pages/admin/Invitations';
import { UserRole } from '@/types/user';

const AuthenticatedRoutes = () => {
  const { data: profileDetails } = useGetUserDataQuery();
  const isAdmin = profileDetails?.role === UserRole.Admin;

  return profileDetails?.role ? (
    <Routes>
      <Route path={ROUTES.HOME} element={<DashboardView />}>
        <Route index element={isAdmin ? <AdminDashboard /> : <Dashboard />} />
        <Route path={ROUTES.ACCOUNT} element={<Account />} />
        {isAdmin && (
          <>
            <Route path={ROUTES.DOCUMENTS} element={<AllDocuments />} />
            <Route path={ROUTES.USERS} element={<Users />} />
            <Route path={ROUTES.INVITATIONS} element={<Invitations />} />
            <Route path={ROUTES.USER_ACCOUNT} element={<UserDetails />} />
            <Route path={ROUTES.STATISTICS} element={<Statistics />} />
            <Route path={ROUTES.ACTIVITY} element={<Activity />} />
          </>
        )}
        {!isAdmin && (
          <>
            <Route path={ROUTES.CREATE_DOCUMENT} element={<CreateDocument />} />
            <Route path={ROUTES.DOCUMENTS} element={<UserDocuments />} />
          </>
        )}
        <Route path={ROUTES.DOCUMENT_DETAILS} element={<SingleDocumentDetails />} />
        <Route path={ROUTES.LOGOUT} element={<Logout />} />
        <Route path={ROUTES.HELP} element={<Help />} />
        <Route path={ROUTES.VERSION} element={<Version />} />
      </Route>
      <Route path="*" element={<Navigate to={ROUTES.HOME} />} />
    </Routes>
  ) : null;
};

export default AuthenticatedRoutes;

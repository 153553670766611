import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { Title } from '@/components/Typography/Title';
import { TextInput } from '@/components/TextInput';
import { PageTitle } from '@/components/Typography/Title/PageTitle';
import { Spinner } from '@/components/Spinner';
import {
  useDeleteUserMutation,
  useGetUserProfileQuery,
  useRestUserCredentialsMutation,
} from '@/api/adminUserApi';
import { Button } from '@/components/Button';
import { ROUTES } from '@/constants/routes';
import AllDocuments from '@/pages/admin/AllDocuments';
import { useAppSelector } from '@/utils/hooks';
import { Modal } from '@/components/Modal/Modal';
import { showSuccessToast } from '@/utils/toast';
import ConfirmationModal from '@/components/Modal/ConfirmationModal';
import BackButton from '@/components/Button/BackButton';
import { TwoFactorAuthModal } from '@/components/Modal/TwoFactorAuthModal';
import { UserInfo, UserStatus } from '@/types/user';
import { ConfirmationToken } from '@/types/auth';

type UserDetailsParams = {
  id: string;
};

const UserDetails = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams() as UserDetailsParams;

  const activeUserEmail = useAppSelector(state => state.user.profile?.email);

  const [isTransferTokenModalOpen, setIsTransferTokenModalOpen] = useState(false);
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] =
    useState(false);
  const [
    isResetCredentialsConfirmationModalOpen,
    setIsResetCredentialsConfirmationModalOpen,
  ] = useState(false);
  const [isDelete2FaModalOpen, setIsDelete2FaModalOpen] = useState<boolean>(false);
  const [isResetCredentials2FaModalOpen, setIsResetCredentials2FaModalOpen] =
    useState<boolean>(false);

  const { data: profileDetails, isLoading, isError } = useGetUserProfileQuery({ id });
  const [deleteUser, { isLoading: isDeleting, isSuccess: isUserDeleted }] =
    useDeleteUserMutation();
  const [
    restUserCredentials,
    { isLoading: isRestingUserCredentials, isSuccess: isUserCredentialsReset },
  ] = useRestUserCredentialsMutation();

  const { control } = useForm<UserInfo>({
    values: profileDetails,
    defaultValues: {
      email: '',
      name: '',
      surname: '',
    },
  });

  useEffect(() => {
    if (isError) {
      navigate(-1);
    }
  }, [isError]);

  const openDelete2FaModal = () => {
    setIsDelete2FaModalOpen(true);
    window.history.pushState({ tfaModal: true }, '', window.location.href);
  };

  const closeDelete2FaModal = () => {
    setIsDelete2FaModalOpen(false);
    window.history.back();
  };

  const openResetCredentials2FaModal = () => {
    setIsResetCredentials2FaModalOpen(true);
    window.history.pushState({ tfaModal: true }, '', window.location.href);
  };

  const closeResetCredentials2FaModal = () => {
    setIsResetCredentials2FaModalOpen(false);
    window.history.back();
  };

  const onDeleteUser = async () => {
    setIsDeleteConfirmationModalOpen(false);
    openDelete2FaModal();
  };

  const onDeleteUserWith2Fa = async ({ confirmationToken }: ConfirmationToken) => {
    closeDelete2FaModal();
    await deleteUser({
      id,
      confirmationToken,
    });
  };

  const onResetUserCredentials = async () => {
    setIsResetCredentials2FaModalOpen(false);
    openResetCredentials2FaModal();
  };

  const onResetUserCredentialsWith2Fa = async ({
    confirmationToken,
  }: ConfirmationToken) => {
    closeResetCredentials2FaModal();
    await restUserCredentials({
      id,
      confirmationToken,
    });
  };

  const onCopyTransferToken = async () => {
    navigator.clipboard.writeText(profileDetails?.transferToken ?? '');
    showSuccessToast(t('UserProfile.messages.copied'));
  };

  const handleOpenTransferTokenModal = () => {
    window.history.pushState({ modal: true }, '', window.location.href);
    setIsTransferTokenModalOpen(true);
  };

  const handleCloseTransferTokenModal = () => {
    setIsTransferTokenModalOpen(false);
    window.history.back();
  };

  const handleOpenDeleteConfirmationModal = () => {
    window.history.pushState({ modal: true }, '', window.location.href);
    setIsDeleteConfirmationModalOpen(true);
  };

  const handleCloseDeleteConfirmationModal = () => {
    setIsDeleteConfirmationModalOpen(false);
    window.history.back();
  };

  const handleOpenResetCredentialsConfirmationModal = () => {
    window.history.pushState({ modal: true }, '', window.location.href);
    setIsResetCredentialsConfirmationModalOpen(true);
  };

  const handleCloseResetCredentialsConfirmationModal = () => {
    setIsResetCredentialsConfirmationModalOpen(false);
    window.history.back();
  };

  window.onpopstate = () => {
    if (isTransferTokenModalOpen) setIsTransferTokenModalOpen(false);
    if (isDeleteConfirmationModalOpen) setIsDeleteConfirmationModalOpen(false);
    if (isResetCredentialsConfirmationModalOpen)
      setIsResetCredentialsConfirmationModalOpen(false);
    if (isDelete2FaModalOpen) setIsDelete2FaModalOpen(false);
    if (isResetCredentials2FaModalOpen) setIsResetCredentials2FaModalOpen(false);
  };

  useEffect(() => {
    if (isUserDeleted) {
      showSuccessToast(t('UserProfile.messages.userDeleted'));
      if (profileDetails?.status === UserStatus.Active) {
        navigate(ROUTES.USERS);
      } else {
        navigate(ROUTES.INVITATIONS);
      }
    }
  }, [isUserDeleted]);

  useEffect(() => {
    if (isUserCredentialsReset) {
      showSuccessToast(t('UserProfile.messages.userCredentialsReset'));
      handleCloseResetCredentialsConfirmationModal();
    }
  }, [isUserCredentialsReset]);

  const onShowTransferToken = () => {
    handleOpenTransferTokenModal();
  };

  // TODO: Move modal open/close logic to hook
  const onBack = async () => {
    navigate(-1);
  };

  const hasDocuments = !!profileDetails?.documentsCount;

  const canBeDeleted = !hasDocuments && activeUserEmail !== profileDetails?.email;

  const canResetCredentials = activeUserEmail !== profileDetails?.email;

  const showLoader = isLoading || isDeleting || isRestingUserCredentials;

  return (
    <>
      {profileDetails?.transferToken && isTransferTokenModalOpen && (
        <Modal
          isOpen={isTransferTokenModalOpen}
          onClose={handleCloseTransferTokenModal}
          title={t('UserProfile.labels.transferToken')}
          size="super-small"
        >
          <div>
            <div className="mx-[30px] my-[20px]">
              <p className="font_primary_base mb-[18px]">
                {t('UserProfile.labels.transferTokenDescription', {
                  user: `${profileDetails?.name} ${profileDetails?.surname}`,
                })}
              </p>
            </div>
            <div className="mx-[20px] my-[20px]">
              <input
                className={clsx(
                  'font_primary_base',
                  '!bg-white-primary w-full py-9 my-5 px-5 outline-none',
                  'cursor-not-allowed whitespace-nowrap'
                )}
                value={profileDetails.transferToken}
                disabled
              />
              <Button
                disabled={isLoading}
                onClick={onCopyTransferToken}
                className="w-full"
              >
                {t('UserProfile.buttons.copy')}
              </Button>
            </div>
          </div>
        </Modal>
      )}
      <PageTitle>{t('UserProfile.title')}</PageTitle>
      {showLoader ? (
        <Spinner fullScreen size="huge" />
      ) : (
        <>
          <div className="background_page_content border-t-3 border-t-red-primary">
            <form>
              <div
                className={clsx(
                  'grid-cols-1 grid grid-rows-auto w-full',
                  'xl:gap-3px xl:flex-row pt-0'
                )}
              >
                <div className="flex flex-1 flex-row justify-between items-center">
                  <Title
                    level="h2"
                    className="px-25px py-7.5 md:px-7.5 xl:py-[42px] xl:px-10"
                  >
                    {t('UserProfile.userDetails')}
                  </Title>
                  {profileDetails?.transferToken && (
                    <Button
                      type="button"
                      className={clsx('button_caption !py-[10px] mr-[25px]')}
                      onClick={onShowTransferToken}
                    >
                      {t('UserProfile.buttons.transferToken')}
                    </Button>
                  )}
                </div>
                <TextInput
                  label={t('UserProfile.labels.email')}
                  name="email"
                  control={control}
                  disabled
                />
                <div
                  className={clsx(
                    'grid-cols-1 grid grid-rows-auto w-full xl:grid-cols-2 gap-3px'
                  )}
                >
                  <TextInput
                    label={t('UserProfile.labels.name')}
                    name="name"
                    disabled
                    control={control}
                  />
                  <TextInput
                    label={t('UserProfile.labels.surname')}
                    name="surname"
                    disabled
                    control={control}
                  />
                </div>
              </div>
              <div className="flex items-center justify-end">
                {canResetCredentials && (
                  <>
                    <Button
                      disabled={isLoading}
                      onClick={handleOpenResetCredentialsConfirmationModal}
                      className="w-full h-15 xl:w-[33%] md:w-[33%]"
                    >
                      {t('UserProfile.buttons.resetCredentials')}
                    </Button>
                    <ConfirmationModal
                      isOpen={isResetCredentialsConfirmationModalOpen}
                      onClose={handleCloseResetCredentialsConfirmationModal}
                      onConfirm={onResetUserCredentials}
                      title={t('UserProfile.labels.resetUserCredentials')}
                      sentences={[
                        t('UserProfile.messages.resetUserCredentialsConfirmText1'),
                        t('UserProfile.messages.resetUserCredentialsConfirmText2'),
                        t('UserProfile.messages.resetUserCredentialsConfirmText3'),
                      ]}
                    />
                  </>
                )}
                {canBeDeleted && (
                  <>
                    <Button
                      disabled={isLoading}
                      onClick={handleOpenDeleteConfirmationModal}
                      className="w-full h-15 xl:w-[33%] md:w-[33%] ml-[3px]"
                    >
                      {t('UserProfile.buttons.delete')}
                    </Button>
                    <ConfirmationModal
                      isOpen={isDeleteConfirmationModalOpen}
                      onClose={handleCloseDeleteConfirmationModal}
                      onConfirm={onDeleteUser}
                      title={t('UserProfile.labels.deleteUser')}
                      sentences={[
                        t('UserProfile.messages.deleteUserConfirmText1'),
                        t('UserProfile.messages.deleteUserConfirmText2'),
                        t('UserProfile.messages.deleteUserConfirmText3'),
                      ]}
                    />
                  </>
                )}
              </div>
            </form>
          </div>
          {hasDocuments ? (
            <div className="background_secondary">
              <Title level="h2" className="px-25px md:px-7.5 pt-[42px] xl:px-10">
                {t('UserProfile.userDocuments')}
              </Title>
              <AllDocuments user={id} showBackButton />
            </div>
          ) : null}
        </>
      )}
      {!hasDocuments && (
        <BackButton title={t('UserProfile.buttons.backToUsers')} onBack={onBack} />
      )}
      <TwoFactorAuthModal
        isOpen={isDelete2FaModalOpen}
        onClose={closeDelete2FaModal}
        onTokenSubmit={onDeleteUserWith2Fa}
      />
      <TwoFactorAuthModal
        isOpen={isResetCredentials2FaModalOpen}
        onClose={closeResetCredentials2FaModal}
        onTokenSubmit={onResetUserCredentialsWith2Fa}
      />
    </>
  );
};

export default UserDetails;

import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { SubmitHandler, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { Title } from '@/components/Typography/Title';
import { Paragraph } from '@/components/Typography/Paragraph';
import { Button } from '@/components/Button';
import { CircularTitle } from '@/components/CircularTitle';
import { AuthContent } from '@/components/AuthContent';
import { FormData, resetPasswordFormSchema } from '@/forms/resetPasswordForm';
import { TextInput } from '@/components/TextInput';
import { useRequestResetPasswordMutation } from '../../api/authApi';
import { showSuccessToast } from '@/utils/toast';
import { CircularRequired } from '@/components/CircularRequired';

const SuccessRegistration = (): JSX.Element => {
  const { t } = useTranslation();

  const [resetPassword, { isLoading, isSuccess }] = useRequestResetPasswordMutation();

  const { handleSubmit, control, resetField } = useForm<FormData>({
    resolver: joiResolver(resetPasswordFormSchema),
    defaultValues: {
      email: '',
    },
  });

  useEffect(() => {
    if (isSuccess) {
      showSuccessToast(t('ResetPassword.emailSent'));
      resetField('email');
    }
  }, [isSuccess]);

  const onSubmit: SubmitHandler<FormData> = data => resetPassword(data);

  return (
    <div className="flex justify-end relative">
      <CircularTitle>{t('ResetPassword.password')}</CircularTitle>
      <CircularRequired />
      <AuthContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            className={clsx(
              'flex w-full pt-7 justify-end flex-col-reverse',
              'xl:gap-3px xl:flex-row xl:pt-12'
            )}
          >
            <div className="flex flex-1 flex-col justify-between">
              <div>
                <Title
                  level="h2"
                  className="xl:mb-10 mb-7.5 px-25px md:px-7.5 xl:px-10 ml-[2px]"
                >
                  {t('ResetPassword.resetPassword')}
                </Title>
                <Paragraph className="px-25px pb-9 md:pb-4 xl:pb-11 xl:px-10 ml-[2px]">
                  {t('ResetPassword.description')}
                </Paragraph>
              </div>
              <TextInput
                label={t('Common.email')}
                name="email"
                isRequired
                control={control}
                focus
              />
              <Button disabled={isLoading} isSubmit>
                {t('Common.submit')}
              </Button>
            </div>
          </div>
        </form>
      </AuthContent>
    </div>
  );
};

export default SuccessRegistration;

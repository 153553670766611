import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import AdminCardStats from '@/components/AdminCardStats/AdminCardStats';
import { Button } from '@/components/Button';
import { ContentLayout } from '@/components/ContentLayout/ContentLayout';
import DocumentsStatsBlock from '@/components/StatsBlock/DocumentsStatsBlock';
import UsersStatsBlock from '@/components/StatsBlock/UsersStatsBlock';
import StorageStatsBlock from '@/components/StatsBlock/StorageStatsBlock';
import PrintIcon from '@/assets/icons/print.svg';

const Statistics = (): JSX.Element => {
  const { t } = useTranslation();
  const statsRef = useRef<HTMLDivElement | null>(null);
  const printSummary = useReactToPrint({ content: () => statsRef.current });

  return (
    <ContentLayout title={t('AdminDashboard.statistics')}>
      <div className="background_page_content" ref={statsRef}>
        <AdminCardStats />
        <DocumentsStatsBlock />
        <UsersStatsBlock />
        <StorageStatsBlock />
      </div>
      <Button onClick={printSummary} className="w-full mt-[100px]">
        <img src={PrintIcon} alt="" className="mr-[30px]" />
        {t('Statistics.buttons.print')}
      </Button>
    </ContentLayout>
  );
};

export default Statistics;

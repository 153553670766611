import React, { useState, useMemo, useEffect } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Button } from '@/components/Button/index';
import { ContentLayout } from '@/components/ContentLayout/ContentLayout';
import DocumentsCount from '@/components/DocumentsCount/DocumentsCount';
import DocumentsTable from '@/components/Table/DocumentsTable/DocumentsTable';
import { SelectInputUncontrolled } from '@/components/SelectInput/SelectInputUncontrolled';
import SortInput, { SortOrders } from '@/components/SortInput/SortInput';
import { Spinner } from '@/components/Spinner';
import TextFilter from '@/components/TextFilter/TextFilter';
import {
  useGetUserRootDocumentsQuery,
  useGetDocumentTypesQuery,
} from '@/api/documentsApi';
import { scrollToTableTop } from '@/utils/scroll';
import { ReactComponent as ChevronDown } from '@/assets/icons/chevron-down.svg';
import { USER_DOCUMENTS_COLUMNS } from '@/components/Table/DocumentsTable/columns/userDocuments';

export const defaultPageSize = 10;
export const defaultPageNumber = 1;
export const defaultSortField = 'agreementDate';
export const defaultSortOrder = 'desc';

const UserDocuments = () => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();

  const ALL_FILTER = t('DocumentTypes.all');

  const [clientFilter, setClientFilter] = useState<string | undefined>(undefined);
  const [typeFilter, setTypeFilter] = useState(ALL_FILTER);

  const [sortBy, setSortBy] = useState(defaultSortField);
  const [sortOrder, setSortOrder] = useState<SortOrders>(defaultSortOrder);
  const [pageNumber, setPageNumber] = useState(defaultPageNumber);
  const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>(false);

  const meta = searchParams.get('meta');

  const { data: documentTypes } = useGetDocumentTypesQuery();
  const {
    data: documents,
    isLoading: isDocumentsLoading,
    isFetching: isDocumentsFetching,
  } = useGetUserRootDocumentsQuery({
    filters: {
      offset: defaultPageSize * (pageNumber - 1),
      limit: defaultPageSize,
      sortBy,
      sortOrder,
      client: clientFilter === '' ? undefined : clientFilter,
      type: typeFilter === ALL_FILTER ? undefined : typeFilter,
      meta: meta || undefined,
    },
  });

  const documentTypesFilterOptions = useMemo(() => {
    if (!documentTypes) return [{ value: ALL_FILTER, label: ALL_FILTER }];

    const types = documentTypes.map(documentType => ({
      value: documentType.type,
      label: documentType.type,
    }));

    return [{ value: ALL_FILTER, label: ALL_FILTER }, ...types];
  }, [documentTypes]);

  const sortByOptions = [
    { value: 'title', label: t('UserDocuments.columns.description') },
    { value: 'client', label: t('UserDocuments.columns.client') },
    { value: 'agreementDate', label: t('UserDocuments.columns.date') },
    { value: 'type', label: t('UserDocuments.columns.type') },
  ];

  useEffect(() => {
    setPageNumber(1);
  }, [meta]);

  const onTypeChanged = (value: string) => {
    setPageNumber(1);
    setTypeFilter(value);
    scrollToTableTop();
  };

  const onClientChanged = (value: string) => {
    setPageNumber(1);
    setClientFilter(value);
    scrollToTableTop();
  };

  const onSortByChanged = (value: string) => {
    setPageNumber(1);
    setSortBy(value);
    scrollToTableTop();
  };

  const onSortOrderChanged = (value: SortOrders) => {
    setPageNumber(1);
    setSortOrder(value);
  };

  const onToggleFilters = () => {
    const isOpen = !isFiltersOpen;
    setIsFiltersOpen(isOpen);
  };

  return (
    <ContentLayout title={t('UserDocuments.title')}>
      <div className={clsx('grid grid-cols-2 md:grid-cols-1 grid-cols-[3fr,1fr]')}>
        <DocumentsCount isLoading={isDocumentsLoading} total={documents?.total} />
        <Button
          className="button_toggle_filter w-auto md:hidden"
          onClick={onToggleFilters}
        >
          <span className={clsx('text-16px text_primary')}>FILTERS</span>
          <ChevronDown className={clsx(isFiltersOpen && 'rotate-180', 'ml-2')} />
        </Button>
      </div>
      <div
        className={clsx(
          'grid grid-cols-1 md:grid-cols-3 xl:grid-cols-2 2xl:grid-cols-3',
          'gap-y-[3px] md:gap-x-[3px] lg:mb-[37px] mb-0',
          !isFiltersOpen && 'max-md:hidden'
        )}
      >
        <SelectInputUncontrolled
          label={t('Authenticate.labels.documentType')}
          name="documentType"
          options={documentTypesFilterOptions}
          value={typeFilter}
          setValue={onTypeChanged}
        />
        <TextFilter
          label={t('Authenticate.labels.client')}
          placeholder={t('UserDocuments.filters.clientPlaceholder')}
          onSubmit={onClientChanged}
        />
        <div className="md:hidden lg:hidden xl:block 2xl:hidden" />
        <SortInput
          label={t('UserDocuments.sortBy')}
          name="sortBy"
          options={sortByOptions}
          sortBy={sortBy}
          setSortBy={onSortByChanged}
          sortOrder={sortOrder}
          setSortOrder={onSortOrderChanged}
        />
      </div>
      {isDocumentsFetching ? (
        <Spinner size="medium" />
      ) : (
        <DocumentsTable
          columns={USER_DOCUMENTS_COLUMNS}
          documents={documents?.data || []}
          isLoadingData={isDocumentsFetching}
          setPageNumber={setPageNumber}
          allDocumentsNumber={documents?.total}
          currentPageNumber={pageNumber}
          pageSize={defaultPageSize}
        />
      )}
    </ContentLayout>
  );
};

export default UserDocuments;

import { createApi, TagDescription } from '@reduxjs/toolkit/query/react';
import urlcat from 'urlcat';

import { ENDPOINTS } from '@/constants/endpoints';
import { customBaseQuery } from '@/api/baseQuery';
import { InvitedUsersFormData } from '@/forms/inviteUsersForm';
import { ApiPaginatedResponse } from '@/types/common';
import {
  DeleteUserArgs,
  GetUserProfileArgs,
  GetUsersArgs,
  InviteUserResponse,
  ResendUserInviteArgs,
  ResetUserCredentialsArgs,
  UserInfo,
} from '@/types/user';

export const adminUserApi = createApi({
  reducerPath: 'adminUserApi',
  tagTypes: ['AdminUsers'],
  baseQuery: customBaseQuery,
  endpoints: builder => ({
    getUsers: builder.query<ApiPaginatedResponse<UserInfo>, GetUsersArgs | void>({
      query: arg => ({
        url:
          arg && arg.filters
            ? urlcat(ENDPOINTS.GET_USERS, { ...arg.filters })
            : ENDPOINTS.GET_USERS,
      }),
      keepUnusedDataFor: 0.0001,
      providesTags: ['Users' as TagDescription<'AdminUsers'>],
    }),
    getUserProfile: builder.query<UserInfo, GetUserProfileArgs>({
      query: arg => ({
        url: ENDPOINTS.GET_USER_PROFILE(arg.id),
      }),
    }),
    inviteUsers: builder.mutation<InviteUserResponse, InvitedUsersFormData>({
      query: body => ({
        method: 'POST',
        body,
        url: ENDPOINTS.INVITE_USER,
      }),
      invalidatesTags: [
        'Users' as TagDescription<'AdminUsers'>,
        'UserStatistic' as TagDescription<'AdminUsers'>,
      ],
    }),
    resendUserInvitation: builder.mutation<void, ResendUserInviteArgs>({
      query: data => ({
        method: 'POST',
        url: ENDPOINTS.RESEND_USER_INVITE(data.id),
      }),
      invalidatesTags: ['Users' as TagDescription<'AdminUsers'>],
    }),
    deleteUser: builder.mutation<void, DeleteUserArgs>({
      query: data => ({
        method: 'DELETE',
        url: ENDPOINTS.DELETE_USER(data.id),
        body: {
          confirmationToken: data.confirmationToken,
        },
      }),
      invalidatesTags: [
        'Users' as TagDescription<'AdminUsers'>,
        'UserStatistic' as TagDescription<'AdminUsers'>,
      ],
    }),
    restUserCredentials: builder.mutation<void, ResetUserCredentialsArgs>({
      query: data => ({
        method: 'POST',
        url: ENDPOINTS.RESET_USER_CREDENTIALS(data.id),
        body: {
          confirmationToken: data.confirmationToken,
        },
      }),
    }),
  }),
});

export const {
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useGetUserProfileQuery,
  useInviteUsersMutation,
  useResendUserInvitationMutation,
  useDeleteUserMutation,
  useRestUserCredentialsMutation,
} = adminUserApi;

const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB'];

export function humanFileSize(size?: number) {
  if (!size) {
    return { size: 0, unit: 'Bytes' };
  }

  let n = size;
  let i = 0;
  for (i; n > 1024; i += 1) {
    n /= 1024;
  }
  return {
    size: parseFloat(n.toFixed(2)),
    unit: units[i],
  };
}

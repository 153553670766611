import { createApi, TagDescription } from '@reduxjs/toolkit/query/react';
import urlcat from 'urlcat';

import { ENDPOINTS } from '@/constants/endpoints';
import { customBaseQuery } from '@/api/baseQuery';
import {
  ApiPaginatedResponse,
  ApproveSignatoriesResponse,
  ApproveSignatoryRequestArgs,
  CreateDocumentResponse,
  Document,
  DocumentListItem,
  DownloadDocumentArgs,
  DownloadDocumentResponse,
  GetDocumentArgs,
  GetDocumentsArgs,
  GetDocumentTypesArgs,
  GetDocumentTypesResponse,
  GetDocumentViewArgs,
  GetDocumentViewResponse,
  GetSharedDocumentSignatoryPhotoArgs,
  GetSharedDocumentSignatoryPhotoResponse,
  GetSignatoryPhotoRequestArgs,
  GetSignatoryPhotoResponse,
  RejectSignatoryRequestArgs,
  ResendSignatoryRequestArgs,
  ShareDocumentApiArgs,
  ShareDocumentArgs,
  TransferDocumentsArgs,
  VerifySignatoryArgs,
} from '@/types/document';

export const documentsApi = createApi({
  reducerPath: 'documentsApi',
  tagTypes: ['Document'],
  baseQuery: customBaseQuery,
  endpoints: builder => ({
    getUserDocuments: builder.query<
      ApiPaginatedResponse<DocumentListItem>,
      GetDocumentsArgs | void
    >({
      query: arg => ({
        url:
          arg && arg.filters
            ? urlcat(ENDPOINTS.GET_DOCUMENTS, arg.filters)
            : ENDPOINTS.GET_DOCUMENTS,
      }),
      keepUnusedDataFor: 10,
      providesTags: ['UserDocuments' as TagDescription<'Document'>],
    }),
    getUserRootDocuments: builder.query<
      ApiPaginatedResponse<DocumentListItem>,
      GetDocumentsArgs | void
    >({
      query: arg => ({
        url:
          arg && arg.filters
            ? urlcat(ENDPOINTS.GET_DOCUMENTS, { kind: 'root', ...arg.filters })
            : ENDPOINTS.GET_DOCUMENTS,
      }),
      keepUnusedDataFor: 0.0001,
      providesTags: ['RootDocuments' as TagDescription<'Document'>],
    }),
    getDocument: builder.query<Document, GetDocumentArgs>({
      query: data => ({
        url: urlcat(ENDPOINTS.GET_DOCUMENT(data.documentId), {
          fetchHistory: true,
        }),
      }),
      keepUnusedDataFor: 10,
      providesTags: ['Document' as TagDescription<'Document'>],
    }),
    createDocument: builder.mutation<CreateDocumentResponse, FormData>({
      query: body => ({
        method: 'POST',
        body,
        url: ENDPOINTS.CREATE_DOCUMENT,
      }),
      invalidatesTags: [
        'Document' as TagDescription<'Document'>,
        'UserDocuments' as TagDescription<'Document'>,
        'RootDocuments' as TagDescription<'Document'>,
      ],
    }),
    downloadDocument: builder.query<DownloadDocumentResponse, DownloadDocumentArgs>({
      query: data => {
        let url;
        switch (data.target) {
          case 'general':
            url = ENDPOINTS.DOWNLOAD_DOCUMENT(data.documentId);
            break;
          case 'signatory':
            url = urlcat(ENDPOINTS.SIGNATORY_DOWNLOAD, { token: data.token });
            break;
          case 'share':
            url = urlcat(ENDPOINTS.SHARE_DOWNLOAD, {
              token: data.token,
              subDocumentId: data.subDocumentId,
            });
            break;
          default:
            throw Error('Invalid download api target');
        }
        return {
          url,
          cache: 'no-cache',
          responseHandler: async response => {
            const header: string | null = response.headers.get('content-disposition');
            if (!header) {
              throw new Error('Invalid file received');
            }
            const filename = header.split('filename=')[1];
            if (!filename) {
              throw new Error('Invalid file received');
            }
            const parts = filename.replaceAll('"', '').split('.');
            const name = parts.join('.');
            const extension = parts.pop();
            return {
              data: {
                content: URL.createObjectURL(await response.blob()),
                name,
                extension,
              },
            };
          },
        };
      },
      keepUnusedDataFor: 60,
    }),
    verifySignatory: builder.mutation<ApproveSignatoriesResponse, VerifySignatoryArgs>({
      query: data => ({
        method: 'PUT',
        url: ENDPOINTS.VERIFY_SIGNATORY(data.documentId),
        body: data.body,
      }),
    }),
    resendSignatoryRequest: builder.mutation<void, ResendSignatoryRequestArgs>({
      query: data => ({
        method: 'PUT',
        url: ENDPOINTS.RESEND_SIGNATORY_REQUEST(data.documentId),
        body: data.body,
      }),
    }),
    rejectSignatoryRequest: builder.mutation<void, RejectSignatoryRequestArgs>({
      query: data => ({
        method: 'PUT',
        url: ENDPOINTS.REJECT_SIGNATORY_REQUEST(data.documentId),
        body: data.body,
      }),
    }),
    shareDocument: builder.mutation<void, ShareDocumentArgs>({
      query: data => ({
        method: 'POST',
        url: ENDPOINTS.SHARE_DOCUMENT(data.documentId),
        body: <ShareDocumentApiArgs>{
          sections: Object.keys(data.visibleSections).filter(
            section => data.visibleSections[section]
          ),
          recipients: [...data.recipients],
        },
      }),
    }),
    signatoryApprove: builder.mutation<void, ApproveSignatoryRequestArgs>({
      query: data => ({
        method: 'PUT',
        url: urlcat(ENDPOINTS.SIGNATORY_APPROVE(data.documentId), { token: data.token }),
        body: data.body,
      }),
    }),
    getDocumentSignatoryView: builder.query<Document, GetDocumentViewArgs>({
      query: data => ({
        url: urlcat(ENDPOINTS.SIGNATORY_VIEW, { token: data.token }),
      }),
      keepUnusedDataFor: 60,
    }),
    getDocumentSignatoryPhoto: builder.query<
      GetSignatoryPhotoResponse,
      GetSignatoryPhotoRequestArgs
    >({
      query: data => ({
        url: urlcat(ENDPOINTS.SIGNATORY_PHOTO(data.documentId), { email: data.email }),
      }),
      keepUnusedDataFor: 20,
    }),
    getDocumentShareView: builder.query<GetDocumentViewResponse, GetDocumentViewArgs>({
      query: data => ({
        url: urlcat(ENDPOINTS.SHARED_DOCUMENT_VIEW, { token: data.token }),
      }),
      keepUnusedDataFor: 60,
    }),
    getSharedDocumentSignatoryPhoto: builder.query<
      GetSharedDocumentSignatoryPhotoResponse,
      GetSharedDocumentSignatoryPhotoArgs
    >({
      query: data => ({
        url: urlcat(ENDPOINTS.SHARED_DOCUMENT_SIGNATORY_PHOTO_VIEW, {
          token: data.token,
          email: data.email,
          subDocumentId: data.subDocumentId,
        }),
      }),
      keepUnusedDataFor: 60,
    }),
    getDocumentTypes: builder.query<
      GetDocumentTypesResponse[],
      GetDocumentTypesArgs | void
    >({
      query: () => ({
        url: ENDPOINTS.GET_DOCUMENT_TYPES,
      }),
    }),

    getAllDocuments: builder.query<
      ApiPaginatedResponse<DocumentListItem>,
      GetDocumentsArgs | void
    >({
      query: arg => ({
        url:
          arg && arg.filters
            ? urlcat(ENDPOINTS.GET_ALL_DOCUMENTS, { kind: 'root', ...arg.filters })
            : ENDPOINTS.GET_ALL_DOCUMENTS,
      }),
      keepUnusedDataFor: 0.0001,
      providesTags: ['AllDocuments' as TagDescription<'Document'>],
    }),
    transferDocuments: builder.mutation<void, TransferDocumentsArgs>({
      query: data => ({
        method: 'PUT',
        url: ENDPOINTS.TRANSFER_DOCUMENTS,
        body: data,
      }),
      invalidatesTags: [
        'UserDocuments' as TagDescription<'Document'>,
        'RootDocuments' as TagDescription<'Document'>,
        'AllDocuments' as TagDescription<'Document'>,
      ],
    }),
  }),
});

export const {
  useLazyGetUserDocumentsQuery,
  useGetUserRootDocumentsQuery,
  useGetAllDocumentsQuery,
  useCreateDocumentMutation,
  useGetDocumentQuery,
  useDownloadDocumentQuery,
  useVerifySignatoryMutation,
  useResendSignatoryRequestMutation,
  useRejectSignatoryRequestMutation,
  useShareDocumentMutation,
  useSignatoryApproveMutation,
  useGetDocumentSignatoryViewQuery,
  useGetDocumentSignatoryPhotoQuery,
  useGetSharedDocumentSignatoryPhotoQuery,
  useGetDocumentShareViewQuery,
  useGetDocumentTypesQuery,
  useTransferDocumentsMutation,
} = documentsApi;

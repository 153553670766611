import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '@/utils/hooks';
import { authApi, useLogOutMutation } from '../../api/authApi';
import { userApi } from '@/api/userApi';
import { documentsApi } from '@/api/documentsApi';
import { adminUserApi } from '../../api/adminUserApi';

const Logout = (): null => {
  const refreshToken = useAppSelector(state => state.auth.refreshToken);
  const dispatch = useAppDispatch();

  const [logOut] = useLogOutMutation();

  useEffect(() => {
    if (refreshToken) {
      logOut({ refresh: refreshToken });
      dispatch(authApi.util.resetApiState());
      dispatch(userApi.util.resetApiState());
      dispatch(adminUserApi.util.resetApiState());
      dispatch(documentsApi.util.resetApiState());
    }
  }, [refreshToken]);

  return null;
};

export default Logout;

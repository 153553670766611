import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import React, { ChangeEvent, useEffect, useState } from 'react';
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import SearchIcon from '@/assets/icons/search.svg';
import SearchIconWhite from '@/assets/icons/search-white.svg';
import { ROUTES } from '@/constants/routes';

const delay = 500;

interface SearchInputProps {
  mode?: string;
  className?: string;
}

const SearchInput = ({ mode, className }: SearchInputProps) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const meta = searchParams.get('meta');

  const [value, setValue] = useState<string>(meta ?? '');

  const debouncedOnChange = useDebouncedCallback((newValue: string) => {
    navigate({
      pathname: ROUTES.DOCUMENTS,
      search: createSearchParams({
        meta: newValue,
      }).toString(),
    });
  }, delay);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setValue(newValue);
    if (mode !== 'sidebar') debouncedOnChange(newValue);
  };

  const handleClickSearch = () => {
    navigate({
      pathname: ROUTES.DOCUMENTS,
      search: createSearchParams({
        meta: value,
      }).toString(),
    });
  };

  useEffect(() => {
    if (location.pathname !== ROUTES.DOCUMENTS) {
      setValue('');
    }
  }, [location]);

  return mode !== 'sidebar' ? (
    <div className={clsx('search_input', className)}>
      <span className="cursor-pointer" aria-hidden="true">
        <img src={SearchIcon} alt="" />
      </span>
      <input
        type="text"
        value={value}
        onChange={handleChange}
        className={clsx(
          'search_input_text',
          'focus:border-b-[1px] focus:border-b-gray-tetriary',
          'ml-[20px] w-[270px] duration-200 outline-none'
        )}
        id="search"
        placeholder={t('Dashboard.header.search')}
      />
    </div>
  ) : (
    <div
      className={clsx(
        'flex items-center border border-gray-tetriary p-[20px] mx-10 mb-10 max-sm:mx-5'
      )}
    >
      <span className="cursor-pointer" aria-hidden="true" onClick={handleClickSearch}>
        <img src={SearchIconWhite} alt="" />
      </span>
      <input
        type="text"
        value={value}
        onChange={handleChange}
        className={clsx(
          '!text-white-primary search_input_text',
          'background_primary  placeholder:text-white-primary',
          'ml-[20px] w-full outline-none'
        )}
        id="search"
        placeholder={t('Dashboard.header.searchShort')}
      />
    </div>
  );
};

export default SearchInput;

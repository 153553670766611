import clsx from 'clsx';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import './DateRangePicker.css';
import './Calendar.css';
import CalendarIcon from '@/assets/icons/calendar.svg';
import CloseIcon from '@/assets/icons/close-filter.svg';
import { getRangeUTCValue } from '@/utils/dates';

type ValuePiece = Date | null;
export type RangeValue = ValuePiece | [ValuePiece, ValuePiece];

interface IProps {
  label: string;
  onChange: (value: RangeValue) => void;
  value: RangeValue;
}
const DateRange = ({ label, onChange, value }: IProps): JSX.Element => {
  const filled = getRangeUTCValue(value);

  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label
      className={clsx(
        'input_label',
        'bg-white-primary b py-3 px-25px md:px-7.5 xl:px-10 xl:py-4 text-left block'
      )}
    >
      {label}
      <div className="flex items-center justify-between mt-[13px]">
        <DateRangePicker
          clearIcon={
            filled ? (
              <img src={CloseIcon} className="w-[20px] pt-[2px]" alt="" />
            ) : (
              <div className="w-[36px]" />
            )
          }
          calendarIcon={<img src={CalendarIcon} alt="" />}
          onChange={onChange}
          value={value}
          locale="en-US"
          dayPlaceholder="dd"
          monthPlaceholder="mm"
          yearPlaceholder="yyyy"
          className="datepicker"
        />
      </div>
    </label>
  );
};

export default DateRange;

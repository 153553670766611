import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import type { BaseQueryFn, FetchArgs, FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { Mutex } from 'async-mutex';

import { ENDPOINTS } from '@/constants/endpoints';
import { RootState } from '@/store';
import { clearTokens, setTokens } from '@/store/authSlice';
import { showErrorToast } from '@/utils/toast';
import { isServerError } from '@/utils/errors';
import { TokenResponse } from '@/types/auth';

const mutex = new Mutex();

const baseQuery = fetchBaseQuery({
  baseUrl: ENDPOINTS.BASE,
  prepareHeaders: (headers, { getState }) => {
    const { accessToken } = (getState() as RootState).auth;

    if (accessToken) {
      headers.set('Authorization', `Bearer ${accessToken}`);
    }

    return headers;
  },
});

export const customBaseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  await mutex.waitForUnlock();

  let result = await baseQuery(args, api, extraOptions);

  // Don't refetch token if login fails
  if (result.error && !result.meta?.request?.url.match('token')) {
    if (isServerError(result.error)) {
      showErrorToast(result.error?.data?.message);
    }

    if (result.error.status === 401) {
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();
        const refresh = (api.getState() as RootState).auth.refreshToken;
        try {
          const refreshResult = await baseQuery(
            { url: ENDPOINTS.REFRESH_TOKEN, body: { refresh }, method: 'POST' },
            api,
            extraOptions
          );
          if (refreshResult.data?.data) {
            api.dispatch(setTokens(refreshResult.data!.data as TokenResponse));
            // Retry the original request
            result = await baseQuery(args, api, extraOptions);
          } else {
            api.dispatch(clearTokens());
          }
        } finally {
          release();
        }
      } else {
        await mutex.waitForUnlock();
        result = await baseQuery(args, api, extraOptions);
      }
    }
  }

  // Extract the data if was received the ResponseWrapper
  if (result?.data && typeof result?.data !== 'string') {
    return result!.data;
  }

  // Return "As Is" for another cases
  return result;
};

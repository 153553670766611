import Joi from 'joi';
import i18next from '@/translations';

import { PASSWORD_REGEX } from '@/constants/regex';

export interface FormData {
  password: string;
  passwordRepeat: string;
}

export const newPasswordSchema = Joi.object({
  password: Joi.string()
    .trim()
    .required()
    .min(8)
    .max(255)
    .pattern(new RegExp(PASSWORD_REGEX))
    .messages({
      'string.min': i18next.t('Common.errors.passwordTooShort'),
      'string.pattern.base': i18next.t('Common.errors.invalidPasswordFormat'),
      'string.empty': i18next.t('Common.errors.fieldRequired'),
    }),
  passwordRepeat: Joi.any()
    .valid(Joi.ref('password'))
    .required()
    .options({
      messages: {
        'any.only': i18next.t('Common.errors.incorrectPasswordMatch'),
      },
    }),
}).required();

import { useEffect } from 'react';
import clsx from 'clsx';
import { joiResolver } from '@hookform/resolvers/joi';
import { useForm, useFieldArray, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Switch } from '../Switch';
import { Modal } from './Modal';
import ShareIcon from '@/assets/icons/share.svg';
import { AddPartyFormRow } from '../AddPartyFormRow';
import { ShareViewFormData, shareViewFormSchema } from '@/forms/shareViewForm';
import { useShareDocumentMutation } from '@/api/documentsApi';
import { showSuccessToast } from '@/utils/toast';
import { Spinner } from '@/components/Spinner';

interface ShareViewModalProps {
  isOpen: boolean;
  onClose: () => void;
  documentId: string;
}

export const documentRecipientsLimit = 10;

export const ShareViewModal = ({ isOpen, onClose, documentId }: ShareViewModalProps) => {
  const { t } = useTranslation();

  const [shareDocument, { isSuccess, isLoading: isSharing }] = useShareDocumentMutation();

  const { handleSubmit, control, trigger, reset, watch, setValue } =
    useForm<ShareViewFormData>({
      resolver: joiResolver(shareViewFormSchema),
      defaultValues: {
        visibleSections: {
          history: true,
          type: false,
          client: false,
          signatories: false,
          signatoryPhotos: false,
          date: false,
        },
        recipients: [
          {
            email: '',
            name: '',
            surname: '',
          },
        ],
      },
    });

  const signatoryPhotosSelected = watch('visibleSections.signatoryPhotos');

  useEffect(() => {
    if (signatoryPhotosSelected) {
      setValue('visibleSections.signatories', true);
    }
  }, [signatoryPhotosSelected]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'recipients',
  });

  useEffect(() => {
    if (!isOpen) reset();
  }, [isOpen]);

  useEffect(() => {
    if (isSuccess) {
      showSuccessToast(t('SingleDocumentDetails.documentShared'));
      onClose();
    }
  }, [isSuccess]);

  const onSubmit: SubmitHandler<ShareViewFormData> = async data => {
    await shareDocument({ documentId, ...data });
  };

  return (
    <Modal
      isOpen={isOpen}
      annotation={t('ShareView.description')}
      onClose={onClose}
      title={t('ShareView.addInfo')}
    >
      {isSharing && <Spinner fullScreen size="huge" />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div
          className={clsx(
            'grid grid-cols-3 background_tetriary xl:gap-y-[53px]',
            'sm:gap-x-[40px] sm:gap-y-[30px] max-md:py-[40px]',
            'max-sm:p-[25px] max-sm:grid-cols-1 max-sm:gap-y-[20px] xl:gap-x-[105px]',
            'p-12.5 max-xl:px-7.5'
          )}
        >
          <div>
            <Switch
              name="visibleSections.history"
              text={t('ShareView.buttons.entireHistory')}
              control={control}
              textClassname="switch_label"
            />
          </div>
          <div>
            <Switch
              name="visibleSections.date"
              text={t('ShareView.buttons.documentDate')}
              control={control}
              textClassname="switch_label"
            />
          </div>
          <div>
            <Switch
              name="visibleSections.signatories"
              text={t('ShareView.buttons.parties')}
              control={control}
              textClassname="switch_label"
            />
          </div>
          <div>
            <Switch
              name="visibleSections.client"
              text={t('ShareView.buttons.client')}
              control={control}
              textClassname="switch_label"
            />
          </div>
          <div>
            <Switch
              name="visibleSections.type"
              text={t('ShareView.buttons.type')}
              control={control}
              textClassname="switch_label"
            />
          </div>
          <div>
            <Switch
              name="visibleSections.signatoryPhotos"
              text={t('ShareView.buttons.partiesPhoto')}
              control={control}
              textClassname="switch_label"
            />
          </div>
        </div>

        <div className=" xl:pl-[7px] sm:pl-[5px] md:pl-0">
          <AddPartyFormRow
            control={control}
            fields={fields}
            append={append}
            remove={remove}
            trigger={trigger}
            arrayName="recipients"
            submitButtonIcon={ShareIcon}
            submitButtonText={t('SingleDocumentDetails.buttons.share')}
            title={t('AddParties.addRecipients')}
            addButtonTitle={t('AddParties.addRecipient')}
            limit={documentRecipientsLimit}
            limitError={t('AddParties.errors.recipientsLimit', {
              count: documentRecipientsLimit,
            })}
            minNumber={1}
          />
        </div>
      </form>
    </Modal>
  );
};

import clsx from 'clsx';

interface CircularTitleProps {
  children?: React.ReactNode;
}

const CircularTitle = ({ children }: CircularTitleProps) => {
  return (
    <div
      className={clsx(
        'circular_title',
        'bg-primary-gradient rounded-full text-center w-[200px] h-[200px]',
        'flex justify-center items-center',
        '-top-[170px] absolute left-0 z-0 md:w-[245px] md:h-[245px]',
        'md:top-0 xl:w-100 xl:h-100'
      )}
    >
      <span>{children}</span>
    </div>
  );
};

export default CircularTitle;

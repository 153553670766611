import clsx from 'clsx';

interface TwoFactorStepProps {
  label: string;
  number: number;
}

const TwoFactorStep = ({ label, number }: TwoFactorStepProps): JSX.Element => (
  <span className="flex items-center text-gray-senary text-18px font-bold">
    <span
      className={clsx(
        'flex items-center justify-center w-[34px] h-[34px] background_tetriary',
        'rounded-full mr-3'
      )}
    >
      {number}
    </span>
    {label}
  </span>
);

export default TwoFactorStep;

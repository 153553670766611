import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { SubmitHandler, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { Title } from '@/components/Typography/Title';
import { Paragraph } from '@/components/Typography/Paragraph';
import { Button } from '@/components/Button';
import { CircularTitle } from '@/components/CircularTitle';
import { AuthContent } from '@/components/AuthContent';
import { FormData, newPasswordSchema } from '@/forms/newPasswordForm';
import { TextInput } from '@/components/TextInput';
import { CircularRequired } from '@/components/CircularRequired';
import { useResetPasswordMutation } from '../../api/authApi';
import { ErrorMessage } from '@/components/ErrorMessage';
import { ROUTES } from '@/constants/routes';
import { showSuccessToast } from '@/utils/toast';
import { TwoFactorAuthModal } from '@/components/Modal/TwoFactorAuthModal';
import { Spinner } from '@/components/Spinner';
import { useModal } from '@/utils/hooks';
import { ResetPassword2faAction, ResetPasswordOkAction } from '@/types/auth';

const NewPassword = (): JSX.Element => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const {
    isModalOpen: is2FaModalOpen,
    openModal: open2FaModal,
    closeModal: handleCloseModal,
  } = useModal();

  const [resetPassword, { data, error, isLoading, isSuccess }] =
    useResetPasswordMutation();

  const { handleSubmit, control, watch } = useForm<FormData>({
    resolver: joiResolver(newPasswordSchema),
    defaultValues: {
      password: '',
      passwordRepeat: '',
    },
  });

  const password = watch('password');
  const passwordRepeat = watch('passwordRepeat');

  useEffect(() => {
    if (isSuccess) {
      if (data?.action === ResetPasswordOkAction) {
        showSuccessToast(t('NewPassword.passwordChanged'));
        navigate(ROUTES.HOME);
      }
      if (data?.action === ResetPassword2faAction) {
        open2FaModal();
      }
    }
  }, [isSuccess]);

  const onSubmit: SubmitHandler<FormData> = formData => {
    const token = searchParams.get('token');

    if (token) {
      resetPassword({
        token,
        password: formData.password,
      });
    }
  };

  const onSubmitWith2fa = ({ confirmationToken }: { confirmationToken: string }) => {
    const token = searchParams.get('token');

    if (token) {
      resetPassword({
        token,
        password,
        confirmationToken,
      });
      handleCloseModal();
    }
  };

  return (
    <div className="flex justify-end relative">
      <CircularTitle>{t('Common.password')}</CircularTitle>
      <CircularRequired />
      <AuthContent>
        {isLoading && <Spinner fullScreen size="huge" />}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            className={clsx(
              'flex w-full pt-7 justify-end flex-col-reverse',
              'xl:gap-3px xl:flex-row xl:pt-12'
            )}
          >
            <div className="flex flex-1 flex-col justify-between">
              <div>
                <Title
                  level="h2"
                  className=" xl:mb-10 mb-7.5 px-25px md:px-7.5 xl:px-10 ml-[2px]"
                >
                  {t('NewPassword.resetPassword')}
                </Title>
                <Paragraph className="px-25px pb-9 md:pb-4 xl:pb-11 xl:px-10 ml-[2px]">
                  {t('NewPassword.description')}
                </Paragraph>
              </div>
              <TextInput
                label={t('NewPassword.newPassword')}
                name="password"
                isRequired
                isPassword
                control={control}
                focus
              />
              <TextInput
                label={t('NewPassword.confirmNewPassword')}
                name="passwordRepeat"
                isRequired
                isPassword
                control={control}
              />
              <ErrorMessage error={error} resetOn={[password, passwordRepeat]} />
              <Button disabled={isLoading} isSubmit>
                {t('Common.submit')}
              </Button>
            </div>
          </div>
        </form>
        <TwoFactorAuthModal
          isOpen={is2FaModalOpen}
          onClose={handleCloseModal}
          onTokenSubmit={onSubmitWith2fa}
        />
      </AuthContent>
    </div>
  );
};

export default NewPassword;

import clsx from 'clsx';
import { useEffect, forwardRef, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import TextDocumentViewer from '@/components/DocumentViewer/TextDocumentViewer';
import ImageDocumentViewer from '@/components/DocumentViewer/ImageDocumentViewer';
import { useDownloadDocumentQuery } from '@/api/documentsApi';
import { Spinner } from '../Spinner';
import { showErrorToast } from '@/utils/toast';
import { isImageFile, isPDFTronFile } from '@/constants/files';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import PdfDocumentViewer from '@/components/DocumentViewer/PdfDocumentViewer';
import { WebDocumentViewer } from '@/components/DocumentViewer/WebDocumentViewer';

interface DocumentViewerProps {
  documentId?: string;
  subDocumentId?: string;
  target: 'general' | 'signatory' | 'share';
  token?: string | null;
  onDownloadCompleted?: (str: string) => void;
  onDownloadError?: () => void;
  className?: string;
  printMode?: boolean;
}

const DocumentViewer = forwardRef(
  (
    {
      documentId,
      subDocumentId,
      target,
      token,
      onDownloadError,
      className,
      printMode,
      onDownloadCompleted,
    }: DocumentViewerProps,
    componentRef
  ) => {
    if (!documentId) return null;

    const { t } = useTranslation();

    const { data, isFetching, isError } = useDownloadDocumentQuery({
      documentId,
      subDocumentId,
      target,
      token,
    });

    const { fileType, filePath } = useMemo(() => {
      return {
        fileType: data?.extension?.toLowerCase() || '',
        filePath: data?.content || '',
      };
    }, [data]);

    useEffect(() => {
      const path = data?.content || '';
      if (onDownloadCompleted && path) {
        onDownloadCompleted(path);
      }
    }, [data]);

    const isPDF = fileType === 'pdf';
    const isTxt = fileType === 'txt';
    const isPDFTron = isPDFTronFile(fileType);
    const isImage = isImageFile(fileType);

    useEffect(() => {
      if (isError) {
        if (onDownloadError) {
          onDownloadError();
        } else {
          showErrorToast(t('DocumentContentView.invalidFile'));
        }
      }
    }, [isError]);

    const viewer = () => {
      if (isTxt) return <TextDocumentViewer filePath={filePath} printMode={printMode} />;
      if (isPDF) return <PdfDocumentViewer filePath={filePath} printMode={printMode} />;
      if (isImage) return <ImageDocumentViewer filePath={filePath} />;
      return (
        <WebDocumentViewer
          filePath={filePath}
          fileType={fileType}
          fileName={data?.name ?? ''}
          componentRef={componentRef}
        />
      );
    };

    if (isFetching) {
      return <Spinner size="large" className="pt-[200px]" />;
    }

    return (
      <div
        className={clsx(
          'flex justify-center items-center w-auto h-[calc(100vh-140px)] relative',
          isPDFTron ? 'p-0' : 'p-5 max-sm:px-0',
          className || 'my-[40px]'
        )}
      >
        {viewer()}
      </div>
    );
  }
);

export default DocumentViewer;

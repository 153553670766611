import React from 'react';
import clsx from 'clsx';
import DebouncedInput from '@/components/DebouncedInput/DebouncedInput';

interface TextFilterProps {
  initValue?: string;
  label: string;
  placeholder: string;
  onSubmit: (str: string) => void;
  delay?: number;
}

const TextFilter = ({
  initValue,
  label,
  placeholder,
  onSubmit,
  delay,
}: TextFilterProps) => {
  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label
      className={clsx(
        'input_label',
        'bg-white-primary b',
        'py-3 px-25px md:px-7.5 xl:px-10 xl:py-4 text-left block',
        ''
      )}
    >
      {label}
      <div className="flex items-center justify-between">
        <DebouncedInput
          initValue={initValue}
          className={clsx(
            'font_primary_base',
            'w-full h-8 mt-[10px] outline-none bg-transparent pr-[5px]'
          )}
          onChange={onSubmit}
          placeholder={placeholder}
          delay={delay}
        />
      </div>
    </label>
  );
};

export default TextFilter;

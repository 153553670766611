import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useTable, Column, usePagination, useGlobalFilter, Cell, Row } from 'react-table';
import ArrowIcon from '@/assets/icons/back.svg';
import { Button } from '../../Button';
import { ROUTES } from '@/constants/routes';
import { constructRoute } from '@/utils/routes';
import { Spinner } from '../../Spinner';
import { useResendUserInvitationMutation } from '@/api/adminUserApi';
import { showSuccessToast } from '@/utils/toast';
import { UserInfo } from '@/types/user';

interface UserTableProps {
  columns: Column<UserInfo>[];
  data: UserInfo[];
  showPreviousPage: () => void;
  showNextPage: () => void;
  isLoadingData: boolean;
  currentPageNumber: number;
  total?: number;
  pageSize?: number;
}

interface TableStylesProps {
  name?: string;
  inviteButton?: string;
  email?: string;
  speciality?: string;
  documents?: string;
}

const cellStyles: TableStylesProps = {
  inviteButton: 'cursor-pointer',
  name: 'cursor-pointer max-md:text-15px font-bold',
  email: 'cursor-pointer max-md:text-15px',
  documents: 'max-md:text-15px',
};

const headerStyles: TableStylesProps = {};

const UserTable = ({
  columns,
  data,
  showNextPage,
  showPreviousPage,
  isLoadingData,
  currentPageNumber,
  total = 0,
  pageSize = 10,
}: UserTableProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { headerGroups, prepareRow, page } = useTable(
    {
      columns,
      data,
      manualPagination: true,
    },
    useGlobalFilter,
    usePagination
  );

  const [resendUserInvite, { isLoading }] = useResendUserInvitationMutation();

  const handleUserNameClick = (id: string) => {
    navigate(constructRoute(ROUTES.USER_ACCOUNT, id));
  };

  const handleCellClick = (cell: Cell<UserInfo>, row: Row<UserInfo>) => async () => {
    if (cell.column.id === 'name') {
      handleUserNameClick(row.original.id);
    }
    if (cell.column.id === 'inviteButton') {
      await resendUserInvite({ id: row.original.id });
      showSuccessToast(t('InviteUser.invitationSent'));
    }
  };

  const allPagesNumber = Math.ceil(total / pageSize);

  const hasPreviousPage = currentPageNumber !== 1;
  const hasNextPage = currentPageNumber !== allPagesNumber && total > 0;

  return (
    <>
      {isLoadingData ? (
        <Spinner size="medium" />
      ) : (
        <div
          className={clsx(
            'overflow-hidden max-xl:w-full lg:pt-8 pt-0',
            'border-t-3 border-t-gray-secondary'
          )}
        >
          {isLoading && <Spinner fullScreen size="huge" />}
          <table className="table-auto w-full border-spacing-[6px]">
            <thead className="mb-[18px]">
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map(column => {
                    return (
                      <th
                        {...column.getHeaderProps()}
                        className={clsx(
                          'section-subtitle py-[18px]',
                          'max-xl:px-[25px] max-md:text-11px 3xl:px-10',
                          'max-sm:px-[7px] first:md:pl-7.5 first:pl-[25px]',
                          'first:xl:pl-12.5 last:pr-[25px] last:md:pr-7.5',
                          'last:xl:pr-12.5 last:3xl:pl-0 !font-normal',
                          column.id === 'name' || column.id === 'email'
                            ? 'text-left pl-[25px]'
                            : 'text-center',
                          headerStyles[column.id as keyof TableStylesProps]
                        )}
                        key={column.id}
                      >
                        {column.render('Header')}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody className="bg-white-primary">
              {page.map(row => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    className="border-b-3 border-b-gray-secondary relative"
                    key={row.id}
                  >
                    {row.cells.map((cell, index) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={clsx(
                            'table_cell',
                            'py-[39px] 3xl:px-10 xl:px-5 max-xl:px-[25px]',
                            'max-sm:py-[18px] first:md:pl-7.5 max-sm:px-[7px]',
                            'first:xl:pl-12.5 last:md:pr-7.5 first:pl-25px last:2xl:pl-0',
                            'last:xl:pr-12.5 last:3xl:pr-12.5 last:pr-25px',
                            'overflow-hidden text-ellipsis whitespace-nowrap',
                            'max-sm:max-w-[160px] sm:max-w-[160px] md:max-w-[160px] xl:max-w-[220px] 2xl:max-w-[300px]',
                            cellStyles[cell.column.id as keyof TableStylesProps],
                            cell.column.id !== 'name' &&
                              cell.column.id !== 'inviteButton' &&
                              'pointer-events-none',
                            cell.column.id === 'name' || cell.column.id === 'email'
                              ? 'text-left'
                              : 'text-center',
                            cell.column.id === 'documentsCount' && '!w-[100px]',
                            cell.column.id === 'invitedAt' && '!w-[160px]',
                            cell.column.id === 'inviteButton' && '!w-[80px]'
                          )}
                          onClick={handleCellClick(cell, row)}
                          key={row.id + index}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      <div
        className={clsx(
          'flex w-full background_primary text-white-primary items-center justify-between'
        )}
      >
        {(hasPreviousPage || hasNextPage) && (
          <Button
            type="button"
            onClick={showPreviousPage}
            disabled={!hasPreviousPage}
            className={clsx(
              'flex items-center section-subtitle justify-center w-[275px] h-[107px]',
              'max-sm:h-[77px] p-0 max-sm:w-[70px]'
            )}
          >
            <img src={ArrowIcon} className="h-[24px] h-[24px]" alt="" />
            <span className="text-white-primary ml-[40px] max-sm:hidden">
              {t('Pagination.previous')}
            </span>
          </Button>
        )}
        <div
          className={clsx(
            'h-full text-18px uppercase tracking-widest pagination_page_number w-full',
            'flex items-center justify-center py-[40px] max-sm:py-[25px]',
            hasPreviousPage && 'border-l-white-secondary border-l-3',
            hasNextPage && 'border-r-white-secondary border-r-3'
          )}
        >
          {t('Pagination.pageNumber', {
            currentPage: currentPageNumber,
            allPages: total === 0 ? 1 : allPagesNumber,
          })}
        </div>
        {(hasPreviousPage || hasNextPage) && (
          <Button
            type="button"
            onClick={showNextPage}
            disabled={!hasNextPage}
            className={clsx(
              'flex items-center section-subtitle justify-center w-[275px] h-[107px]',
              'max-sm:h-[77px] p-0 max-sm:w-[70px]'
            )}
          >
            <span className="text-white-primary mr-[40px] max-sm:hidden">
              {t('Pagination.next')}
            </span>
            <img src={ArrowIcon} className="-rotate-180 w-[12px] h-[24px]" alt="" />
          </Button>
        )}
      </div>
    </>
  );
};

export default UserTable;

import clsx from 'clsx';
import { Switch as SwitchComponent } from '@headlessui/react';
import { Paragraph } from '@/components/Typography/Paragraph';

interface SwitchUncontrolledProps {
  text?: string;
  className?: string;
  textClassname?: string;
  disabled?: boolean;
  value: boolean;
  onChange: (value: boolean) => void;
}

const SwitchUncontrolled = ({
  text,
  className,
  textClassname,
  disabled,
  onChange,
  value,
}: SwitchUncontrolledProps) => {
  return (
    <div
      className={clsx('flex items-center', disabled && 'cursor-not-allowed opacity-50')}
    >
      <SwitchComponent
        checked={value}
        onChange={onChange}
        className={clsx(
          'relative inline-flex h-[20px] w-12.5 pl-3px background_tetriary',
          'focus:outline-none focus-visible:ring-opacity-75',
          'cursor-pointer duration-200 ease-in-out flex-shrink-0',
          disabled && 'pointer-events-none',
          className
        )}
      >
        <span
          className={clsx(
            value ? 'background_primary' : 'bg-white-primary',
            value && 'translate-x-22px',
            'my-auto pointer-events-none inline-block h-[14px] w-[22px]',
            'transform shadow-lg transition duration-200 ease-in-out'
          )}
        />
      </SwitchComponent>
      {text && <Paragraph className={clsx('pl-18px', textClassname)}>{text}</Paragraph>}
    </div>
  );
};

export default SwitchUncontrolled;

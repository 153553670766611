import { useMemo } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ROUTES } from './constants/routes';
import AuthView from './pages/auth/AuthView';
import ConfirmToken from './pages/auth/ConfirmToken';
import AcceptInvitation from './pages/auth/AcceptInvitation';
import NewPassword from './pages/auth/NewPassword';
import ResetPassword from './pages/auth/ResetPassword';
import SignIn from './pages/auth/SignIn';
import SuccessRegistration from './pages/auth/SuccessRegistration';
import PartyApproval from './pages/parties/PartyApproval';
import PartyShareView from './pages/parties/PartyShareView';
import { useAppSelector } from './utils/hooks';
import AuthenticatedRoutes from './AuthenticatedRoutes';
import RotatePassword from '@/pages/auth/RotatePassword';
import { ScrollToTop } from '@/utils/scroll';

const AppRouter = () => {
  const isAuthenticated = useAppSelector(state => state.auth.isAuthenticated);

  const routes = useMemo(
    () =>
      isAuthenticated ? (
        <AuthenticatedRoutes />
      ) : (
        <Routes>
          <Route path={ROUTES.HOME} element={<AuthView />}>
            <Route index element={<SignIn />} />
            <Route path={ROUTES.ACCEPT_INVITATION} element={<AcceptInvitation />} />
            <Route path={ROUTES.VERIFIED_ACCOUNT} element={<SuccessRegistration />} />
            <Route path={ROUTES.RESET_PASSWORD} element={<ResetPassword />} />
            <Route path={ROUTES.NEW_PASSWORD} element={<NewPassword />} />
            <Route path={ROUTES.ROTATE_PASSWORD} element={<RotatePassword />} />
            <Route path={ROUTES.CONFIRM_TOKEN} element={<ConfirmToken />} />
            <Route path={ROUTES.SETUP_2FA} element={<ConfirmToken />} />
          </Route>
          <Route path={ROUTES.PARTY_APPROVAL} element={<PartyApproval />} />
          <Route path={ROUTES.SHARE_VIEW} element={<PartyShareView />} />
          <Route path="*" element={<Navigate to={ROUTES.HOME} />} />
        </Routes>
      ),
    [isAuthenticated]
  );

  if (isAuthenticated === null) {
    return null;
  }

  return (
    <BrowserRouter>
      <ScrollToTop />
      {routes}
    </BrowserRouter>
  );
};

export default AppRouter;

import { Document, DocumentListItem } from '@/types/document';

export function documentToListItem(document: Document): DocumentListItem {
  return {
    id: document.id,
    createdAt: document.createdAt,
    title: document.title,
    type: document.type,
    client: document.client,
    agreementDate: document.agreementDate,
    signatories: document.signatories.length,
    user: document.user,
    linkedDocuments: [
      ...(document.history?.previousLinkedDocuments ?? []).map(linkedDocument =>
        documentToListItem(linkedDocument)
      ),
      ...(document.history?.nextLinkedDocuments ?? []).map(linkedDocument =>
        documentToListItem(linkedDocument)
      ),
    ],
  };
}

import clsx from 'clsx';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { ReactComponent as Logo } from '@/assets/images/logo-header.svg';
import { PageTitle } from '../Typography/Title/PageTitle';
import { ROUTES } from '@/constants/routes';

interface ThirdPartyPageViewProps {
  pageTitle: string;
  children: React.ReactNode;
  containerClassName?: string;
}

const ThirdPartyPageView = ({
  pageTitle,
  children,
  containerClassName,
}: ThirdPartyPageViewProps) => {
  const { t } = useTranslation();

  const year = new Date().getFullYear();

  return (
    <div className="background_tetriary w-full pb-[150px]">
      <div
        className={clsx(
          'max-w-[1480px] mx-[auto] relative',
          'w-[90%] max-sm:w-[95%]',
          containerClassName
        )}
      >
        <div className={clsx('pt-16 max-lg:pt-10 max-sm:pt-7')}>
          <NavLink to={ROUTES.HOME} className={clsx('block')}>
            <Logo className="w-[309px] h-auto" />
          </NavLink>
          <PageTitle
            className={clsx(
              'text-center block max-sm:!pt-7.5 max-xl:!pt-[45px] xl:!pt-[45px]',
              'xl:!mb-[45px]'
            )}
          >
            {pageTitle}
          </PageTitle>
        </div>
        {children}
        <div className="mt-[63px]">
          <p className="font_primary_base">{t('Footer.copyright', { year })}</p>
        </div>
      </div>
    </div>
  );
};

export default ThirdPartyPageView;

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { SubmitHandler, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { useNavigate } from 'react-router-dom';

import { Title } from '@/components/Typography/Title';
import { Paragraph } from '@/components/Typography/Paragraph';
import { Button } from '@/components/Button';
import { CircularTitle } from '@/components/CircularTitle';
import { AuthContent } from '@/components/AuthContent';
import { FormData, newPasswordSchema } from '@/forms/newPasswordForm';
import { TextInput } from '@/components/TextInput';
import { CircularRequired } from '@/components/CircularRequired';
import { useRotatePasswordMutation } from '../../api/authApi';
import { ErrorMessage } from '@/components/ErrorMessage';
import { ROUTES } from '@/constants/routes';
import { showSuccessToast } from '@/utils/toast';
import { TwoFactorSetupModal } from '@/components/Modal/TwoFactorSetupModal';
import { TwoFactorAuthModal } from '@/components/Modal/TwoFactorAuthModal';
import { Spinner } from '@/components/Spinner';
import { useAppSelector } from '@/utils/hooks';
import { Setup2faAction, TokenAction } from '@/types/auth';

const RotatePassword = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const loginSessionKey = useAppSelector(state => state.auth.loginSessionKey);
  const setup2faCode = useAppSelector(state => state.auth.setup2FaCode);

  const [is2FaModalOpen, setIs2FaModalOpen] = useState<boolean>(false);
  const [isSetup2FaModalOpen, setIsSetup2FaModalOpen] = useState<boolean>(false);

  const [rotatePassword, { data, error, isLoading, isSuccess }] =
    useRotatePasswordMutation();

  const { handleSubmit, control, watch } = useForm<FormData>({
    resolver: joiResolver(newPasswordSchema),
    defaultValues: {
      password: '',
      passwordRepeat: '',
    },
  });

  const password = watch('password');
  const passwordRepeat = watch('passwordRepeat');

  const open2FaModal = () => {
    setIs2FaModalOpen(true);
    window.history.pushState({ tfaModal: true }, '', window.location.href);
  };

  const handleCloseModal = () => {
    setIs2FaModalOpen(false);
    window.history.back();
  };

  const openSetup2FaModal = () => {
    setIsSetup2FaModalOpen(true);
    window.history.pushState({ tfaModal: true }, '', window.location.href);
  };

  const handleCloseSetup2FaModal = () => {
    setIsSetup2FaModalOpen(false);
    window.history.back();
  };

  window.onpopstate = () => {
    if (is2FaModalOpen) setIs2FaModalOpen(false);
    if (isSetup2FaModalOpen) setIsSetup2FaModalOpen(false);
  };

  useEffect(() => {
    if (isSuccess) {
      if (data?.action === TokenAction) {
        showSuccessToast(t('RotatePassword.passwordChanged'));
        navigate(ROUTES.HOME);
      }
      if (data?.action === Setup2faAction && setup2faCode) {
        openSetup2FaModal();
      }
    }
  }, [isSuccess]);

  const onSubmit: SubmitHandler<FormData> = () => {
    open2FaModal();
  };

  const onSubmitWith2fa = ({ confirmationToken }: { confirmationToken: string }) => {
    if (loginSessionKey) {
      rotatePassword({
        sessionKey: loginSessionKey,
        token: confirmationToken,
        password,
      });
      handleCloseModal();
    }
  };

  return (
    <div className="flex justify-end relative">
      <CircularTitle>{t('Common.password')}</CircularTitle>
      <CircularRequired />
      <AuthContent>
        {isLoading && <Spinner fullScreen size="huge" />}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            className={clsx(
              'flex w-full pt-7 justify-end flex-col-reverse',
              'xl:gap-3px xl:flex-row xl:pt-12'
            )}
          >
            <div className="flex flex-1 flex-col justify-between">
              <div>
                <Title
                  level="h2"
                  className="mb-6 px-25px md:px-7.5 xl:mt-0 mt-7 xl:mb-10 xl:px-10"
                >
                  {t('RotatePassword.title')}
                </Title>
                <Paragraph className="px-25px pb-9 md:pb-4 xl:pb-11 xl:px-10">
                  {t('RotatePassword.description')}
                </Paragraph>
              </div>
              <TextInput
                label={t('RotatePassword.newPassword')}
                name="password"
                isRequired
                isPassword
                control={control}
              />
              <TextInput
                label={t('RotatePassword.confirmNewPassword')}
                name="passwordRepeat"
                isRequired
                isPassword
                control={control}
              />
              <ErrorMessage error={error} resetOn={[password, passwordRepeat]} />
              <Button disabled={isLoading} isSubmit>
                {t('Common.submit')}
              </Button>
            </div>
          </div>
        </form>
        <TwoFactorAuthModal
          isOpen={is2FaModalOpen}
          onClose={handleCloseModal}
          onTokenSubmit={onSubmitWith2fa}
        />
        <TwoFactorSetupModal
          isOpen={isSetup2FaModalOpen}
          onClose={handleCloseSetup2FaModal}
          code={setup2faCode}
        />
      </AuthContent>
    </div>
  );
};

export default RotatePassword;

import clsx from 'clsx';
import { Listbox } from '@headlessui/react';

import { ReactComponent as ChevronDown } from '@/assets/icons/chevron-down.svg';
import { Option } from './SelectInput';

interface SelectInputUncontrolledProps {
  error?: string;
  name: string;
  isRequired?: boolean;
  label: string;
  options: Option[];
  value?: string;
  setValue: (value: string) => void;
  placeholder?: string;
}

export const SelectInputUncontrolled = ({
  error,
  name,
  isRequired,
  label,
  options,
  value,
  setValue,
  placeholder,
}: SelectInputUncontrolledProps) => {
  const titleValue = options?.find((item: Option) => item.value === value)?.label;

  return (
    <Listbox value={value} onChange={setValue}>
      {({ open }) => (
        <label
          htmlFor={name}
          className={clsx(
            'input_label',
            'relative py-3 px-25px md:px-7.5 text-left block',
            'xl:px-10 xl:py-4',
            {
              'border-white-primary': !error,
              'bg-red-quaternary border-red-primary border-l-3': !!error,
            },
            open && 'background_tetriary shadow-xl',
            !open && 'bg-white-primary'
          )}
        >
          {label}
          {isRequired && '*'}
          <Listbox.Button
            className={clsx(
              'listbox_button',
              'w-full h-8 mt-[10px] xl:mt-[3px] outline-none',
              'bg-transparent flex items-center justify-between',
              'relative xl:h-12 border-b-[1px]',
              {
                'border-b-white-primary': !open,
                'border-b-gray-tetriary z-[99]': open,
              }
            )}
          >
            <span className="capitalize tracking-normal">
              {titleValue ?? value ?? placeholder}
            </span>
            <ChevronDown className={clsx(open && 'rotate-180')} />
          </Listbox.Button>
          <div
            className={clsx(
              'absolute top-[100%] pt-0 px-25px md:px-7.5 xl:px-10 left-0 z-50',
              'background_tetriary xl:pr-[30px] w-full',
              'xl:translate-y-[-6%] translate-y-[-4%]',
              open && 'shadow-xl'
            )}
          >
            <Listbox.Options
              className={clsx(
                'relative overflow-auto max-h-[250px] mb-7.5',
                'xl:scrollbar xl:pr-[19px]'
              )}
            >
              {options?.map((item, id) => (
                <Listbox.Option
                  key={id}
                  value={item.value}
                  className={clsx(
                    'listbox_option',
                    'cursor-pointer py-18px border-b-gray-tetriary border-b-[1px] z-50'
                  )}
                >
                  {item.label}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </div>
        </label>
      )}
    </Listbox>
  );
};

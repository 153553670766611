import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { ContentLayout } from '@/components/ContentLayout/ContentLayout';
import { Spinner } from '@/components/Spinner';
import { Title } from '@/components/Typography/Title';
import TextFilter from '../../components/TextFilter/TextFilter';
import { scrollToTableTop } from '@/utils/scroll';
import { SelectInputUncontrolled } from '@/components/SelectInput/SelectInputUncontrolled';
import ActivityTable from '@/components/Table/ActivityTable/ActivityTable';
import AllNFTsIcon from '@/assets/icons/nfts-gray.svg';
import { useGetActivityQuery } from '@/api/systemApi';
import { ActivityAction } from '@/types/activity';
import { ACTIVITY_COLUMNS } from '@/components/Table/ActivityTable/columns/activity';

export const DEFAULT_PAGE_SIZE = 30;
export const DEFAULT_PAGE_NUMBER = 1;

const Activity = () => {
  const { t } = useTranslation();
  const ALL_FILTER = t('Activity.filters.all');

  const [emailFilter, setEmailFilter] = useState<string | undefined>(undefined);
  const [targetFilter, setTargetFilter] = useState<string | undefined>(undefined);
  const [pageNumber, setPageNumber] = useState<number>(DEFAULT_PAGE_NUMBER);
  const [actionFilter, setActionFilter] = useState(ALL_FILTER);

  const { data, isFetching: isFetchingActivity } = useGetActivityQuery({
    filters: {
      email: emailFilter || undefined,
      on: targetFilter || undefined,
      action: actionFilter === ALL_FILTER ? undefined : actionFilter,
      offset: DEFAULT_PAGE_SIZE * (pageNumber - 1),
      limit: DEFAULT_PAGE_SIZE,
    },
  });

  const actionOptions = [
    { value: ALL_FILTER, label: ALL_FILTER },
    { value: ActivityAction.UserLoggedIn, label: t('Activity.actions.UserLoggedIn') },
    {
      value: ActivityAction.AdminInvitedUser,
      label: t('Activity.actions.AdminInvitedUser'),
    },
    {
      value: ActivityAction.AdminResendUserInvitation,
      label: t('Activity.actions.AdminResendUserInvitation'),
    },
    {
      value: ActivityAction.AdminDeletedUser,
      label: t('Activity.actions.AdminDeletedUser'),
    },
    {
      value: ActivityAction.AdminResetUserCredentials,
      label: t('Activity.actions.AdminResetUserCredentials'),
    },
    {
      value: ActivityAction.UserAcceptedInvitation,
      label: t('Activity.actions.UserAcceptedInvitation'),
    },
    {
      value: ActivityAction.UserChangedPassword,
      label: t('Activity.actions.UserChangedPassword'),
    },
    {
      value: ActivityAction.UserCreatedDocument,
      label: t('Activity.actions.UserCreatedDocument'),
    },
    {
      value: ActivityAction.UserResendDocumentToParty,
      label: t('Activity.actions.UserResendDocumentToParty'),
    },
    {
      value: ActivityAction.PartyRequestedDocument,
      label: t('Activity.actions.PartyRequestedDocument'),
    },
    {
      value: ActivityAction.PartySignedDocument,
      label: t('Activity.actions.PartySignedDocument'),
    },
    {
      value: ActivityAction.UserVerifierDocumentParty,
      label: t('Activity.actions.UserVerifierDocumentParty'),
    },
    {
      value: ActivityAction.UserRejectedDocumentParty,
      label: t('Activity.actions.UserRejectedDocumentParty'),
    },
    {
      value: ActivityAction.UserSharedDocument,
      label: t('Activity.actions.UserSharedDocument'),
    },
    {
      value: ActivityAction.PartyRequestedSharedDocument,
      label: t('Activity.actions.PartyRequestedSharedDocument'),
    },
    {
      value: ActivityAction.AdminTransferredDocument,
      label: t('Activity.actions.AdminTransferredDocument'),
    },
  ];

  const getNextPageData = () => {
    setPageNumber(prev => prev + 1);
    scrollToTableTop();
  };

  const getPreviousPageData = () => {
    if (pageNumber > 1) {
      setPageNumber(prev => prev - 1);
      scrollToTableTop();
    }
  };

  const onActionChanged = (value: string) => {
    setPageNumber(1);
    setActionFilter(value);
    scrollToTableTop();
  };

  const onEmailChanged = (value: string) => {
    setPageNumber(1);
    setEmailFilter(value);
    scrollToTableTop();
  };

  const onTargetChanged = (value: string) => {
    setPageNumber(1);
    setTargetFilter(value);
    scrollToTableTop();
  };

  return (
    <ContentLayout title={t('Activity.labels.title')}>
      <div className={clsx('grid-cols-2 grid grid-rows-auto w-full pt-0')}>
        <Title level="h2" className="px-25px py-7.5 md:px-7.5 xl:py-[42px] xl:px-10">
          {t('Activity.labels.events')}
        </Title>
        <div className="flex items-center justify-end content-layout">
          <img src={AllNFTsIcon} alt="" />
          <div
            className={clsx(
              'text-18px ml-[5px] text_primary flex',
              'items-center justify-center'
            )}
          >
            <span className="max-xl:hidden">{t('Activity.labels.total')}:</span>
            <span className="ml-[2px]">
              {isFetchingActivity ? (
                <Spinner size="super-small" />
              ) : (
                <span className="font-bold">{data?.total ?? 0}</span>
              )}
            </span>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 md:gap-x-[3px] gap-y-[3px]">
        <TextFilter
          label={t('Activity.filters.email')}
          placeholder={t('Activity.filters.emailPlaceholder')}
          onSubmit={onEmailChanged}
        />
        <TextFilter
          label={t('Activity.filters.target')}
          placeholder={t('Activity.filters.targetPlaceholder')}
          onSubmit={onTargetChanged}
        />
        <SelectInputUncontrolled
          label={t('Activity.filters.action')}
          name="documentType"
          options={actionOptions}
          value={actionFilter}
          setValue={onActionChanged}
        />
      </div>
      {isFetchingActivity ? (
        <Spinner size="medium" />
      ) : (
        <ActivityTable
          columns={ACTIVITY_COLUMNS}
          data={data?.events ?? []}
          isLoadingData={isFetchingActivity}
          showNextPage={getNextPageData}
          showPreviousPage={getPreviousPageData}
          total={data?.total}
          currentPageNumber={pageNumber}
          pageSize={DEFAULT_PAGE_SIZE}
        />
      )}
    </ContentLayout>
  );
};

export default Activity;

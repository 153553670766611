import { useForm } from 'react-hook-form';
import { useEffect } from 'react';
import { joiResolver } from '@hookform/resolvers/joi';
import { useTranslation } from 'react-i18next';
import { Button } from '../Button';
import { contactUsFormSchema, ContactUsProps } from '@/forms/contactUsForm';
import { TextInput } from '../TextInput';
import { Title } from '../Typography/Title';
import { TextareaInput } from '../TextareaInput';
import { showSuccessToast } from '@/utils/toast';
import { useSendMessageMutation } from '@/api/systemApi';

export const ContactUs = () => {
  const { t } = useTranslation();
  const [sendMessage, { isSuccess }] = useSendMessageMutation();
  const { handleSubmit, control, reset } = useForm({
    resolver: joiResolver(contactUsFormSchema),
    defaultValues: {
      subject: '',
      message: '',
    },
  });
  const onSubmit = (data: ContactUsProps) => {
    const { subject, message } = data;
    sendMessage({ subject, message });
    reset();
  };

  useEffect(() => {
    if (isSuccess) {
      showSuccessToast(t('ContactUs.successMessage'));
    }
  }, [isSuccess]);

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="content-layout">
          <Title level="h4">{t('ContactUs.title')}</Title>
        </div>
        <TextInput label={t('ContactUs.subject')} name="subject" control={control} />
        <TextareaInput label={t('ContactUs.message')} name="message" control={control} />
        <Button type="submit" className="w-full">
          {t('ContactUs.buttonText')}
        </Button>
      </form>
    </div>
  );
};

import Joi from 'joi';
import i18next from '@/translations';

export interface TwoFactorSetupFormData {
  token: string;
}

export const twoFactorSetupFormSchema = Joi.object({
  token: Joi.string()
    .trim()
    .required()
    .messages({
      'string.empty': i18next.t('Common.errors.fieldRequired'),
    }),
});

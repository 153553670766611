import Joi from 'joi';
import i18next from '@/translations';

export interface ContactUsProps {
  subject: string;
  message: string;
}

export const contactUsFormSchema = Joi.object({
  subject: Joi.string()
    .trim()
    .max(255)
    .required()
    .messages({
      'string.empty': i18next.t('Common.errors.fieldRequired'),
      'string.max': i18next.t('Common.errors.tooLong'),
    }),
  message: Joi.string()
    .trim()
    .max(2000)
    .required()
    .messages({
      'string.empty': i18next.t('Common.errors.fieldRequired'),
      'string.max': i18next.t('Common.errors.tooLong'),
    }),
}).required();

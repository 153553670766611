import clsx from 'clsx';

import { useEffect, useState } from 'react';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { SerializedError } from '@reduxjs/toolkit';
import { isErrorWithMessage } from '@/utils/errors';
import { ServerErrors } from '@/translations/en.json';
import i18next from '@/translations';

interface ErrorMessageProps {
  error: FetchBaseQueryError | SerializedError | undefined;
  resetOn?: any[];
}

const ErrorMessage = ({ error, resetOn }: ErrorMessageProps): JSX.Element => {
  const [errorCode, setErrorCode] = useState<keyof typeof ServerErrors | null>(null);

  useEffect(() => {
    if (error) setErrorCode(isErrorWithMessage(error) ? error.data.code : null);
  }, [error]);

  useEffect(() => {
    if (error) setErrorCode(null);
  }, resetOn);

  if (!errorCode) {
    return <div />;
  }

  return (
    <div
      className={clsx(
        'bg-red-tetriary text-16px px text-red-secondary flex justify-center',
        'items-center mb-3px h-17.5 xl:h-25 xl:text-18'
      )}
    >
      {i18next.t(`ServerErrors.${errorCode}`)}
    </div>
  );
};

export default ErrorMessage;

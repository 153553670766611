import clsx from 'clsx';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { PageTitle } from '@/components/Typography/Title/PageTitle';
import { Title } from '@/components/Typography/Title';
import { DocumentViewer } from '@/components/DocumentViewer';
import { Button } from '@/components/Button';
import { showErrorToast } from '@/utils/toast';
import { PartyApprovalSuccess } from '@/pages/parties/PartyApprovalSuccess';
import { ReactComponent as FailureIcon } from '@/assets/icons/cross-round.svg';
import {
  useGetDocumentSignatoryViewQuery,
  useSignatoryApproveMutation,
} from '../../api/documentsApi';
import { Spinner } from '@/components/Spinner';
import { ThirdPartyPageView } from '@/components/ThirdPartyPageView';
import { fileSizeLimit, fileTypes } from '@/constants/files';
import { FileInput } from '@/components/FileInput';
import { Paragraph } from '@/components/Typography/Paragraph';
import { partyApprovalTCFormSchema } from '@/forms/partyApprovalForm';

const PartyApproval = () => {
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [previewPicture, setPreviewPicture] = useState<string | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { documentId } = useParams();
  const [searchParams] = useSearchParams();

  if (!documentId) {
    return null;
  }

  const token = searchParams.get('token') as string;

  const [approve, { isSuccess, isLoading }] = useSignatoryApproveMutation();
  const {
    data,
    isError,
    isLoading: isDocumentLoading,
  } = useGetDocumentSignatoryViewQuery({ token });

  const { handleSubmit } = useForm({
    resolver: joiResolver(partyApprovalTCFormSchema),
  });

  const onChangeHandler = (file: File) => {
    setSelectedFile(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      if (typeof reader.result === 'string') {
        setPreviewPicture(reader.result);
      } else {
        showErrorToast();
      }
    };

    reader.readAsDataURL(file);
  };

  const onResetFile = () => {
    if (inputRef.current) {
      inputRef.current.value = '';
      setSelectedFile(null);
    }
  };

  const handleChooseFile = () => inputRef?.current?.click();

  const handleApprove = async () => {
    if (!token) {
      return;
    }
    if (!selectedFile) {
      showErrorToast(t('PartyApproval.errors.noPhotoChosen'));
      return;
    }

    const formData = new FormData();
    formData.append('photo', selectedFile);
    try {
      await approve({ documentId: data!.id, token, body: formData });
    } catch {
      showErrorToast();
    }
  };

  if (isDocumentLoading) {
    return <Spinner fullScreen size="huge" />;
  }

  const renderApprovalBody = () => {
    if (isSuccess) {
      return <PartyApprovalSuccess />;
    }
    return (
      <div className="bg-white-primary flex flex-col justify-start h-full">
        <form onSubmit={handleSubmit(handleApprove)}>
          <FileInput
            accept={fileTypes.party.accept}
            limit={fileSizeLimit.party}
            extensions={fileTypes.party.extensions}
            innerRef={inputRef}
            onReset={onResetFile}
            onChange={onChangeHandler}
          />
          {previewPicture && (
            <div className="px-[60px] max-sm:px-[25px] max-2xl:py-[30px] mt-[45px]">
              <img src={previewPicture} alt="" className="m-auto max-h-[560px]" />
            </div>
          )}
          <div
            className={clsx(
              'font_primary_base',
              'bg-white-primary outline-none pr-[5px] text-center'
            )}
          >
            {selectedFile?.name ? (
              <p className="truncate">{selectedFile.name}</p>
            ) : (
              <p className="py-[40px] truncate">{t('UploadDocument.noPictureChosen')}</p>
            )}
          </div>
          {selectedFile && (
            <Button className="w-full my-10" type="submit">
              {t('UploadDocument.approve')}
            </Button>
          )}
          <Button className="w-full" type="button" onClick={handleChooseFile}>
            {t('UploadDocument.choosePic')}
          </Button>
          <div className="max-lg:order-1">
            <Paragraph className="text-center py-5">
              {t('PartyApproval.maxSize')}
            </Paragraph>
          </div>
        </form>
      </div>
    );
  };

  return (
    <ThirdPartyPageView pageTitle={data?.title || ''}>
      <div
        className={clsx(
          'background_page_content border-t-3 border-t-red-primary',
          'grid xl:grid-cols-2 gap-x-[5px] grid-cols-1',
          isSuccess && '!bg-green-secondary !border-t-green-primary',
          isError && '2xl:grid-cols-1'
        )}
      >
        {isError ? (
          <div
            className={clsx(
              'bg-red-tetriary flex items-center justify-center flex-col xl:py-48 py-24'
            )}
          >
            <FailureIcon />
            <PageTitle>{t('PartyApproval.linkExpired.title')}</PageTitle>
            <p
              className={clsx(
                'md:max-w-[940px] text-center text_primary text-18px max-w-[90%]',
                'max-md:text-14px'
              )}
            >
              {t('PartyApproval.linkExpired.description')}
            </p>
          </div>
        ) : (
          <>
            <div>
              <Title level="h4" className="content-layout">
                {t('PartyApproval.labels.preview')}
              </Title>
              <div className="bg-white-primary">
                {!!data && (
                  <DocumentViewer
                    target="signatory"
                    documentId={documentId}
                    token={token}
                    className="my-0 "
                  />
                )}
              </div>
            </div>
            <div className="flex flex-col">
              <Title level="h4" className="max-sm:leading-6 content-layout">
                {t('PartyApproval.labels.uploadApprove')}
              </Title>
              {renderApprovalBody()}
            </div>
          </>
        )}
      </div>
      {isLoading && <Spinner fullScreen size="huge" />}
    </ThirdPartyPageView>
  );
};

export default PartyApproval;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserDataResponse, UserState } from '@/types/user';

const initialState: UserState = {
  profile: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setProfile: (_state, { payload }: PayloadAction<UserDataResponse>) => {
      return {
        profile: payload,
      };
    },
    resetProfile: _state => {
      return {
        profile: null,
      };
    },
  },
});

export const { setProfile, resetProfile } = userSlice.actions;

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/Button';
import { ROUTES } from '@/constants/routes';
import { RecentDocuments } from '@/components/RecentActions/RecentDocuments';
import { Title } from '@/components/Typography/Title';
import { Paragraph } from '@/components/Typography/Paragraph';
import { PageTitle } from '@/components/Typography/Title/PageTitle';
import { useGetUserDataQuery } from '@/api/userApi';
import { useGetUserRootDocumentsQuery } from '../../api/documentsApi';

export const defaultPageSize = 3;
export const defaultSortField = 'agreementDate';
export const defaultSortOrder = 'desc';

const Dashboard = (): JSX.Element => {
  const { t } = useTranslation();
  const { data: profileDetails } = useGetUserDataQuery();

  const navigate = useNavigate();
  const name = profileDetails?.name;

  const handleButtonClick = () => navigate(ROUTES.CREATE_DOCUMENT);

  const { data, isLoading } = useGetUserRootDocumentsQuery({
    filters: {
      limit: defaultPageSize,
      sortBy: defaultSortField,
      sortOrder: defaultSortOrder,
    },
  });

  return (
    <>
      <PageTitle>{t('Dashboard.title')}</PageTitle>
      <div className="background_page_content relative max-sm:w-full">
        <div
          className={clsx(
            'relative flex items-center justify-center flex-col bg-dashboard-bg',
            'bg-cover h-[660px] max-sm:h-[370px] max-w-full overflow-hidden'
          )}
        >
          <h2 className={clsx('dashboard_header', 'text-center mb-[109px] px-10')}>
            {t('Dashboard.content.label')}
          </h2>
          <Button
            className="xl:text-[25px] max-sm:mx-[25px] button_authenticate_document"
            onClick={handleButtonClick}
          >
            {t('Dashboard.content.authenticateDocument')}
          </Button>
        </div>
        <div
          className={clsx(
            'content-layout text_primary',
            'border-t-red-primary border-t-3'
          )}
        >
          <Title
            level="h4"
            className="mb-[34px] overflow-hidden text-ellipsis whitespace-nowrap"
          >
            {t('Dashboard.content.greeting', { name })}
          </Title>
          <Paragraph>{t('Dashboard.content.description')}</Paragraph>
        </div>
        {data && data?.total > 0 && (
          <div
            className={clsx('xl:pb-[70px] pb-[40px] border-t-3 border-t-gray-secondary')}
          >
            <RecentDocuments
              documents={data?.data}
              isLoadingData={isLoading}
              totalDocumentsNumber={data?.total}
            />
          </div>
        )}
      </div>
    </>
  );
};

export default Dashboard;

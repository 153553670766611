import Joi from 'joi';
import i18next from '@/translations';
import { Signatory } from '@/types/document';

export interface CreateDocumentFormData {
  title: string;
  type: string;
  agreementDate: string;
  client: string;
  linkedDocument?: string | null;
  isCopyDescription: boolean;
  signatories: Signatory[];
  file?: File;
}

export const createDocumentFormSchema = Joi.object({
  title: Joi.when('isCopyDescription', {
    is: true,
    then: Joi.allow(null),
    otherwise: Joi.string()
      .trim()
      .max(255)
      .required()
      .messages({
        'string.empty': i18next.t('Common.errors.fieldRequired'),
        'string.max': i18next.t('Common.errors.tooLong'),
      }),
  }),
  type: Joi.when('isCopyDescription', {
    is: true,
    then: Joi.allow(null),
    otherwise: Joi.string()
      .trim()
      .max(255)
      .required()
      .messages({
        'string.empty': i18next.t('Common.errors.fieldRequired'),
        'string.max': i18next.t('Common.errors.tooLong'),
      }),
  }),
  agreementDate: Joi.when('isCopyDescription', {
    is: true,
    then: Joi.allow(null),
    otherwise: Joi.string()
      .trim()
      .required()
      .messages({ 'string.empty': i18next.t('Common.errors.fieldRequired') }),
  }),
  client: Joi.when('isCopyDescription', {
    is: true,
    then: Joi.allow(null),
    otherwise: Joi.string()
      .trim()
      .max(255)
      .required()
      .messages({
        'string.empty': i18next.t('Common.errors.fieldRequired'),
        'string.max': i18next.t('Common.errors.tooLong'),
      }),
  }),
  linkedDocument: Joi.string().trim().allow(null),
  isCopyDescription: Joi.boolean(),
  signatories: Joi.array().items(
    Joi.object({
      email: Joi.string()
        .trim()
        .email({ tlds: { allow: false } })
        .max(255)
        .required()
        .messages({
          'string.empty': i18next.t('Common.errors.fieldRequired'),
          'string.email': i18next.t('Common.errors.emailInvalid'),
          'string.max': i18next.t('Common.errors.tooLong'),
        }),
      name: Joi.string()
        .trim()
        .max(255)
        .required()
        .messages({
          'string.empty': i18next.t('Common.errors.fieldRequired'),
          'string.max': i18next.t('Common.errors.tooLong'),
        }),
      surname: Joi.string()
        .trim()
        .max(255)
        .required()
        .messages({
          'string.empty': i18next.t('Common.errors.fieldRequired'),
          'string.max': i18next.t('Common.errors.tooLong'),
        }),
    })
  ),
}).required();

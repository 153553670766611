import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CardStats from '@/components/CardStats/CardStats';
import DateRange, { RangeValue } from '@/components/DateRange/DateRange';
import { SelectInputUncontrolled } from '@/components/SelectInput/SelectInputUncontrolled';
import { Title } from '@/components/Typography/Title';
import TextFilter from '@/components/TextFilter/TextFilter';
import { useGetDocumentTypesQuery } from '@/api/documentsApi';
import AllNFTsIcon from '@/assets/icons/allnft-gray.svg';
import { getRangeFilterValue } from '@/utils/dates';
import { useGetDocumentsStatisticQuery } from '@/api/statisticApi';

const DocumentsStatsBlock = (): JSX.Element => {
  const { t } = useTranslation();
  const ALL_FILTER = t('DocumentTypes.all');

  const [userFilter, setUserFilter] = useState<string | undefined>(undefined);
  const [typeFilter, setTypeFilter] = useState(ALL_FILTER);
  const [dateRange, setDateRange] = useState<RangeValue>([null, null]);

  const { data: documentTypes } = useGetDocumentTypesQuery();
  const { data: documentsStatistic, isFetching: isFetchingDocumentsStatistic } =
    useGetDocumentsStatisticQuery({
      filters: {
        user: userFilter === '' ? undefined : userFilter,
        documentType: typeFilter === ALL_FILTER ? undefined : typeFilter,
        from: getRangeFilterValue(dateRange, 0),
        to: getRangeFilterValue(dateRange, 1),
        limit: 5,
      },
    });

  const onTypeChanged = (value: string) => {
    setTypeFilter(value);
  };

  const onUserChanged = (value: string) => {
    setUserFilter(value);
  };

  const docsLabel =
    documentsStatistic?.documents === 1
      ? t('AdminDashboard.cardStats.document')
      : t('AdminDashboard.cardStats.documents');

  const documentTypesFilterOptions = useMemo(() => {
    if (!documentTypes) return [{ value: ALL_FILTER, label: ALL_FILTER }];

    const types = documentTypes.map(documentType => ({
      value: documentType.type,
      label: documentType.type,
    }));

    return [{ value: ALL_FILTER, label: ALL_FILTER }, ...types];
  }, [documentTypes]);

  return (
    <div>
      <Title
        level="h2"
        className="px-25px py-7.5 md:px-7.5 xl:py-[42px] xl:px-10 print:py-[20px]"
      >
        {t('AdminDashboard.documents')}
      </Title>
      <div
        className={clsx(
          'grid gap-x-[3px]',
          'grid-cols-[1fr,1fr] 3xl:grid-cols-[1fr,2fr]'
        )}
      >
        <div className="grid gap-y-[3px] auto-rows-min">
          <TextFilter
            label={t('AllUsers.filters.user')}
            placeholder={t('AllUsers.filters.userPlaceholder')}
            onSubmit={onUserChanged}
          />
          <DateRange
            label="Date"
            value={dateRange}
            onChange={value => setDateRange(value)}
          />
          <SelectInputUncontrolled
            label={t('Authenticate.labels.documentType')}
            name="documentType"
            options={documentTypesFilterOptions}
            value={typeFilter}
            setValue={onTypeChanged}
          />
        </div>
        <div>
          <CardStats
            icon={AllNFTsIcon}
            value={documentsStatistic?.documents}
            isLoading={isFetchingDocumentsStatistic}
            mainText={docsLabel}
          />
        </div>
      </div>
    </div>
  );
};

export default DocumentsStatsBlock;

import clsx from 'clsx';
import { ControllerProps, FieldValues, Controller } from 'react-hook-form';

interface TextareaInputProps<T extends FieldValues>
  extends Omit<ControllerProps<T>, 'render'> {
  label: string;
  disabled?: boolean;
  className?: string;
  inputClassName?: string;
  isRequired?: boolean;
}

const TextareaInput = <T extends FieldValues>({
  label,
  disabled,
  name,
  control,
  className,
  inputClassName,
  isRequired,
}: TextareaInputProps<T>): JSX.Element => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <div className={clsx('mb-3px', className)}>
          <label
            htmlFor={name}
            className={clsx(
              'input_label',
              'bg-white-primary border-l-3 b',
              'py-3 px-25px md:px-7.5 xl:px-10 xl:py-4 text-left block',
              {
                'border-white-primary': !error,
                'bg-red-quaternary border-red-primary': !!error,
              },
              inputClassName
            )}
          >
            {label}
            {isRequired && '*'}
            <div className="flex items-center justify-between">
              <textarea
                className={clsx(
                  'font_primary_base',
                  'w-full mt-4 outline-none bg-transparent',
                  'pr-[5px] leading-6 resize-none',
                  { 'cursor-not-allowed': disabled }
                )}
                {...field}
                name={name}
                id={name}
                disabled={disabled}
                rows={6}
              />
            </div>
          </label>
          {!!error && (
            <div
              className={clsx(
                'error_message',
                'bg-red-tetriary border-l-3 pl-25px border-red-primary',
                'md:pl-7.5 py-2.5 xl:pl-12.5 text-left'
              )}
            >
              {error.message}
            </div>
          )}
        </div>
      )}
    />
  );
};

export default TextareaInput;

import clsx from 'clsx';

interface TitleProps {
  level?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  children?: React.ReactNode;
  className?: string;
}

const Title = ({ level = 'h1', children, className }: TitleProps): JSX.Element => {
  const Tag = level;

  return <Tag className={clsx('title', className)}>{children}</Tag>;
};

export default Title;

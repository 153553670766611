import { combineReducers, configureStore, PreloadedState } from '@reduxjs/toolkit';

import { authApi } from '@/api/authApi';
import { authSlice } from '@/store/authSlice';
import { AuthMiddleware } from './authMiddleware';
import { userApi } from '@/api/userApi';
import { documentsApi } from '@/api/documentsApi';
import { versionApi } from '@/api/versionApi';
import { adminUserApi } from '@/api/adminUserApi';
import { userSlice } from '@/store/userSlice';
import { statisticApi } from '@/api/statisticApi';
import { systemApi } from '@/api/systemApi';

const reducer = combineReducers({
  [authApi.reducerPath]: authApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [authSlice.name]: authSlice.reducer,
  [userSlice.name]: userSlice.reducer,
  [documentsApi.reducerPath]: documentsApi.reducer,
  [versionApi.reducerPath]: versionApi.reducer,
  [adminUserApi.reducerPath]: adminUserApi.reducer,
  [statisticApi.reducerPath]: statisticApi.reducer,
  [systemApi.reducerPath]: systemApi.reducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer,
    preloadedState,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware().concat([
        authApi.middleware,
        AuthMiddleware,
        userApi.middleware,
        documentsApi.middleware,
        versionApi.middleware,
        adminUserApi.middleware,
        statisticApi.middleware,
        systemApi.middleware,
      ]),
  });
};

export type RootState = ReturnType<typeof reducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];

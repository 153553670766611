import moment from 'moment';
import { dates } from '@/constants/dates';
import { RangeValue } from '@/components/DateRange/DateRange';

const convertedDate = (date: Date, shortenedDate?: boolean, hasTime?: boolean) => {
  return date.toLocaleDateString('en-GB', {
    day: dates.day,
    month: shortenedDate ? 'short' : dates.month,
    year: dates.year,
    hour: hasTime ? dates.hour : undefined,
    minute: hasTime ? dates.minute : undefined,
  });
};

export const getConvertedDate = (
  date?: string,
  shortenedDate?: boolean,
  hasTime?: boolean
) => {
  if (!date) return '-';
  return convertedDate(new Date(date), shortenedDate, hasTime);
};

export const getConvertedDateTimestamp = (
  date?: number,
  shortenedDate?: boolean,
  hasTime?: boolean
) => {
  if (!date) return '-';
  return convertedDate(new Date(1000 * date), shortenedDate, hasTime);
};

export const getRangeUTCValue = (dateRange: RangeValue): RangeValue => {
  if (!dateRange) return null;

  if (dateRange instanceof Array) {
    const [start, end] = dateRange;
    if (!start || !end) return null;

    const startUTC = new Date(moment(start).format('YYYY-MM-DD'));
    const endUTC = new Date(moment(end).format('YYYY-MM-DD'));

    endUTC.setUTCHours(23, 59, 59);

    return [startUTC, endUTC];
  }

  return new Date(moment(dateRange).format('YYYY-MM-DD'));
};

export const getRangeFilterValue = (dateRange: RangeValue, index: number) => {
  const rangeUTC = getRangeUTCValue(dateRange);

  return rangeUTC instanceof Array
    ? rangeUTC[index]?.toISOString()
    : rangeUTC?.toISOString();
};

import { ChangeEvent, MutableRefObject } from 'react';
import { useTranslation } from 'react-i18next';
import { showErrorToast } from '@/utils/toast';

interface FileInputProps {
  accept: string;
  limit: number;
  extensions: string[];
  innerRef: MutableRefObject<HTMLInputElement | null>;
  onReset: () => void;
  onChange: (file: File) => void;
}

export const FileInput = ({
  accept,
  limit,
  extensions,
  innerRef,
  onReset,
  onChange,
}: FileInputProps) => {
  const { t } = useTranslation();

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) return;
    if (!innerRef.current) return;

    const file = e.target.files[0];
    const extension = file.name.split('.').pop();

    if (!extension || !extensions.includes(extension.toLowerCase())) {
      onReset();
      showErrorToast(t('UploadDocument.fileTypeIsNotSupported'));
      return;
    }

    if (e.target.files[0].size > limit) {
      onReset();
      showErrorToast(t('UploadDocument.fileTooBig'));
      return;
    }

    onChange(file);
  };

  return (
    <input
      type="file"
      className="hidden"
      accept={accept}
      ref={innerRef}
      onChange={changeHandler}
    />
  );
};

export default FileInput;

import Joi from 'joi';
import i18next from '@/translations';

export interface FormData {
  email: string;
  password: string;
}

export const signUpFormSchema = Joi.object({
  email: Joi.string()
    .trim()
    .email({ tlds: { allow: false } })
    .max(255)
    .trim()
    .required()
    .messages({
      'string.empty': i18next.t('Common.errors.fieldRequired'),
      'string.email': i18next.t('Common.errors.emailInvalid'),
      'string.max': i18next.t('Common.errors.tooLong'),
    }),
  password: Joi.string()
    .trim()
    .required()
    .min(7)
    .max(255)
    .messages({ 'string.empty': i18next.t('Common.errors.fieldRequired') }),
}).required();

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { ReactComponent as SuccessIcon } from '@/assets/icons/success-icon.svg';

export const PartyApprovalSuccess = () => {
  const { t } = useTranslation();
  return (
    <div
      className={clsx(
        'bg-white-primary px-12.5 outline-none text_primary',
        'flex items-center flex-col justify-center h-full max-2xl:py-12.5'
      )}
    >
      <SuccessIcon />
      <h5 className="pt-[35px] mb-[10px] font-bold text-[25px] max-md:text-18px">
        {t('PartyApproval.success.congratulations')}
      </h5>
      <p className="text-[25px] max-md:text-18px text-center">
        {t('PartyApproval.success.description')}
      </p>
    </div>
  );
};

import { Outlet } from 'react-router-dom';
import clsx from 'clsx';

import { DashboardHeader } from '@/components/DashboardHeader';
import { DashboardSidebar } from '@/components/DashboardSidebar';
import { Footer } from '@/components/Footer';

const DashboardView = () => {
  return (
    <div className="background_tetriary h-full">
      <DashboardHeader />
      <div className="flex min-h-screen">
        <div className="xl:w-[430px] max-xl:hidden shrink-0">
          <DashboardSidebar />
        </div>
        <div
          className={clsx(
            'flex-1 h-auto',
            'max-sm:pt-[120px] max-sm:pb-[40px] pt-[150px] pb-[150px] ',
            'overflow-auto max-sm:py-[133px]',
            'max-sm:px-3 md:px-10 xl:px-15 px-[25px]',
            'background_tetriary'
          )}
        >
          <Outlet />
        </div>
      </div>
      <div className="ml-0 xl:ml-[430px]">
        <Footer containerClassName="sm:px-10 px-[25px]" />
      </div>
    </div>
  );
};

export default DashboardView;

import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/query';
import { ENDPOINTS } from '@/constants/endpoints';

export const versionApi = createApi({
  reducerPath: 'versionApi',
  tagTypes: ['Version'],
  baseQuery: fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_APP_API_URL}`,
  }),
  endpoints: builder => ({
    getVersion: builder.query<string, void>({
      query: () => ({
        url: ENDPOINTS.VERSION,
      }),
    }),
  }),
});

export const { useGetVersionQuery } = versionApi;

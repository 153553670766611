import { Column } from 'react-table';

import i18n from '@/translations';
import {
  TableCellAction,
  TableCellDateTime,
  TableCellEmail,
} from '@/components/TableCells/TableCells';
import { Activity } from '@/types/activity';

export interface CustomRowProps {
  original: Activity;
}

export const ACTIVITY_COLUMNS = [
  {
    Header: i18n.t('Activity.columns.email'),
    accessor: 'email',
    Cell: ({ row }: { row: CustomRowProps }) => TableCellEmail(row.original.actor.email),
  },
  {
    Header: i18n.t('Activity.columns.action'),
    accessor: 'action',
    Cell: ({ row }: { row: CustomRowProps }) => TableCellAction(row.original),
  },
  {
    Header: i18n.t('Activity.columns.timestamp'),
    accessor: 'createdAt',
    Cell: ({ row }: { row: CustomRowProps }) => TableCellDateTime(row.original.createdAt),
  },
] as Column<Activity>[];

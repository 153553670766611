import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';

import { Title } from '@/components/Typography/Title';
import { Paragraph } from '@/components/Typography/Paragraph';
import { Button } from '@/components/Button';
import { CircularTitle } from '@/components/CircularTitle';
import { AuthContent } from '@/components/AuthContent';
import { ROUTES } from '@/constants/routes';

const SuccessRegistration = (): JSX.Element => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const redirectToLogin = () => {
    navigate(ROUTES.HOME);
  };

  return (
    <div className="flex justify-end relative">
      <CircularTitle>{t('SuccessScreen.welcome')}</CircularTitle>
      <AuthContent>
        <div
          className={clsx(
            'flex w-full justify-end flex-col-reverse',
            'xl:gap-3px xl:flex-row',
            'sm:pt-7 xl:pt-12'
          )}
        >
          <div className="flex flex-1 flex-col justify-between">
            <div>
              <Title
                level="h2"
                className="mb-6 px-25px md:px-7.5 xl:mt-0 mt-7 xl:mb-10 xl:px-10"
              >
                {t('SuccessScreen.accountReady')}
              </Title>
              <Paragraph className="px-7.5 pb-9 md:pb-4 xl:pb-11 xl:px-10">
                {t('SuccessScreen.successMessage')}
              </Paragraph>
              <Paragraph className="px-7.5 pb-9 md:pb-15 xl:pb-25 xl:px-10">
                {t('SuccessScreen.description')}
              </Paragraph>
            </div>
            <Button onClick={redirectToLogin}>{t('Common.login')}</Button>
          </div>
        </div>
      </AuthContent>
    </div>
  );
};

export default SuccessRegistration;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button';
import { Modal } from '@/components/Modal/Modal';

interface ConfirmationModalProps {
  title: string;
  sentences: string[];
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const ConfirmationModal = ({
  title,
  isOpen,
  onClose,
  onConfirm,
  sentences,
}: ConfirmationModalProps) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={isOpen} title={title} onClose={onClose} size="super-small">
      <div className="flex max-xl:w-full pl-12.5 pr-20 flex-col">
        {sentences.map((sentence, index) => (
          <p key={`sent${index}`} className="font_primary_base mb-5">
            {sentence}
          </p>
        ))}
      </div>
      <div className="flex items-center w-full p-6 gap-x-[3px]">
        <Button onClick={onConfirm} className="w-full">
          {t('ConfirmationDialog.buttons.yes')}
        </Button>
        <Button onClick={onClose} className="w-full">
          {t('ConfirmationDialog.buttons.no')}
        </Button>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;

import clsx from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CardStats from '@/components/CardStats/CardStats';
import DateRange, { RangeValue } from '@/components/DateRange/DateRange';
import { Title } from '@/components/Typography/Title';
import TextFilter from '@/components/TextFilter/TextFilter';
import StorageIcon from '@/assets/icons/storage-gray.svg';
import { getRangeFilterValue } from '@/utils/dates';
import { humanFileSize } from '@/utils/fileSize';
import { useGetStorageStatisticQuery } from '@/api/statisticApi';
import UserTable from '@/components/Table/UserTable/UserTable';
import { STORAGE_STATISTIC_COLUMNS } from '@/components/Table/UserTable/columns/storageStatistic';

export const DEFAULT_PAGE_SIZE = 5;
export const DEFAULT_PAGE_NUMBER = 1;

const StorageStatsBlock = (): JSX.Element => {
  const { t } = useTranslation();

  const [userFilter, setUserFilter] = useState<string>('');
  const [dateRange, setDateRange] = useState<RangeValue>([null, null]);
  const [pageNumber, setPageNumber] = useState<number>(DEFAULT_PAGE_NUMBER);

  const { data: storageStatistic, isFetching: isFetchingStorageStatistic } =
    useGetStorageStatisticQuery({
      filters: {
        user: userFilter === '' ? undefined : userFilter,
        from: getRangeFilterValue(dateRange, 0),
        to: getRangeFilterValue(dateRange, 1),
        limit: DEFAULT_PAGE_SIZE,
        offset: DEFAULT_PAGE_SIZE * (pageNumber - 1),
      },
    });

  const { size, unit } = humanFileSize(storageStatistic?.storage);

  const onUserChanged = (value: string) => {
    setPageNumber(1);
    setUserFilter(value);
  };

  const onDateChanged = (value: RangeValue) => {
    setPageNumber(1);
    setDateRange(value);
  };

  const getNextPageData = () => {
    setPageNumber(prev => prev + 1);
  };

  const getPreviousPageData = () => {
    if (pageNumber > 1) {
      setPageNumber(prev => prev - 1);
    }
  };

  return (
    <div>
      <Title
        level="h2"
        className="px-25px py-7.5 md:px-7.5 xl:py-[42px] xl:px-10 print:py-[20px]"
      >
        {t('AdminDashboard.storage')}
      </Title>
      <div
        className={clsx(
          'grid gap-x-[3px]',
          'grid-cols-[1fr,1fr] 3xl:grid-cols-[1fr,2fr]'
        )}
      >
        <div className="grid gap-y-[3px] auto-rows-min">
          <TextFilter
            label={t('AllUsers.filters.user')}
            placeholder={t('AllUsers.filters.userPlaceholder')}
            onSubmit={onUserChanged}
          />
          <DateRange label="Date" value={dateRange} onChange={onDateChanged} />
        </div>
        <div>
          <CardStats
            icon={StorageIcon}
            isLoading={isFetchingStorageStatistic}
            value={size}
            subText={unit}
            mainText={t('AdminDashboard.cardStats.storageForDocuments')}
          />
        </div>
      </div>
      <UserTable
        columns={STORAGE_STATISTIC_COLUMNS}
        data={storageStatistic?.items.data ?? []}
        isLoadingData={isFetchingStorageStatistic}
        total={storageStatistic?.items.total}
        currentPageNumber={pageNumber}
        showPreviousPage={getPreviousPageData}
        showNextPage={getNextPageData}
        pageSize={DEFAULT_PAGE_SIZE}
      />
    </div>
  );
};

export default StorageStatsBlock;

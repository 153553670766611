import clsx from 'clsx';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isSubmit?: boolean;
  children: React.ReactNode;
  className?: string;
  disabled?: boolean;
}

const Button = ({ children, isSubmit, className, disabled, ...props }: ButtonProps) => {
  return (
    <button
      disabled={disabled}
      className={clsx(
        'button_label',
        'button_main',
        'flex justify-center items-center p-6 xl:p-9',
        'duration-200 ease-in-out ',
        disabled && 'button_disabled',
        className
      )}
      type={isSubmit ? 'submit' : 'button'}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;

import { Middleware } from '@reduxjs/toolkit';

import { setTokens, clearTokens, updateAccessToken } from '@/store/authSlice';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from '@/constants/storageKeys';

export const AuthMiddleware: Middleware = () => next => action => {
  if (setTokens.match(action)) {
    localStorage.setItem(ACCESS_TOKEN_KEY, action.payload.access);
    localStorage.setItem(REFRESH_TOKEN_KEY, action.payload.refresh);
  }

  if (clearTokens.match(action)) {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  }

  if (updateAccessToken.match(action)) {
    localStorage.setItem(ACCESS_TOKEN_KEY, action.payload.access);
  }

  return next(action);
};

import React, { useMemo, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import InvitationsIcon from '@/assets/icons/invitations.svg';
import { Button } from '@/components/Button';
import { ContentLayout } from '@/components/ContentLayout/ContentLayout';
import DateRange, { RangeValue } from '@/components/DateRange/DateRange';
import { Spinner } from '@/components/Spinner';
import SortInput, { SortOrders } from '@/components/SortInput/SortInput';
import { useGetUsersQuery } from '@/api/adminUserApi';
import { Title } from '@/components/Typography/Title';
import UserTable from '@/components/Table/UserTable/UserTable';
import TextFilter from '../../components/TextFilter/TextFilter';
import { InviteUserModal } from '@/components/Modal/InviteUserModal';
import { getRangeFilterValue } from '@/utils/dates';
import { scrollToTableTop } from '@/utils/scroll';
import { useModal } from '@/utils/hooks';
import { UserStatus } from '@/types/user';
import { INVITED_USERS_COLUMNS } from '@/components/Table/UserTable/columns/invitedUsers';

export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGE_NUMBER = 1;
export const DEFAULT_SORT_FIELD = 'name';
export const DEFAULT_SORT_ORDER = 'asc';

const Invitations = () => {
  const { t } = useTranslation();

  const [userFilter, setUserFilter] = useState<string | undefined>(undefined);
  const [emailFilter, setEmailFilter] = useState<string | undefined>(undefined);
  const [dateRange, setDateRange] = useState<RangeValue>([null, null]);
  const [pageNumber, setPageNumber] = useState<number>(DEFAULT_PAGE_NUMBER);
  const [sortBy, setSortBy] = useState<string>(DEFAULT_SORT_FIELD);
  const [sortOrder, setSortOrder] = useState<SortOrders>(DEFAULT_SORT_ORDER);

  const SORT_BY_OPTIONS = [
    { value: 'name', label: t('AllUsers.columns.name') },
    { value: 'email', label: t('AllUsers.columns.email') },
    { value: 'invitedAt', label: t('AllUsers.columns.invited') },
  ];

  const {
    data,
    isLoading: isUsersLoading,
    isFetching: isUsersFetching,
  } = useGetUsersQuery({
    filters: {
      offset: DEFAULT_PAGE_SIZE * (pageNumber - 1),
      limit: DEFAULT_PAGE_SIZE,
      sortBy,
      sortOrder,
      name: userFilter || undefined,
      email: emailFilter || undefined,
      status: UserStatus.Invited,
      invitedFrom: getRangeFilterValue(dateRange, 0),
      invitedTo: getRangeFilterValue(dateRange, 1),
    },
  });

  const noInvitations = useMemo(() => {
    if (userFilter || emailFilter || (dateRange && dateRange[0])) {
      return false;
    }
    return data?.total === 0;
  }, [data]);

  const getNextPageData = () => {
    setPageNumber(prev => prev + 1);
    scrollToTableTop();
  };

  const getPreviousPageData = () => {
    if (pageNumber > 1) {
      setPageNumber(prev => prev - 1);
      scrollToTableTop();
    }
  };

  const onUserChanged = (value: string) => {
    setPageNumber(1);
    setUserFilter(value);
    scrollToTableTop();
  };

  const onEmailChanged = (value: string) => {
    setPageNumber(1);
    setEmailFilter(value);
    scrollToTableTop();
  };

  const onDateRangeChanged = (value: RangeValue) => {
    setPageNumber(1);
    setDateRange(value);
    scrollToTableTop();
  };

  const onSortByChanged = (value: string) => {
    setPageNumber(1);
    setSortBy(value);
    scrollToTableTop();
  };

  const onSortOrderChanged = (value: SortOrders) => {
    setPageNumber(1);
    setSortOrder(value);
  };

  const { isModalOpen, openModal, closeModal } = useModal();

  const content = () => {
    return noInvitations ? (
      <>
        <div className={clsx('grid-cols-2 grid grid-rows-auto w-full pt-0')}>
          <Title level="h2" className="px-25px py-7.5 md:px-7.5 xl:py-[42px] xl:px-10">
            {t('InvitedUsers.subtitle')}
          </Title>
        </div>
        <div className="content-layout text-center background_tetriary m-[20px]">
          {t('InvitedUsers.empty')}
        </div>
      </>
    ) : (
      <>
        <div className={clsx('grid-cols-1 grid grid-rows-auto w-full pt-0')}>
          <div className="flex items-center justify-end content-layout">
            <img src={InvitationsIcon} alt="" />
            <div
              className={clsx(
                'text-18px ml-[5px] text_primary flex',
                'items-center justify-center'
              )}
            >
              <span className="max-xl:hidden">{t('InvitedUsers.subtitle')}:</span>
              <span className="ml-[2px]">
                {isUsersLoading ? (
                  <Spinner size="super-small" />
                ) : (
                  <span className="font-bold">{data?.total ?? 0}</span>
                )}
              </span>
            </div>
          </div>
        </div>
        <div
          className={clsx(
            'grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-2 2xl:grid-cols-3',
            'gap-y-[3px] md:gap-x-[3px] lg:mb-[37px] mb-0'
          )}
        >
          <TextFilter
            label={t('AllUsers.filters.user')}
            placeholder={t('AllUsers.filters.userPlaceholder')}
            onSubmit={onUserChanged}
          />
          <TextFilter
            label={t('AllUsers.filters.email')}
            placeholder={t('AllUsers.filters.emailPlaceholder')}
            onSubmit={onEmailChanged}
          />
          <div className="md:block lg:hidden xl:block 2xl:hidden" />
          <DateRange label="Date" value={dateRange} onChange={onDateRangeChanged} />
          <div className="md:col-span-1 lg:col-span-2 xl:col-span-1 2xl:col-span-2" />
          <div className="mt-[10px]">
            <SortInput
              label={t('AllUsers.filters.sortBy')}
              name="sortBy"
              options={SORT_BY_OPTIONS}
              sortBy={sortBy}
              setSortBy={onSortByChanged}
              sortOrder={sortOrder}
              setSortOrder={onSortOrderChanged}
              isOneLine
            />
          </div>
        </div>
        <UserTable
          columns={INVITED_USERS_COLUMNS}
          data={data?.users ?? []}
          isLoadingData={isUsersFetching}
          showNextPage={getNextPageData}
          showPreviousPage={getPreviousPageData}
          total={data?.total}
          currentPageNumber={pageNumber}
          pageSize={DEFAULT_PAGE_SIZE}
        />
      </>
    );
  };

  return (
    <ContentLayout title={t('InvitedUsers.title')}>
      {isUsersLoading ? <Spinner size="huge" /> : content()}
      <Button isSubmit className="w-full mt-[20px]" onClick={openModal}>
        {t('InvitedUsers.buttons.invite')}
      </Button>
      <InviteUserModal isOpen={isModalOpen} onClose={closeModal} />
    </ContentLayout>
  );
};

export default Invitations;

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import { ForwardedRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import WebViewer, { WebViewerInstance } from '@pdftron/webviewer';

const options = {
  path: '/webviewer',
  css: '/webviewer/style.css',
  fullAPI: true,
};

const elements = [
  'toolsHeader',
  'ribbonsDropdown',
  'toggleNotesButton',
  'menuButton',
  'toolbarGroup-View',
  'toolbarGroup-Annotate',
  'toolbarGroup-Shapes',
  'toolbarGroup-Insert',
  'toolbarGroup-Edit',
  'toolbarGroup-FillAndSign',
  'toolbarGroup-Forms',
  'selectToolButton',
  'contextMenuPopup',
];

interface WebDocumentViewerProps {
  componentRef?: ForwardedRef<unknown>;
  filePath: string;
  fileType: string;
  fileName: string;
}

export const WebDocumentViewer = ({
  filePath,
  fileType,
  fileName,
  componentRef,
}: WebDocumentViewerProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const isMounted = useRef<boolean>(false);

  const [viewerInstance, setViewerInstance] = useState<WebViewerInstance | null>(null);

  useEffect(() => {
    if (isMounted.current || !ref.current || viewerInstance) return;
    isMounted.current = true;
    WebViewer(options, ref.current as HTMLElement).then(async instance => {
      setViewerInstance(instance);
      instance.UI.disableElements(elements);
      return instance;
    });
  }, [isMounted, ref]);

  useEffect(() => {
    if (filePath && viewerInstance) {
      viewerInstance.UI.loadDocument(filePath, {
        extension: fileType,
        filename: fileName,
      });
    }
  }, [filePath, viewerInstance]);

  useImperativeHandle(componentRef, () => ({
    print() {
      viewerInstance?.UI.printInBackground();
    },
    download() {
      viewerInstance?.UI.downloadPdf();
    },
  }));

  return <div className="webviewer flex flex-1 h-full w-full" ref={ref} />;
};

import clsx from 'clsx';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Listbox } from '@headlessui/react';
import { ControllerProps, FieldValues, Controller } from 'react-hook-form';

import { ReactComponent as ChevronDown } from '@/assets/icons/chevron-down.svg';
import { Spinner } from '../Spinner';

export type Option = {
  label: string;
  value: string;
};
interface SelectInputProps<T extends FieldValues>
  extends Omit<ControllerProps<T>, 'render'> {
  label: string;
  options: Option[];
  isRequired?: boolean;
  isLoading?: boolean;
  inputClassName?: string;
}

const SelectInput = <T extends FieldValues>({
  name,
  label,
  options,
  isRequired,
  control,
  isLoading,
  inputClassName,
}: SelectInputProps<T>) => {
  const { t } = useTranslation();

  const renderOptions = useMemo(() => {
    if (!options || options.length < 1) {
      return (
        <Listbox.Option
          disabled
          value=""
          className={clsx('listbox_option', 'py-18px cursor-pointer z-50')}
        >
          {t('Common.noItemsFound')}
        </Listbox.Option>
      );
    }

    return options.map(item => {
      return (
        <Listbox.Option
          key={item.value}
          value={item.value}
          className={clsx(
            'listbox_option',
            'cursor-pointer py-18px border-b-gray-tetriary border-b-[1px] z-50'
          )}
        >
          {item.label}
        </Listbox.Option>
      );
    });
  }, [options]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        const titleValue =
          options?.find((item: Option) => item.value === value)?.label || '';
        return (
          <div className="mb-3px">
            <Listbox value={value} onChange={onChange}>
              {({ open }) => (
                <label
                  htmlFor={name}
                  className={clsx(
                    'input_label',
                    'relative py-3 px-25px md:px-7.5 text-left block',
                    'xl:px-10 xl:py-4',
                    {
                      'border-white-primary': !error,
                      'bg-red-quaternary border-red-primary border-l-3': !!error,
                    },
                    open && 'background_tetriary shadow-xl',
                    !open && 'bg-white-primary',
                    inputClassName
                  )}
                >
                  {label}
                  {isRequired && '*'}
                  <Listbox.Button
                    className={clsx(
                      'listbox_button',
                      'w-full h-8 mt-1 outline-none',
                      'bg-transparent flex items-center justify-between',
                      'relative xl:h-12 border-b-[1px]',
                      {
                        'border-b-white-primary': !open,
                        'background_tetriary z-[99]': open,
                      }
                    )}
                  >
                    <span className="capitalize text-left tracking-normal">
                      {titleValue}
                    </span>
                    <ChevronDown className={clsx(open && 'rotate-180')} />
                  </Listbox.Button>
                  <div
                    className={clsx(
                      'absolute top-[100%] pt-0 px-25px md:px-7.5 xl:px-10 left-0 z-50',
                      'background_tetriary xl:pr-[30px] w-full',
                      'xl:translate-y-[-6%] translate-y-[-4%]',
                      open && 'shadow-xl'
                    )}
                  >
                    <Listbox.Options
                      className={clsx(
                        'relative overflow-auto max-h-[250px] mb-7.5',
                        'xl:scrollbar xl:pr-[19px]'
                      )}
                    >
                      {isLoading ? <Spinner size="small" /> : renderOptions}
                    </Listbox.Options>
                  </div>
                </label>
              )}
            </Listbox>
            {!!error && (
              <div
                className={clsx(
                  'error_message',
                  'bg-red-tetriary border-l-3 pl-25px border-red-primary',
                  'md:pl-7.5 py-2.5 xl:pl-12.5 text-left'
                )}
              >
                {error.message}
              </div>
            )}
          </div>
        );
      }}
    />
  );
};

export default SelectInput;

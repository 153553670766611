import { useRef, useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import clsx from 'clsx';
import { Spinner } from '@/components/Spinner';

interface PdfDocumentViewerProps {
  filePath: string;
  modalMode?: boolean;
  printMode?: boolean;
}

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
};

const wjs = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
pdfjs.GlobalWorkerOptions.workerSrc = wjs;

const PdfDocumentViewer = ({
  filePath,
  modalMode,
  printMode,
}: PdfDocumentViewerProps) => {
  const [numPages, setNumPages] = useState<number>();
  const documentRef = useRef<HTMLDivElement | null>(null);

  return (
    <div
      className={clsx(
        'w-full h-full overflow-auto print:overflow-hidden',
        modalMode && 'flex justify-center'
      )}
      ref={documentRef}
    >
      <Document
        className={clsx(!modalMode && 'max-w-[500px]')}
        file={filePath}
        onLoadSuccess={info => setNumPages(info.numPages)}
        options={options}
        loading={<Spinner size="large" className="mt-[200px]" />}
      >
        {Array.from(new Array(numPages), (el, index) => (
          <Page
            width={documentRef.current?.clientWidth}
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            className={clsx(
              'w-full',
              !printMode && 'border-b-3 border-b-white-secondary last:border-none'
            )}
          />
        ))}
      </Document>
    </div>
  );
};

export default PdfDocumentViewer;

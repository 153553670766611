import { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import TagManager from 'react-gtm-module';

import AppRouter from './AppRouter';
import { ACCESS_TOKEN_KEY, REFRESH_TOKEN_KEY } from './constants/storageKeys';
import { setIsAuthenticated, setTokens } from './store/authSlice';
import { useAppDispatch } from '@/utils/hooks';

const App = (): JSX.Element => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const access = localStorage.getItem(ACCESS_TOKEN_KEY);
    const refresh = localStorage.getItem(REFRESH_TOKEN_KEY);

    if (access && refresh) {
      dispatch(setTokens({ access, refresh }));
    } else {
      dispatch(setIsAuthenticated({ isAuthenticated: false }));
    }
  }, []);

  useEffect(() => {
    if (import.meta.env.PROD) {
      TagManager.initialize({ gtmId: import.meta.env.VITE_GTM_ID });
    }
  }, []);

  return (
    <>
      <AppRouter />
      <Toaster position="bottom-right" />
    </>
  );
};

export default App;

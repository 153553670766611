import clsx from 'clsx';
import React from 'react';
import ArrowIcon from '@/assets/icons/back-black.svg';
import { Button } from '@/components/Button/index';

interface ButtonProps {
  title: string;
  onBack: () => void;
}

const BackButton = ({ title, onBack }: ButtonProps) => {
  return (
    <div className="flex items-center justify-start background_tetriary">
      <Button type="button" onClick={onBack} className="button_back !pl-0">
        <img src={ArrowIcon} className="w-[12px] h-[24px]" alt="" />
        <span className={clsx('text-16px ml-[30px]', 'max-sm:hidden ')}>{title}</span>
      </Button>
    </div>
  );
};

export default BackButton;

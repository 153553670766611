import { createApi } from '@reduxjs/toolkit/query/react';

import urlcat from 'urlcat';
import { ENDPOINTS } from '@/constants/endpoints';
import {
  setTokens,
  clearTokens,
  setInvitationAccepted,
  setSetup2fa,
  setLoginRequest2fa,
  setLoginRotatePassword,
} from '../store/authSlice';
import { noop } from '@/utils/functions';
import { customBaseQuery } from '@/api/baseQuery';
import { resetProfile } from '@/store/userSlice';
import {
  AcceptInvitationArgs,
  AcceptInvitationResponse,
  GetInvitationArgs,
  GetInvitationResponse,
  Login2faArgs,
  LoginResponse,
  LogoutArgs,
  Request2faAction,
  RequestResetPasswordArgs,
  ResetPasswordArgs,
  ResetUserPasswordResponse,
  RotatePasswordAction,
  RotateUserPasswordArgs,
  RotateUserPasswordResponse,
  Setup2faAction,
  Setup2faArgs,
  TokenAction,
  TokenArgs,
  TokenResponse,
} from '@/types/auth';

export const authApi = createApi({
  reducerPath: 'authApi',
  tagTypes: ['Auth'],
  baseQuery: customBaseQuery,
  endpoints: builder => ({
    logIn: builder.mutation<LoginResponse, TokenArgs>({
      query: body => ({
        method: 'POST',
        body,
        url: ENDPOINTS.LOGIN,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (data) {
            if (data.action === TokenAction) {
              dispatch(setTokens(data));
            }
            if (data.action === Request2faAction) {
              dispatch(setLoginRequest2fa(data));
            }
            if (data.action === Setup2faAction) {
              dispatch(setSetup2fa(data));
            }
            if (data.action === RotatePasswordAction) {
              dispatch(setLoginRotatePassword(data));
            }
          }
        } catch (e) {
          noop();
        }
      },
    }),
    logOut: builder.mutation<void, LogoutArgs>({
      query: body => ({
        method: 'POST',
        body,
        url: ENDPOINTS.LOGOUT,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        await queryFulfilled;
        dispatch(clearTokens());
        dispatch(resetProfile());
      },
    }),
    getInvitation: builder.query<GetInvitationResponse, GetInvitationArgs>({
      query: data => ({
        url: urlcat(ENDPOINTS.GET_INVITATION, {
          token: data.token,
        }),
      }),
    }),
    acceptInvitation: builder.mutation<AcceptInvitationResponse, AcceptInvitationArgs>({
      query: body => ({
        method: 'POST',
        body,
        url: ENDPOINTS.ACCEPT_INVITATION,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (data) {
            dispatch(setInvitationAccepted(data));
          }
        } catch (e) {
          noop();
        }
      },
    }),
    requestResetPassword: builder.mutation<void, RequestResetPasswordArgs>({
      query: body => ({
        method: 'POST',
        body,
        url: ENDPOINTS.USER_PASSWORD_RESET_REQUEST,
      }),
    }),
    resetPassword: builder.mutation<ResetUserPasswordResponse, ResetPasswordArgs>({
      query: body => ({
        method: 'POST',
        body,
        url: ENDPOINTS.USER_PASSWORD_RESET,
      }),
    }),
    rotatePassword: builder.mutation<RotateUserPasswordResponse, RotateUserPasswordArgs>({
      query: body => ({
        method: 'POST',
        body,
        url: ENDPOINTS.USER_PASSWORD_ROTATION,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (data) {
            if (data.action === TokenAction) {
              dispatch(setTokens(data));
            }
            if (data.action === Setup2faAction) {
              dispatch(setSetup2fa(data));
            }
          }
        } catch (e) {
          noop();
        }
      },
    }),
    setup2FA: builder.mutation<TokenResponse, Setup2faArgs>({
      query: body => ({
        method: 'POST',
        url: ENDPOINTS.SETUP_2FA,
        body,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          dispatch(setTokens(data));
        } catch (e) {
          noop();
        }
      },
    }),
    login2Fa: builder.mutation<TokenResponse, Login2faArgs>({
      query: body => ({
        method: 'POST',
        url: ENDPOINTS.LOGIN_2FA,
        body,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setTokens(data));
          }
        } catch {
          noop();
        }
      },
    }),
  }),
});

export const {
  useLogInMutation,
  useLogOutMutation,
  useGetInvitationQuery,
  useAcceptInvitationMutation,
  useRequestResetPasswordMutation,
  useResetPasswordMutation,
  useRotatePasswordMutation,
  useSetup2FAMutation,
  useLogin2FaMutation,
} = authApi;

export const fileTypes = {
  document: {
    accept: '.bmp,.jpeg,.jpg,.png,.docx,.pdf,.txt,.xls,.xlsx',
    extensions: ['bmp', 'jpeg', 'jpg', 'png', 'docx', 'pdf', 'txt', 'xls', 'xlsx'],
  },
  party: {
    accept: '.bmp,.jpeg,.jpg,.png',
    extensions: ['bmp', 'jpeg', 'jpg', 'png'],
  },
};
export const fileSizeLimit = {
  document: 50 * 1024 * 1024, // 50 MB
  party: 5 * 1024 * 1024, // 5 MB
};

const pdftronFileTypes = ['xls', 'xlsx', 'csv', 'docx'];
const imageFileTypes = ['bmp', 'jpeg', 'jpg', 'png'];

export const isPDFTronFile = (fileType: string) => pdftronFileTypes.includes(fileType);
export const isImageFile = (fileType: string) => imageFileTypes.includes(fileType);

import clsx from 'clsx';
import { Listbox } from '@headlessui/react';

import React from 'react';
import { ReactComponent as ChevronDown } from '@/assets/icons/chevron-down.svg';
import { ReactComponent as SortingAsc } from '@/assets/icons/sorting-asc.svg';
import { ReactComponent as SortingDesc } from '@/assets/icons/sorting-desc.svg';

export type SortOrders = 'asc' | 'desc';

export type Option = {
  label: string;
  value: string;
};

interface SortInputProps {
  name: string;
  label: string;
  options: Option[];
  sortBy: string;
  setSortBy: (value: string) => void;
  sortOrder: string;
  setSortOrder: (value: SortOrders) => void;
  isOneLine?: boolean;
}

const SortInput = ({
  name,
  label,
  options,
  sortBy,
  setSortBy,
  sortOrder,
  setSortOrder,
  isOneLine,
}: SortInputProps) => {
  const changeSort = (event: React.MouseEvent<SVGElement>, direction: SortOrders) => {
    event.preventDefault();
    setSortOrder(direction);
  };

  return (
    <Listbox value={sortBy} onChange={setSortBy}>
      {({ open }) => {
        const sortValue =
          options.find(option => option.value === sortBy)?.label ?? sortBy;
        return (
          <label
            htmlFor={name}
            className={clsx(
              'input_label',
              'relative py-3 px-25px md:px-7.5 text-left block',
              'xl:px-10 xl:py-4 border-white-primary',
              open && 'background_tetriary shadow-xl',
              !open && 'bg-white-primary',
              isOneLine && '!py-0 !xl:py-0'
            )}
          >
            {!isOneLine ? label : ''}
            <Listbox.Button
              className={clsx(
                'listbox_button',
                'w-full h-8 mt-[8px] xl:mt-[1px] outline-none',
                'bg-transparent flex items-center justify-between',
                'relative xl:h-12 border-b-[1px]',
                {
                  'border-b-white-primary': !open,
                  'border-b-gray-tetriary z-[99]': open,
                }
              )}
            >
              <span className={clsx('capitalize tracking-normal')}>
                {isOneLine ? `${label}:  ${sortValue}` : sortValue}
              </span>
              <span className="flex items-center justify-between">
                <ChevronDown className={clsx(open && 'rotate-180', 'mr-5')} />
                {sortOrder === 'asc' && (
                  <SortingAsc onClick={event => changeSort(event, 'desc')} />
                )}
                {sortOrder === 'desc' && (
                  <SortingDesc onClick={event => changeSort(event, 'asc')} />
                )}
              </span>
            </Listbox.Button>
            <div
              className={clsx(
                'absolute top-[100%] pt-0 px-25px md:px-7.5 xl:px-10 left-0 z-50',
                'background_tetriary xl:pr-[30px] w-full',
                'xl:translate-y-[-6%] translate-y-[-4%]',
                open && 'shadow-xl'
              )}
            >
              <Listbox.Options
                className={clsx(
                  'relative overflow-auto max-h-[250px] mb-7.5',
                  'xl:scrollbar xl:pr-[19px]'
                )}
              >
                {options?.map((item, id) => (
                  <Listbox.Option
                    key={id}
                    value={item.value}
                    className={clsx(
                      'listbox_option',
                      'cursor-pointer py-18px border-b-gray-tetriary border-b-[1px] z-50'
                    )}
                  >
                    {item.label}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </div>
          </label>
        );
      }}
    </Listbox>
  );
};

export default SortInput;

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from 'clsx';
import { ReactNode } from 'react';
import { useTable, Column, Cell, Row } from 'react-table';
import { useNavigate } from 'react-router-dom';
import { Title } from '../Typography/Title';
import { Spinner } from '../Spinner';
import { constructRoute } from '@/utils/routes';
import { ROUTES } from '@/constants/routes';
import { DocumentListItem } from '@/types/document';

interface RecentActionsLayoutProps {
  title?: string;
  totalLabel: string;
  totalNumber?: number;
  totalIcon?: string;
  columns: Column<DocumentListItem>[];
  data: DocumentListItem[];
  children: ReactNode;
  isLoadingData?: boolean;
}

export type RecentActionsCellProps = {
  value: string;
};

const RecentActionsLayout = ({
  title,
  totalLabel,
  totalNumber,
  totalIcon,
  columns,
  data,
  children,
  isLoadingData,
}: RecentActionsLayoutProps) => {
  const { headerGroups, rows, prepareRow } = useTable<DocumentListItem>({
    columns,
    data,
  });

  const navigate = useNavigate();

  const redirectToDocumentDetails = (id: string) => {
    navigate(constructRoute(ROUTES.DOCUMENT_DETAILS, id));
  };

  const handleCellClick =
    (cell: Cell<DocumentListItem>, row: Row<DocumentListItem>) => () => {
      redirectToDocumentDetails(row.original.id);
    };

  return (
    <div>
      <div className="flex items-center justify-between content-layout">
        <Title level="h4">{title}</Title>
        <div className="flex items-center max-sm:hidden">
          <img src={totalIcon} alt="" />
          <span className="font_primary_base ml-[5px] flex">
            {totalLabel}:{' '}
            <span className="ml-[2px] w-[16px]">
              {isLoadingData ? (
                <Spinner size="super-small" />
              ) : (
                <span className="font-bold">{totalNumber}</span>
              )}
            </span>
          </span>
        </div>
      </div>
      {isLoadingData ? (
        <Spinner size="medium" />
      ) : (
        <div className="max-md:overflow-auto max-md:w-full">
          <table className="table-auto w-[100%] border-spacing-[6px]">
            <thead className="mb-[18px]">
              {headerGroups.map((headerGroup, idx) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={`hgroup${idx}`}>
                  {headerGroup.headers.map(column => (
                    <th
                      {...column.getHeaderProps()}
                      key={column.id}
                      className={clsx(
                        'first:text-left text-center section-subtitle py-[18px]',
                        'max-xl:px-[25px] max-md:text-11px 3xl:px-10',
                        'max-sm:px-[7px] first:md:pl-7.5 first:pl-[25px]',
                        'first:xl:pl-12.5 last:pr-[25px] last:md:pr-7.5',
                        'last:xl:pr-12.5 last:3xl:pl-0',
                        column.id === 'agreementDate' && 'max-sm:hidden'
                      )}
                    >
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="bg-white-primary">
              {rows.map(row => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    key={row.id}
                    className={clsx(
                      'border-b-3 border-b-gray-secondary relative last:border-b-0'
                    )}
                  >
                    {row.cells.map((cell, index) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          key={row.id + index}
                          className={clsx(
                            'table_cell',
                            'py-[39px] text-center first:text-left',
                            '3xl:px-10 xl:px-5 max-xl:px-[25px]',
                            'max-sm:py-[18px] first:md:pl-7.5 max-sm:px-[7px]',
                            'first:xl:pl-12.5 last:md:pr-7.5 first:pl-[25px]',
                            'last:xl:pr-12.5 last:3xl:pr-12.5 last:pr-[25px]',
                            'last:2xl:pl-0',
                            'overflow-hidden text-ellipsis max-w-[250px]',
                            'whitespace-nowrap',
                            cell.column.id === 'agreementDate' && 'max-sm:hidden',
                            cell.column.id !== 'title' && 'xl:pointer-events-none'
                          )}
                          onClick={handleCellClick(cell, row)}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      <div className="flex items-center justify-end mt-[3px] max-sm:flex-col">
        {children}
      </div>
    </div>
  );
};

export default RecentActionsLayout;

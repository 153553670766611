import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ImagePreviewIcon } from '@/assets/icons/image-preview.svg';
import {
  useGetDocumentSignatoryPhotoQuery,
  useGetSharedDocumentSignatoryPhotoQuery,
} from '@/api/documentsApi';
import { Spinner } from '../Spinner';
import { PhotoPreviewModal } from '../Modal/PhotoPreviewModal';
import { useModal } from '@/utils/hooks';

interface SinglePartyPhotoProps {
  data?: { photo?: string };
  isLoading: boolean;
  signatoryName: string;
}

export const SinglePartyPhoto = ({
  data,
  isLoading,
  signatoryName,
}: SinglePartyPhotoProps) => {
  const { t } = useTranslation();

  const [photo, setPhoto] = useState<string | undefined>(undefined);

  const { isModalOpen, openModal, closeModal } = useModal();

  useEffect(() => {
    setPhoto(data?.photo);
  }, [data]);

  const makeTitle = () => `${t('Signatories.imageLabel')} (${signatoryName})`;

  if (isLoading) {
    return (
      <div className={clsx('flex justify-center items-center w-[150px] h-[150px]')}>
        <Spinner size="small" />
      </div>
    );
  }

  if (!photo) {
    return <div />;
  }

  return (
    <>
      <img
        src={`data:image;base64, ${photo}`}
        className="w-full h-full object-cover"
        alt={t('Signatories.imageLabel')}
      />
      <ImagePreviewIcon
        className={clsx(
          'absolute bottom-0 right-0 w-[32px] h-[32px] cursor-pointer',
          'print-hide'
        )}
        onClick={openModal}
      />
      <PhotoPreviewModal
        isOpen={isModalOpen}
        title={makeTitle()}
        photo={photo}
        onClose={closeModal}
      />
    </>
  );
};

interface SharedDocumentSinglePartyPhotoProps {
  token: string;
  email: string;
  subDocumentId?: string;
  signatoryName: string;
}

export const SharedDocumentSinglePartyPhoto = ({
  token,
  email,
  subDocumentId,
  signatoryName,
}: SharedDocumentSinglePartyPhotoProps) => {
  const { data, isLoading } = useGetSharedDocumentSignatoryPhotoQuery({
    token,
    email,
    subDocumentId,
  });

  return (
    <SinglePartyPhoto data={data} isLoading={isLoading} signatoryName={signatoryName} />
  );
};

interface DocumentSinglePartyPhotoProps {
  documentId: string;
  email: string;
  signatoryName: string;
}

export const DocumentSinglePartyPhoto = ({
  documentId,
  email,
  signatoryName,
}: DocumentSinglePartyPhotoProps) => {
  const { data, isLoading } = useGetDocumentSignatoryPhotoQuery({
    documentId,
    email,
  });

  return (
    <SinglePartyPhoto data={data} isLoading={isLoading} signatoryName={signatoryName} />
  );
};

import { NavigationLinks } from '../NavigationLinks';

const TopNavigation = (): JSX.Element => {
  return (
    <nav>
      <ul className="navigation_top_links justify-between hidden xl:flex">
        <NavigationLinks />
      </ul>
    </nav>
  );
};

export default TopNavigation;

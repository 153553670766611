import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import clsx from 'clsx';
import { Modal } from './Modal';
import { Paragraph } from '../Typography/Paragraph';
import { TextInput } from '../TextInput';
import { TwoFactorSetupFormData } from '@/forms/twoFactorSetupForm';
import { Button } from '../Button';
import { FormData, tokenFormSchema } from '@/forms/tokenForm';

interface TwoFactorAuthModalProps {
  isOpen: boolean;
  onTokenSubmit: (params: { confirmationToken: string }) => void;
  onClose: () => void;
}

export const TwoFactorAuthModal = ({
  isOpen,
  onTokenSubmit,
  onClose,
}: TwoFactorAuthModalProps) => {
  const { t } = useTranslation();

  const { handleSubmit, control, reset } = useForm<FormData>({
    resolver: joiResolver(tokenFormSchema),
    defaultValues: {
      token: '',
    },
  });

  const onSubmit: SubmitHandler<TwoFactorSetupFormData> = formData => {
    onTokenSubmit({
      confirmationToken: formData.token,
    });
    reset();
  };

  const handleCloseModal = () => {
    onClose();
    reset();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleCloseModal}
      title={t('TwoFactorAuth.modalTitle')}
      size="super-small"
    >
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            className={clsx(
              'flex w-full justify-end flex-col-reverse',
              'xl:gap-3px xl:flex-row',
              'sm:pt-7'
            )}
          >
            <div className="flex flex-1 flex-col justify-between">
              <div>
                <Paragraph className="px-7.5 pb-9 md:pb-4 xl:pb-11 xl:px-10">
                  {t('ConfirmToken.instructions')}
                </Paragraph>
              </div>
              <TextInput
                label={t('ConfirmToken.otk')}
                name="token"
                isRequired
                control={control}
                focus
              />
              <Button isSubmit>{t('Common.submit')}</Button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  );
};

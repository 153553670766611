/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from 'clsx';
import React from 'react';
import { ReactComponent as ArrowDownIcon } from '@/assets/icons/arrow-down.svg';

interface HistoryTreeItemProps {
  title?: string;
  type?: string;
  itemClassName?: string;
  titleClassName?: string;
  typeClassName?: string;
  handleRedirect: () => void;
  isExpanded?: boolean;
  handleHideTree?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  isMainDoc?: boolean;
  buttonClassName?: string;
}

export const HistoryTreeItem = ({
  title,
  type,
  itemClassName,
  titleClassName,
  typeClassName,
  handleRedirect,
  isExpanded,
  handleHideTree,
  isMainDoc,
  buttonClassName,
}: HistoryTreeItemProps) => {
  return (
    <div
      onClick={handleRedirect}
      className={clsx(
        'relative flex items-start flex-col bg-white-primary mb-[20px] py-[22px]',
        'px-12.5 max-xl:px-7.5 max-md:px-[25px] duration-200 group cursor-pointer',
        itemClassName
      )}
    >
      <span
        className={clsx(
          'history_tree_item_title',
          'mb-[10px] duration-200',
          'break-all max-w-[300px]',
          titleClassName
        )}
      >
        {title}
      </span>
      {!!type && (
        <div
          className={clsx(
            'history_tree_item_type',
            'inline-flex items-center',
            'py-[2px] px-[4px] text-[12px] h-[22px] duration-200',
            '!h-auto max-sm:max-w-[80%]',
            typeClassName
          )}
        >
          {type}
        </div>
      )}
      {isMainDoc && (
        <button
          type="button"
          className={clsx(
            '2xl:hidden absolute top-[50%] translate-y-[-50%] right-[25px] p-4',
            buttonClassName
          )}
          onClick={handleHideTree}
          aria-label="Hide tree"
        >
          <ArrowDownIcon className={clsx(isExpanded && 'rotate-180')} />
        </button>
      )}
    </div>
  );
};

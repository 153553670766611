import React, { ChangeEvent, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

type DebouncedInputProps = {
  onChange: (value: string) => void;
  delay?: number;
  className?: string;
  id?: string;
  name?: string;
  placeholder?: string;
  initValue?: string;
};

const DebouncedInput: React.FC<DebouncedInputProps> = ({
  onChange,
  className,
  id,
  name,
  initValue,
  placeholder,
  delay = 1000,
}) => {
  const [value, setValue] = useState<string>(initValue || '');

  const debouncedOnChange = useDebouncedCallback((newValue: string) => {
    onChange(newValue);
  }, delay);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setValue(newValue);
    debouncedOnChange(newValue);
  };

  return (
    <input
      type="text"
      value={value}
      onChange={handleChange}
      className={className}
      id={id}
      name={name}
      placeholder={placeholder}
    />
  );
};

export default DebouncedInput;

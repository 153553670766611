import React, { useState, useMemo, useEffect } from 'react';
import clsx from 'clsx';
import { joiResolver } from '@hookform/resolvers/joi';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { Button } from '@/components/Button';

import DocumentsCount from '@/components/DocumentsCount/DocumentsCount';
import DocumentsTable from '@/components/Table/DocumentsTable/DocumentsTable';
import DynamicListbox from '@/components/DynamicListbox/DynamicListbox';
import { Spinner } from '@/components/Spinner';
import { Title } from '@/components/Typography/Title';
import { useTransferDocumentsMutation } from '@/api/documentsApi';
import { transferToUserFormSchema, TransferToUserData } from '@/forms/transferToUserForm';
import { useLazyGetUsersQuery } from '@/api/adminUserApi';
import { DEFAULT_USERS_SORT_FIELD, DEFAULT_USERS_SORT_ORDER } from '@/pages/admin/Users';
import TextInput from '@/components/TextInput/TextInput';
import { showErrorToast, showSuccessToast } from '@/utils/toast';
import ConfirmationModal from '@/components/Modal/ConfirmationModal';
import { TwoFactorAuthModal } from '@/components/Modal/TwoFactorAuthModal';
import BackButton from '@/components/Button/BackButton';
import { UserInfo, UserStatus } from '@/types/user';
import { DocumentListItem } from '@/types/document';
import { ADMIN_DOCUMENTS_COLUMNS } from '@/components/Table/DocumentsTable/columns/adminDocuments';

export const defaultPageSize = 10;
export const defaultPageNumber = 1;
export const transferringDocumentsLimit = 10;

export interface DocumentsTransferParams {
  documents: DocumentListItem[];
  onComplete: () => void;
  onClose: () => void;
  userDetails?: boolean;
  backButtonTitle?: string;
}

const DocumentsTransfer = ({
  documents,
  onClose,
  onComplete,
  userDetails,
  backButtonTitle,
}: DocumentsTransferParams) => {
  const { t } = useTranslation();

  const [pageNumber, setPageNumber] = useState(defaultPageNumber);
  const [transferAll] = useState<boolean>(false);
  const [userToTransfer, setUserToTransfer] = useState<string | undefined>(undefined);
  const [userFilter, setUserFilter] = useState<string | undefined>(undefined);
  const [userToken, setUserToken] = useState<string>('');
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);
  const [is2FaModalOpen, setIs2FaModalOpen] = useState<boolean>(false);
  const [users, setUsers] = useState<UserInfo[]>([]);

  const [getUsers, { isFetching: isFetchingUsers }] = useLazyGetUsersQuery();

  const [transferDocuments, { isLoading: isTransferring, isSuccess: isTransferSuccess }] =
    useTransferDocumentsMutation();

  const shownDocuments = useMemo(() => {
    const start = defaultPageSize * (pageNumber - 1);
    return documents.slice(start, start + defaultPageSize);
  }, [documents, pageNumber]);

  const { handleSubmit, control, resetField } = useForm({
    resolver: joiResolver(transferToUserFormSchema),
    defaultValues: {
      userId: '',
      userToken: '',
    },
  });

  useEffect(() => {
    if (isTransferSuccess) {
      showSuccessToast(t('DocumentTransfer.success'));
      onComplete();
    }
  }, [isTransferSuccess]);

  const open2FaModal = () => {
    setIs2FaModalOpen(true);
    window.history.pushState({ tfaModal: true }, '', window.location.href);
  };

  const close2FaModal = () => {
    setIs2FaModalOpen(false);
    window.history.back();
  };

  const onTransferSubmit: SubmitHandler<TransferToUserData> = data => {
    if (data.userId && data.userToken) {
      showErrorToast(t('DocumentTransfer.errors.onlyOneOfFields'));
      return;
    }

    if (!data.userId && !data.userToken) {
      showErrorToast(t('DocumentTransfer.errors.oneOfFields'));
      return;
    }

    if (documents.length === 0) {
      showErrorToast(t('DocumentTransfer.errors.documentsEmpty'));
      return;
    }

    if (documents.length >= transferringDocumentsLimit) {
      showErrorToast(
        t('DocumentTransfer.errors.documentsLimit', {
          count: transferringDocumentsLimit,
        })
      );
      return;
    }

    window.history.pushState({ modal: true }, '', window.location.href);
    setIsConfirmationModalOpen(true);
    setUserToTransfer(data.userId);
    setUserToken(data.userToken);
  };

  const onConfirmTransfer = () => {
    setIsConfirmationModalOpen(false);
    open2FaModal();
  };

  const onSubmitWith2fa = ({ confirmationToken }: { confirmationToken: string }) => {
    const selectedIDs = documents.map(row => row.id);
    transferDocuments({
      documents: selectedIDs,
      newOwnerId: userToTransfer,
      newOwnerToken: userToken,
      confirmationToken,
    });
    setIs2FaModalOpen(false);
  };

  const onCancelTransfer = () => {
    setIsConfirmationModalOpen(false);
    window.history.back();
  };

  const onBack = () => {
    onClose();
  };

  window.onpopstate = () => {
    if (isConfirmationModalOpen) {
      setIsConfirmationModalOpen(false);
    } else if (is2FaModalOpen) {
      setIs2FaModalOpen(false);
    } else {
      onBack();
    }
  };

  useEffect(() => {
    if (userFilter === undefined) return;
    getUsers({
      filters: {
        offset: 0,
        limit: 10,
        sortBy: DEFAULT_USERS_SORT_FIELD,
        sortOrder: DEFAULT_USERS_SORT_ORDER,
        status: UserStatus.Active,
        name: userFilter || undefined,
      },
    }).then(result => {
      setUsers(result?.data?.users || []);
    });
  }, [userFilter]);

  const usersFilterOptions = useMemo(
    () =>
      users.map(user => ({
        value: user.id,
        label: `${user.name} ${user.surname}`,
      })),
    [users]
  );

  const handleChangeUser = (str: string) => {
    setUserFilter(str);
    const selected = users.find(user => `${user.name} ${user.surname}` === str);
    return !!selected;
  };

  return (
    <>
      <div className="flex flex-1 flex-row justify-between items-center">
        <Title level="h2" className="px-25px py-7.5 md:px-7.5 xl:py-[42px] xl:px-10">
          {t('DocumentTransfer.title')}
        </Title>
        <DocumentsCount
          label={t('DocumentTransfer.selected')}
          isLoading={false}
          total={documents.length}
        />
      </div>
      {isTransferring && <Spinner fullScreen size="huge" />}
      <DocumentsTable
        columns={ADMIN_DOCUMENTS_COLUMNS.slice(1)}
        documents={shownDocuments}
        isLoadingData={false}
        setPageNumber={setPageNumber}
        allDocumentsNumber={documents.length}
        currentPageNumber={pageNumber}
        pageSize={defaultPageSize}
        allRowsSelected={transferAll}
      />
      <form onSubmit={handleSubmit(onTransferSubmit)}>
        <Title level="h2" className="px-25px py-7.5 md:px-7.5 xl:py-[42px] xl:px-10">
          {t('DocumentTransfer.toUser')}
        </Title>
        <div
          className={clsx(
            'grid-cols-1 grid grid-rows-auto w-full xl:grid-cols-2 gap-3px'
          )}
        >
          <DynamicListbox
            label={t('DocumentTransfer.labels.designatedUser')}
            onChangeHandler={handleChangeUser}
            placeholder={t('DocumentTransfer.labels.designatedUserPlaceholder')}
            options={usersFilterOptions}
            name="userId"
            control={control}
            isLoading={isFetchingUsers}
            debounced
            resetField={() => resetField('userId')}
          />
          <Button isSubmit>{t('DocumentTransfer.transfer')}</Button>
        </div>
        <Title level="h2" className="px-25px py-7.5 md:px-7.5 xl:py-[42px] xl:px-10">
          {t('DocumentTransfer.toOrganization')}
        </Title>
        <div
          className={clsx(
            'grid-cols-1 grid grid-rows-auto w-full xl:grid-cols-2 gap-3px'
          )}
        >
          <TextInput
            control={control}
            name="userToken"
            label={t('DocumentTransfer.labels.transferToken')}
            isRequired
          />
          <Button isSubmit>{t('DocumentTransfer.transfer')}</Button>
        </div>
      </form>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={onCancelTransfer}
        onConfirm={onConfirmTransfer}
        title={t('DocumentTransfer.title')}
        sentences={[
          t('DocumentTransfer.modal.confirmText1'),
          t('DocumentTransfer.modal.confirmText2'),
          t('DocumentTransfer.modal.confirmText3'),
        ]}
      />
      <TwoFactorAuthModal
        isOpen={is2FaModalOpen}
        onClose={close2FaModal}
        onTokenSubmit={onSubmitWith2fa}
      />
      {!userDetails && (
        <BackButton
          title={backButtonTitle ?? t('DocumentTransfer.back')}
          onBack={onBack}
        />
      )}
    </>
  );
};

export default DocumentsTransfer;

import { createApi } from '@reduxjs/toolkit/query/react';
import { customBaseQuery } from '@/api/baseQuery';
import { ENDPOINTS } from '@/constants/endpoints';
import { UserProfileData } from '@/forms/updateProfileDetailsForm';
import { noop } from '@/utils/functions';
import { setProfile } from '@/store/userSlice';
import { UserDataResponse } from '@/types/user';

export const userApi = createApi({
  reducerPath: 'userApi',
  tagTypes: ['User'],
  baseQuery: customBaseQuery,
  endpoints: builder => ({
    getUserData: builder.query<UserDataResponse, void>({
      query: () => ENDPOINTS.GET_PROFILE_DETAILS,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (data) {
            dispatch(setProfile(data));
          }
        } catch (e) {
          noop();
        }
      },
    }),
    updateUserData: builder.mutation<UserDataResponse, UserProfileData>({
      query: body => ({
        method: 'PATCH',
        body,
        url: ENDPOINTS.UPDATE_PROFILE_DETAILS,
      }),
      invalidatesTags: ['User'],
    }),
  }),
});

export const { useGetUserDataQuery, useUpdateUserDataMutation } = userApi;

export interface GetActivityArgs {
  filters: {
    action?: string;
    email?: string;
    on?: string;
    from?: string;
    to?: string;
    offset: number;
    limit: number;
  };
}

export interface Activity {
  createdAt: string;
  action: string;
  actor: {
    email: string;
    id?: string;
  };
  payload?: {
    on: string;
    id?: string;
  };
}

export interface GetActivityResponse {
  events: Activity[];
  total: number;
}

export enum ActivityAction {
  UserLoggedIn = 'UserLoggedIn',
  AdminInvitedUser = 'AdminInvitedUser',
  AdminResendUserInvitation = 'AdminResendUserInvitation',
  AdminDeletedUser = 'AdminDeletedUser',
  AdminResetUserCredentials = 'AdminResetUserCredentials',
  UserAcceptedInvitation = 'UserAcceptedInvitation',
  UserUpdatedProfile = 'UserUpdatedProfile',
  UserChangedPassword = 'UserChangedPassword',
  UserCreatedDocument = 'UserCreatedDocument',
  UserRequestedDocument = 'UserRequestedDocument',
  UserResendDocumentToParty = 'UserResendDocumentToParty',
  PartyRequestedDocument = 'PartyRequestedDocument',
  PartySignedDocument = 'PartySignedDocument',
  UserVerifierDocumentParty = 'UserVerifierDocumentParty',
  UserRejectedDocumentParty = 'UserRejectedDocumentParty',
  UserSharedDocument = 'UserSharedDocument',
  PartyRequestedSharedDocument = 'PartyRequestedSharedDocument',
  AdminTransferredDocument = 'AdminTransferredDocument',
}

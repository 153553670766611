import { useState } from 'react';
import { ControllerProps, FieldValues, Controller } from 'react-hook-form';
import clsx from 'clsx';
import EyeOpenIcon from '../../assets/icons/eye-open.svg';
import EyeClosedIcon from '../../assets/icons/eye-closed.svg';

interface TextInputProps<T extends FieldValues>
  extends Omit<ControllerProps<T>, 'render'> {
  label?: string;
  isPassword?: boolean;
  isRequired?: boolean;
  disabled?: boolean;
  className?: string;
  inputClassName?: string;
  focus?: boolean;
}

const TextInput = <T extends FieldValues>({
  label,
  name,
  isPassword,
  isRequired,
  control,
  disabled,
  className,
  inputClassName,
  focus,
}: TextInputProps<T>): JSX.Element => {
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

  const handleIsPasswordVisible = () => {
    setIsPasswordVisible(isVisible => !isVisible);
  };

  let inputType: string;

  if (isPassword && !isPasswordVisible) {
    inputType = 'password';
  } else {
    inputType = 'text';
  }

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <div className={clsx('mb-3px', className)}>
          <label
            htmlFor={name}
            className={clsx(
              'input_label',
              'bg-white-primary border-l-3 b',
              'py-3 px-25px md:px-7.5 xl:px-10 xl:py-4 text-left block',
              {
                'border-white-primary': !error,
                'bg-red-quaternary border-red-primary': !!error,
              },
              inputClassName
            )}
          >
            {label ?? ''}
            {isRequired && '*'}
            <div className="flex items-center justify-between">
              <input
                className={clsx(
                  'font_primary_base',
                  'w-full h-8 mt-1 outline-none bg-transparent',
                  'xl:h-12 pr-[5px]',
                  { 'cursor-not-allowed': disabled }
                )}
                {...field}
                name={name}
                id={name}
                type={inputType}
                disabled={disabled}
                /* eslint-disable-next-line jsx-a11y/no-autofocus */
                autoFocus={focus}
              />
              {isPassword && (
                <button
                  type="button"
                  className={clsx(
                    'inline-block pt-[5px]',
                    disabled && 'pointer-events-none'
                  )}
                  onClick={handleIsPasswordVisible}
                >
                  <img src={isPasswordVisible ? EyeOpenIcon : EyeClosedIcon} alt="eye" />
                </button>
              )}
            </div>
          </label>
          {!!error && (
            <div
              className={clsx(
                'error_message',
                'bg-red-tetriary border-l-3 pl-25px border-red-primary',
                'md:pl-7.5 py-2.5 xl:pl-12.5 text-left'
              )}
            >
              {error.message}
            </div>
          )}
        </div>
      )}
    />
  );
};

export default TextInput;

export enum UserStatus {
  Active = 'active',
  Invited = 'invited',
}

export interface GetUsersArgs {
  filters?: {
    email?: string;
    name?: string;
    status: UserStatus;
    createdFrom?: string;
    createdTo?: string;
    invitedFrom?: string;
    invitedTo?: string;
    offset: number;
    limit: number;
    sortBy?: string;
    sortOrder?: 'asc' | 'desc';
  };
}

export interface UserInfo {
  id: string;
  name: string;
  surname: string;
  email: string;
  areaOfPractice?: string;
  documentsCount?: number;
  documentsSize?: number;
  invitedAt?: string;
  transferToken?: string;
  status?: string;
}

export interface InviteUserResponse {}

export interface GetUserProfileArgs {
  id: string;
}

export interface ResendUserInviteArgs {
  id: string;
}

export interface DeleteUserArgs {
  id: string;
  confirmationToken: string;
}

export interface ResetUserCredentialsArgs {
  id: string;
  confirmationToken: string;
}

export enum UserRole {
  Admin = 'admin',
  User = 'user',
}

export interface UserDataResponse {
  name: string;
  surname: string;
  email: string;
  has2faActive?: boolean;
  createdAt?: string;
  role?: UserRole;
}

export interface UserProfile {
  email: string;
  name: string;
  surname: string;
  role?: UserRole;
}

export interface UserState {
  profile: UserProfile | null;
}

import { Column } from 'react-table';

import { RecentActionsCellProps } from '@/components/RecentActions/RecentActionsLayout';
import {
  CustomRowProps,
  TableCellDate,
  TableCellDescription,
  TableCellHistory,
  TableCellParties,
  TableCellType,
} from '@/components/TableCells/TableCells';
import i18n from '@/translations';
import { DocumentListItem } from '@/types/document';

export const USER_DOCUMENTS_COLUMNS = [
  {
    id: 'title',
    accessor: 'title',
    Header: i18n.t('UserDocuments.columns.description'),
    Cell: ({ value }: RecentActionsCellProps) => TableCellDescription(value),
  },
  {
    id: 'client',
    accessor: 'client',
    Header: i18n.t('UserDocuments.columns.client'),
  },
  {
    Header: i18n.t('UserDocuments.columns.date'),
    accessor: 'agreementDate',
    Cell: ({ value }: { value: string }) => TableCellDate(value),
  },
  {
    Header: i18n.t('UserDocuments.columns.type'),
    accessor: 'type',
    Cell: ({ value }: RecentActionsCellProps) => TableCellType(value),
  },
  {
    Header: i18n.t('UserDocuments.columns.parties'),
    accessor: 'signatories',
    Cell: ({ value }: { value: number }) => TableCellParties(value),
  },
  {
    id: 'expander',
    Header: i18n.t('UserDocuments.columns.history'),
    accessor: 'nextLinkedDocument',
    Cell: ({ row }: { row: CustomRowProps }) =>
      TableCellHistory({
        ...row,
        canExpand:
          row.original.linkedDocuments && row.original.linkedDocuments.length > 0,
      }),
  },
] as Column<DocumentListItem>[];

import clsx from 'clsx';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CardStats from '@/components/CardStats/CardStats';
import DateRange, { RangeValue } from '@/components/DateRange/DateRange';
import { Title } from '@/components/Typography/Title';
import AccountIcon from '@/assets/icons/account-gray.svg';
import { getRangeFilterValue } from '@/utils/dates';
import { useGetUsersStatisticQuery } from '@/api/statisticApi';

const UsersStatsBlock = (): JSX.Element => {
  const { t } = useTranslation();
  const [dateRange, setDateRange] = useState<RangeValue>([null, null]);

  const { data: usersStatistic, isFetching: isUsersStatisticFetching } =
    useGetUsersStatisticQuery({
      filters: {
        from: getRangeFilterValue(dateRange, 0),
        to: getRangeFilterValue(dateRange, 1),
        limit: 5,
      },
    });

  const usersLabel =
    usersStatistic?.users === 1
      ? t('AdminDashboard.cardStats.user')
      : t('AdminDashboard.cardStats.users');

  return (
    <div>
      <Title
        level="h2"
        className="px-25px py-7.5 md:px-7.5 xl:py-[42px] xl:px-10 print:py-[20px]"
      >
        {t('AdminDashboard.users')}
      </Title>
      <div
        className={clsx(
          'grid gap-x-[3px]',
          'grid-cols-[1fr,1fr] 3xl:grid-cols-[1fr,2fr]'
        )}
      >
        <div className="grid gap-y-[3px] auto-rows-min">
          <DateRange
            label="Date"
            value={dateRange}
            onChange={value => setDateRange(value)}
          />
        </div>
        <div>
          <CardStats
            icon={AccountIcon}
            value={usersStatistic?.users}
            isLoading={isUsersStatisticFetching}
            mainText={usersLabel}
          />
        </div>
      </div>
    </div>
  );
};

export default UsersStatsBlock;

import { useEffect, useState } from 'react';
import clsx from 'clsx';

interface IProps {
  file?: File;
  filePath?: string;
  printMode?: boolean;
}

const TextDocumentViewer = ({ file, filePath, printMode }: IProps): JSX.Element => {
  const [text, setText] = useState<string>();
  useEffect(() => {
    const loadText = async () => {
      if (filePath) {
        const txt = await fetch(filePath).then(response => response.text());
        setText(txt);
      }
    };
    const reader = new FileReader();
    reader.onload = async e => {
      const txt = e.target?.result?.toString();
      setText(txt);
    };
    if (file) {
      reader.readAsText(file);
    } else {
      loadText();
    }
  }, []);

  return printMode ? (
    <div className="w-auto whitespace-pre-wrap font_primary_base  min-w-[300px]">
      {text}
    </div>
  ) : (
    <div className="flex items-center justify-center h-full">
      <div className="max-sm:p-0 p-1 bg-white-primary w-auto max-w-[900px] h-full">
        <div
          className={clsx(
            'w-auto h-full whitespace-pre-wrap font_primary_base',
            'overflow-auto break-all min-w-[320px]'
          )}
        >
          {text}
        </div>
      </div>
    </div>
  );
};

export default TextDocumentViewer;

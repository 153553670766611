import { createApi, TagDescription } from '@reduxjs/toolkit/query/react';
import urlcat from 'urlcat';

import { ENDPOINTS } from '@/constants/endpoints';
import { customBaseQuery } from '@/api/baseQuery';
import {
  DocumentsStatistic,
  GetDocumentsStatisticArgs,
  GetStorageStatisticArgs,
  GetUsersStatisticArgs,
  StorageStatistic,
  TotalStatistic,
  UserStatistic,
} from '@/types/statistic';

export const statisticApi = createApi({
  reducerPath: 'statisticApi',
  tagTypes: ['Statistic'],
  baseQuery: customBaseQuery,
  endpoints: builder => ({
    getTotalStatistic: builder.query<TotalStatistic, void>({
      query: () => ({
        url: ENDPOINTS.GET_TOTAL_STATISTIC,
      }),
      keepUnusedDataFor: 0.0001,
      providesTags: ['TotalStatistic' as TagDescription<'Statistic'>],
    }),
    getUsersStatistic: builder.query<UserStatistic, GetUsersStatisticArgs | void>({
      query: arg => ({
        url:
          arg && arg.filters
            ? urlcat(ENDPOINTS.GET_USERS_STATISTIC, { ...arg.filters })
            : ENDPOINTS.GET_USERS_STATISTIC,
      }),
      keepUnusedDataFor: 0.0001,
      providesTags: ['UserStatistic' as TagDescription<'Statistic'>],
    }),
    getDocumentsStatistic: builder.query<
      DocumentsStatistic,
      GetDocumentsStatisticArgs | void
    >({
      query: arg => ({
        url:
          arg && arg.filters
            ? urlcat(ENDPOINTS.GET_DOCUMENTS_STATISTIC, { ...arg.filters })
            : ENDPOINTS.GET_DOCUMENTS_STATISTIC,
      }),
      keepUnusedDataFor: 0.0001,
      providesTags: ['UserStatistic' as TagDescription<'Statistic'>],
    }),
    getStorageStatistic: builder.query<StorageStatistic, GetStorageStatisticArgs | void>({
      query: arg => ({
        url:
          arg && arg.filters
            ? urlcat(ENDPOINTS.GET_STORAGE_STATISTIC, { ...arg.filters })
            : ENDPOINTS.GET_STORAGE_STATISTIC,
      }),
      keepUnusedDataFor: 0.0001,
      providesTags: ['StorageStatistic' as TagDescription<'Statistic'>],
    }),
  }),
});

export const {
  useGetTotalStatisticQuery,
  useGetUsersStatisticQuery,
  useGetDocumentsStatisticQuery,
  useGetStorageStatisticQuery,
} = statisticApi;

import Joi from 'joi';
import i18next from '@/translations';

export interface InvitedUser {
  email: string;
  name: string;
  surname: string;
  isAdmin?: boolean;
}

export interface InvitedUsersFormData {
  users: InvitedUser[];
}

export const invitedUsersFormSchema = Joi.object({
  users: Joi.array().items(
    Joi.object({
      email: Joi.string()
        .trim()
        .email({ tlds: { allow: false } })
        .max(255)
        .required()
        .messages({
          'string.empty': i18next.t('Common.errors.fieldRequired'),
          'string.email': i18next.t('Common.errors.emailInvalid'),
          'string.max': i18next.t('Common.errors.tooLong'),
        }),
      name: Joi.string()
        .trim()
        .max(255)
        .required()
        .messages({
          'string.empty': i18next.t('Common.errors.fieldRequired'),
          'string.max': i18next.t('Common.errors.tooLong'),
        }),
      surname: Joi.string()
        .trim()
        .max(255)
        .required()
        .messages({
          'string.empty': i18next.t('Common.errors.fieldRequired'),
          'string.max': i18next.t('Common.errors.tooLong'),
        }),
    })
  ),
}).required();

import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useTable, Column, usePagination, useGlobalFilter, Cell, Row } from 'react-table';
import ArrowIcon from '@/assets/icons/back.svg';
import { Button } from '../../Button';
import { ROUTES } from '@/constants/routes';
import { constructRoute } from '@/utils/routes';
import { Spinner } from '../../Spinner';
import { Activity } from '@/types/activity';

interface UserTableProps {
  columns: Column<Activity>[];
  data: Activity[];
  showPreviousPage: () => void;
  showNextPage: () => void;
  isLoadingData: boolean;
  currentPageNumber: number;
  total?: number;
  pageSize?: number;
}

interface TableStylesProps {
  email?: string;
  action?: string;
  createdAt?: string;
}

const cellStyles: TableStylesProps = {
  email:
    'cursor-pointer max-md:text-15px' +
    'min-w-[150px] max-sm:max-w-[150px] sm:max-w-[180px] md:max-w-[220px] xl:max-w-[300px] 2xl:max-w-[320px]',
  action:
    'max-md:text-15px max-sm:max-w-[150px] sm:max-w-[200px] md:max-w-[250px] xl:max-w-[300px] 2xl:max-w-[500px]',
  createdAt: 'max-md:text-15px w-[120px] text-center',
};

const headerStyles: TableStylesProps = {
  createdAt: 'text-center',
};

const ActivityTable = ({
  columns,
  data,
  showNextPage,
  showPreviousPage,
  isLoadingData,
  currentPageNumber,
  total = 0,
  pageSize = 10,
}: UserTableProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { headerGroups, prepareRow, page } = useTable(
    {
      columns,
      data,
      manualPagination: true,
    },
    useGlobalFilter,
    usePagination
  );

  const handleUserClick = (id: string) => {
    navigate(constructRoute(ROUTES.USER_ACCOUNT, id));
  };

  const handleCellClick = (cell: Cell<Activity>, row: Row<Activity>) => async () => {
    if (cell.column.id === 'email' && row.original.actor.id) {
      handleUserClick(row.original.actor.id);
    }
  };

  const allPagesNumber = Math.ceil(total / pageSize);

  const hasPreviousPage = currentPageNumber !== 1;
  const hasNextPage = currentPageNumber !== allPagesNumber && total > 0;

  return (
    <>
      {isLoadingData ? (
        <Spinner size="medium" />
      ) : (
        <div
          className={clsx(
            'overflow-hidden max-xl:w-full lg:pt-8 pt-0',
            'border-t-3 border-t-gray-secondary'
          )}
        >
          {isLoadingData && <Spinner fullScreen size="huge" />}
          <table className="table-auto w-full border-spacing-[6px]">
            <thead className="mb-[18px]">
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map(column => {
                    return (
                      <th
                        {...column.getHeaderProps()}
                        className={clsx(
                          'section-subtitle py-[18px] max-md:text-11px',
                          'first:max-xl:px-[25px] first:3xl:px-10 first:xl:pl-12.5',
                          'pr-[10px]',
                          '!font-normal text-left',
                          headerStyles[column.id as keyof TableStylesProps]
                        )}
                        key={column.id}
                      >
                        {column.render('Header')}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody className="bg-white-primary">
              {page.map(row => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    className={clsx('border-b-3 border-b-gray-secondary relative')}
                    key={row.id}
                  >
                    {row.cells.map((cell, index) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={clsx(
                            'table_cell',
                            'py-[6px]',
                            'pr-[10px]',
                            'first:xl:pl-12.5 first:pl-25px',
                            'overflow-hidden text-ellipsis whitespace-nowrap',
                            cellStyles[cell.column.id as keyof TableStylesProps]
                          )}
                          onClick={handleCellClick(cell, row)}
                          key={row.id + index}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      <div
        className={clsx(
          'flex w-full background_primary text-white-primary items-center justify-between'
        )}
      >
        {(hasPreviousPage || hasNextPage) && (
          <Button
            type="button"
            onClick={showPreviousPage}
            disabled={!hasPreviousPage}
            className={clsx(
              'flex items-center section-subtitle justify-center w-[275px] h-[107px]',
              'max-sm:h-[77px] p-0 max-sm:w-[70px]'
            )}
          >
            <img src={ArrowIcon} className="w-[12px] h-[24px]" alt="" />
            <span className="text-white-primary ml-[40px] max-sm:hidden">
              {t('Pagination.previous')}
            </span>
          </Button>
        )}
        <div
          className={clsx(
            'h-full text-18px uppercase tracking-widest pagination_page_number w-full',
            'flex items-center justify-center py-[40px] max-sm:py-[25px]',
            hasPreviousPage && 'border-l-white-secondary border-l-3',
            hasNextPage && 'border-r-white-secondary border-r-3'
          )}
        >
          {t('Pagination.pageNumber', {
            currentPage: currentPageNumber,
            allPages: total === 0 ? 1 : allPagesNumber,
          })}
        </div>
        {(hasPreviousPage || hasNextPage) && (
          <Button
            type="button"
            onClick={showNextPage}
            disabled={!hasNextPage}
            className={clsx(
              'flex items-center section-subtitle justify-center w-[275px] h-[107px]',
              'max-sm:h-[77px] p-0 max-sm:w-[70px]'
            )}
          >
            <span className="text-white-primary mr-[40px] max-sm:hidden">
              {t('Pagination.next')}
            </span>
            <img src={ArrowIcon} className="-rotate-180 w-[12px] h-[24px]" alt="" />
          </Button>
        )}
      </div>
    </>
  );
};

export default ActivityTable;

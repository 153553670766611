import Joi from 'joi';

import { PASSWORD_REGEX } from '@/constants/regex';
import i18next from '@/translations';

export interface AcceptInvitationFormData {
  email: string;
  name: string;
  surname: string;
  password: string;
  confirmPassword: string;
}

export const acceptInvitationFormSchema = Joi.object({
  name: Joi.string()
    .trim()
    .min(1)
    .max(255)
    .required()
    .messages({
      'string.empty': i18next.t('Common.errors.fieldRequired'),
      'string.max': i18next.t('Common.errors.tooLong'),
    }),
  surname: Joi.string()
    .trim()
    .min(1)
    .max(255)
    .required()
    .messages({
      'string.empty': i18next.t('Common.errors.fieldRequired'),
      'string.max': i18next.t('Common.errors.tooLong'),
    }),
  email: Joi.string()
    .trim()
    .max(255)
    .email({ tlds: { allow: false } })
    .required()
    .messages({
      'string.empty': i18next.t('Common.errors.fieldRequired'),
      'string.email': i18next.t('Common.errors.emailInvalid'),
      'string.max': i18next.t('Common.errors.tooLong'),
    }),
  password: Joi.string()
    .trim()
    .required()
    .min(8)
    .max(255)
    .pattern(new RegExp(PASSWORD_REGEX))
    .messages({
      'string.min': i18next.t('Common.errors.passwordTooShort'),
      'string.max': i18next.t('Common.errors.tooLong'),
      'string.pattern.base': i18next.t('Common.errors.invalidPasswordFormat'),
      'string.empty': i18next.t('Common.errors.fieldRequired'),
    }),
  confirmPassword: Joi.any()
    .valid(Joi.ref('password'))
    .messages({
      'any.only': i18next.t('Common.errors.incorrectPasswordMatch'),
    }),
}).required();

import { useTranslation } from 'react-i18next';
import CardStats from '@/components/CardStats/CardStats';
import AllNFTsIcon from '@/assets/icons/allnft-gray.svg';
import AccountIcon from '@/assets/icons/account-gray.svg';
import StorageIcon from '@/assets/icons/storage-gray.svg';
import { Title } from '@/components/Typography/Title';
import { humanFileSize } from '@/utils/fileSize';
import { useGetTotalStatisticQuery } from '@/api/statisticApi';

const AdminCardStats = (): JSX.Element => {
  const { t } = useTranslation();

  const { data: statistic, isFetching: isFetchingStatistic } =
    useGetTotalStatisticQuery();

  const docsLabel =
    statistic?.documents === 1
      ? t('AdminDashboard.cardStats.document')
      : t('AdminDashboard.cardStats.documents');

  const usersLabel =
    statistic?.users === 1
      ? t('AdminDashboard.cardStats.user')
      : t('AdminDashboard.cardStats.users');

  const { size, unit } = humanFileSize(statistic?.storage);

  return (
    <>
      <Title level="h2" className="px-25px py-7.5 md:px-7.5 xl:py-[42px] xl:px-10">
        {t('AdminDashboard.overview')}
      </Title>
      <div className="grid grid-cols-3 gap-x-[3px] max-sm:grid-cols-1 grid-rows-auto">
        <CardStats
          icon={AllNFTsIcon}
          value={statistic?.documents}
          isLoading={isFetchingStatistic}
          mainText={docsLabel}
        />
        <CardStats
          icon={AccountIcon}
          value={statistic?.users}
          isLoading={isFetchingStatistic}
          mainText={usersLabel}
        />
        <CardStats
          icon={StorageIcon}
          isLoading={isFetchingStatistic}
          value={size}
          subText={unit}
          mainText={t('AdminDashboard.cardStats.storage')}
        />
      </div>
    </>
  );
};

export default AdminCardStats;

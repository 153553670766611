import clsx from 'clsx';

import { ReactComponent as Facebook } from '@/assets/icons/facebook.svg';
import { ReactComponent as Linkedin } from '@/assets/icons/linkedin.svg';

interface SocialIconsProps {
  className?: string;
}

const SocialIcons = ({ className }: SocialIconsProps) => {
  const socialMediaLinks = [
    {
      icon: <Facebook />,
      url: 'https://www.facebook.com/people/Justice-Technology/100090383890797/',
    },
    {
      icon: <Linkedin />,
      url: 'https://www.linkedin.com/company/justice-tech',
    },
  ];

  return (
    <div className={clsx('gap-7 text_primary items-center', className)}>
      {socialMediaLinks.map((link, index) => (
        <a
          key={index}
          href={link.url}
          className="fill-gray-primary hover:fill-red-primary duration-200"
          target="_blank"
          rel="noreferrer"
        >
          {link.icon}
        </a>
      ))}
    </div>
  );
};

export default SocialIcons;

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import AdminCardStats from '@/components/AdminCardStats/AdminCardStats';
import CardLink from '@/components/CardLink/CardLink';
import { ContentLayout } from '@/components/ContentLayout/ContentLayout';
import { cardlinks, ICard } from '@/constants/cardlinks';

const AdminDashboard = (): JSX.Element => {
  const { t } = useTranslation();

  const cards = cardlinks.map((card: ICard, index: number) => (
    <CardLink key={card.url + index} data={card} />
  ));

  return (
    <ContentLayout title={t('AdminDashboard.title')}>
      <AdminCardStats />
      <div
        className={clsx(
          'grid grid-cols-2 gap-x-[3px] 2xl:grid-cols-3 max-sm:grid-cols-1 grid-rows-auto'
        )}
      >
        {cards}
      </div>
    </ContentLayout>
  );
};

export default AdminDashboard;

import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const breakpoints = {
  xl: 1280,
  md: 960,
  sm: 560,
};
export const scrollToTop = (points?: { x?: number; y: number }) => {
  window.scrollTo(points?.x ?? 0, points?.y ?? 0);
};
export const scrollToDocumentTop = () => {
  const position = window.innerWidth > breakpoints.md ? 180 : 120;
  scrollToTop({ y: position });
};
export const scrollToSharedDocumentTop = () => {
  scrollToTop({ y: 160 });
};
export const scrollToTableTop = () => {
  const position =
    // eslint-disable-next-line no-nested-ternary
    window.innerWidth > breakpoints.xl
      ? 130
      : window.innerWidth > breakpoints.md
      ? 90
      : 80;
  scrollToTop({ y: position });
};
export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(scrollToTop, [pathname]);

  return null;
};

import clsx from 'clsx';

import { ReactComponent as SpinnerComponent } from '@/assets/images/spinner.svg';

interface SpinnerProps {
  size?: 'super-small' | 'small' | 'medium' | 'large' | 'huge';
  message?: string;
  fullScreen?: boolean;
  absolute?: boolean;
  className?: string;
}

const Spinner = ({
  size = 'medium',
  fullScreen = false,
  absolute,
  message,
  className,
}: SpinnerProps): JSX.Element => (
  <div
    className={clsx(
      'flex justify-center items-center flex-col',
      fullScreen && 'fixed left-0 top-0 z-50 w-full h-full background_spinner',
      absolute && 'absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2',
      className
    )}
  >
    <div>
      <SpinnerComponent
        className={clsx({
          'w-6 h-6': size === 'super-small',
          'w-10 h-10': size === 'small',
          'w-15 h-15': size === 'medium',
          'w-25 h-25': size === 'large',
          'w-[245px] h-[245px]': size === 'huge',
        })}
      />
      {!!message && (
        <span
          className={clsx('font_secondary_base', {
            'text-8px': size === 'super-small',
            'text-13px': size === 'small',
            'text-16px': size === 'medium',
            'text-18px': size === 'large',
            'text-24px mt-6': size === 'huge',
            'text-white-primary': fullScreen,
          })}
        >
          {message}
        </span>
      )}
    </div>
  </div>
);

export default Spinner;

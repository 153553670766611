import clsx from 'clsx';
import React, { ForwardedRef, forwardRef } from 'react';
import { PageTitle } from '../Typography/Title/PageTitle';

interface ContentLayoutProps {
  title?: string;
  titleClassName?: string;
  className?: string;
  children: React.ReactNode;
}

export const ContentLayout = forwardRef(
  (
    { title, titleClassName, className, children }: ContentLayoutProps,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <div ref={ref}>
        <PageTitle className={titleClassName}>{title}</PageTitle>
        <div
          className={clsx(
            'background_page_content border-t-3 border-t-red-primary',
            className
          )}
        >
          {children}
        </div>
      </div>
    );
  }
);

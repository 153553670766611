import { useTranslation } from 'react-i18next';

interface IProps {
  filePath?: string;
}

const ImageDocumentViewer = ({ filePath }: IProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <div className="h-full overflow-auto">
      <img src={filePath} alt={t('DocumentPreview.preview')} />
    </div>
  );
};

export default ImageDocumentViewer;

/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import clsx from 'clsx';

interface HistoryTreeSubItemProps {
  title?: string;
  type?: string;
  itemClassName?: string;
  titleClassName?: string;
  typeClassName?: string;
  handleRedirect: () => void;
}

export const HistoryTreeSubItem = ({
  title,
  type,
  itemClassName,
  titleClassName,
  typeClassName,
  handleRedirect,
}: HistoryTreeSubItemProps) => {
  return (
    <li
      onClick={handleRedirect}
      className={clsx(
        'flex relative items-start ml-[40px] bg-white-primary mb-[20px]',
        'history-x pl-12.5 flex-col group cursor-pointer p-[22px] duration-200',
        'py-0 h-[100px] justify-center',
        itemClassName
      )}
    >
      <span
        className={clsx(
          'history_tree_item_title',
          'mb-[10px] duration-200',
          'm-0',
          titleClassName
        )}
      >
        {title}
      </span>
      <div
        className={clsx(
          'history_tree_item_type',
          'hidden items-center',
          'py-[2px] px-[4px] h-[22px] duration-200',
          typeClassName
        )}
      >
        {type}
      </div>
    </li>
  );
};

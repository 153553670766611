import { ROUTES } from '@/constants/routes';

export interface ICard {
  title: string;
  header: string;
  text: string;
  caption: string;
  url: string;
}

export const cardlinks: ICard[] = [
  {
    title: 'DOCUMENTS',
    header: 'DOCUMENT MANAGEMENT',
    text:
      'Here you can see the list of all documents in the platform, ' +
      'create lists of documents meeting certain criteria, ' +
      'and transfer documents between users. You can also ' +
      'see the contents of documents and edit their metadata.',
    caption: 'VIEW ALL DOCUMENTS',
    url: ROUTES.DOCUMENTS,
  },
  {
    title: 'USERS',
    header: 'USER MANAGEMENT',
    text:
      'Here you can see the list of all users in the platform, ' +
      'create lists of users meeting certain criteria, ' +
      'and invite new users. You can see and edit the details ' +
      'of users and remove users from the platform.',
    caption: 'VIEW ALL USERS',
    url: ROUTES.USERS,
  },
  {
    title: 'INVITATIONS',
    header: 'NEW USER MANAGEMENT',
    text:
      'Here you can see the list of all pending users in the platform and ' +
      'invite new users.',
    caption: 'VIEW INVITED USERS',
    url: ROUTES.INVITATIONS,
  },
  {
    title: 'STATISTICS',
    header: '',
    text: 'Here you can find information about the usage of the platform.',
    caption: ' VIEW STATISTICS',
    url: ROUTES.STATISTICS,
  },
  {
    title: 'ADMINISTRATOR',
    header: '',
    text: 'Here you can find and edit your information, including your password.',
    caption: 'VIEW PROFILE',
    url: ROUTES.ACCOUNT,
  },
  {
    title: 'ACTIVITY LOG',
    header: '',
    text: 'Here you can find the log of activities performed on the platform by all users..',
    caption: 'VIEW ACTIVITY LOG',
    url: ROUTES.ACTIVITY,
  },
];

import { createApi, TagDescription } from '@reduxjs/toolkit/query/react';
import urlcat from 'urlcat';
import { customBaseQuery } from '@/api/baseQuery';
import { ENDPOINTS } from '@/constants/endpoints';
import { GetActivityArgs, GetActivityResponse } from '@/types/activity';

export const systemApi = createApi({
  reducerPath: 'systemApi',
  tagTypes: ['System'],
  baseQuery: customBaseQuery,
  endpoints: builder => ({
    sendMessage: builder.mutation<void, { subject: string; message: string }>({
      query: body => ({
        method: 'POST',
        body,
        url: ENDPOINTS.CONTACT_US,
      }),
    }),
    getActivity: builder.query<GetActivityResponse, GetActivityArgs | void>({
      query: arg => ({
        url:
          arg && arg.filters
            ? urlcat(ENDPOINTS.ACTIVITY, { ...arg.filters })
            : ENDPOINTS.ACTIVITY,
      }),
      keepUnusedDataFor: 0.0001,
      providesTags: ['Activity' as TagDescription<'System'>],
    }),
  }),
});

export const { useSendMessageMutation, useGetActivityQuery } = systemApi;

export const ENDPOINTS = {
  BASE: `${import.meta.env.VITE_APP_API_URL}/api/v1/`,

  // auth
  LOGIN: 'auth/login',
  LOGOUT: 'auth/logout',
  LOGIN_2FA: 'auth/login/2fa',
  SETUP_2FA: 'auth/login/setup2fa',
  REFRESH_TOKEN: 'auth/token/refresh/',
  USER_PASSWORD_RESET_REQUEST: 'auth/user/password/reset-request',
  USER_PASSWORD_RESET: 'auth/user/password/reset',
  USER_PASSWORD_ROTATION: 'auth/user/password/rotation',

  // user
  GET_INVITATION: `users/invitation/`,
  ACCEPT_INVITATION: 'users/invitation/accept/',
  GET_PROFILE_DETAILS: 'users/profile-details/',
  UPDATE_PROFILE_DETAILS: 'users/profile-details/',

  // admin users
  GET_USERS: 'admin/users/',
  GET_USER_PROFILE: (id: string) => `admin/users/${id}/profile-details`,
  INVITE_USER: 'admin/users/invitation',
  RESEND_USER_INVITE: (id: string) => `admin/users/invitation/${id}/resend`,
  DELETE_USER: (id: string) => `admin/users/${id}`,
  RESET_USER_CREDENTIALS: (id: string) => `admin/users/${id}/reset-credentials`,

  // admin statistic
  GET_TOTAL_STATISTIC: 'statistic/totals',
  GET_USERS_STATISTIC: 'statistic/users',
  GET_DOCUMENTS_STATISTIC: 'statistic/documents',
  GET_STORAGE_STATISTIC: 'statistic/storage',

  // documents
  GET_DOCUMENTS: 'documents/',
  CREATE_DOCUMENT: 'documents/',
  GET_DOCUMENT: (id: string) => `documents/${id}/`,
  DOWNLOAD_DOCUMENT: (id: string) => `documents/${id}/download/`,
  SHARE_DOCUMENT: (id: string) => `documents/${id}/share/`,
  VERIFY_SIGNATORY: (id: string) => `documents/${id}/signatory/verify`,
  RESEND_SIGNATORY_REQUEST: (id: string) => `documents/${id}/signatory/resend`,
  REJECT_SIGNATORY_REQUEST: (id: string) => `documents/${id}/signatory/reject`,
  SIGNATORY_APPROVE: (id: string) => `documents/${id}/signatory/approve`,
  SIGNATORY_VIEW: `documents/signatory-view/details`,
  SIGNATORY_DOWNLOAD: `documents/signatory-view/download`,
  SIGNATORY_PHOTO: (id: string) => `documents/${id}/signatory/photo`,
  SHARED_DOCUMENT_VIEW: `documents/shared/details`,
  SHARED_DOCUMENT_SIGNATORY_PHOTO_VIEW: `documents/shared/signatory/photo`,
  SHARE_DOWNLOAD: `documents/shared/download`,

  // admin documents
  GET_ALL_DOCUMENTS: 'admin/documents/',
  TRANSFER_DOCUMENTS: 'admin/documents/transfer',

  // system
  CONTACT_US: 'contact-us/',
  GET_DOCUMENT_TYPES: 'document-types/',
  VERSION: 'version/',
  ACTIVITY: 'activity/',
};

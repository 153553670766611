/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import {
  useTable,
  Column,
  useExpanded,
  usePagination,
  useGlobalFilter,
  Cell,
  Row,
  useRowSelect,
} from 'react-table';
import ArrowIcon from '@/assets/icons/back.svg';
import { Button } from '../../Button';
import { ROUTES } from '@/constants/routes';
import { constructRoute } from '@/utils/routes';
import { Spinner } from '../../Spinner';
import { scrollToTableTop } from '@/utils/scroll';
import { DocumentListItem } from '@/types/document';

interface TableProps {
  columns: Column<DocumentListItem>[];
  documents: DocumentListItem[];
  isLoadingData: boolean;
  allDocumentsNumber?: number;
  currentPageNumber: number;
  pageSize?: number;
  allRowsSelected?: boolean;
  needToToggleRecords?: boolean;
  setPageNumber: Dispatch<SetStateAction<number>>;
  onSelectedRowsChanged?: Dispatch<
    SetStateAction<Record<string, DocumentListItem | undefined>>
  >;
}

interface TableStylesProps {
  title?: string;
  user?: string;
  client: string;
  agreementDate: string;
  signatories: string;
  nftStatus: string;
}

const CLICKABLE_CELLS = ['expander', 'title', 'user', 'signatories', 'selection'];
const LEFT_ALIGNED = ['title', 'user', 'client'];

const cellStyles: TableStylesProps = {
  title: 'cursor-pointer max-md:text-15px font-bold',
  user: 'cursor-pointer max-md:text-15px',
  client: 'max-lg:hidden xl:hidden 2xl:table-cell',
  agreementDate: 'max-sm:hidden xl:hidden 2xl:table-cell',
  signatories: 'max-xl:hidden xl:hidden 2xl:table-cell',
  nftStatus: 'max-lg:hidden xl:hidden 2xl:table-cell',
};

const headerStyles: TableStylesProps = {
  client: 'max-lg:hidden xl:hidden 2xl:table-cell',
  agreementDate: 'max-sm:hidden xl:hidden 2xl:table-cell',
  signatories: 'max-xl:hidden xl:hidden 2xl:table-cell',
  nftStatus: 'max-lg:hidden xl:hidden 2xl:table-cell',
};

const DocumentsTable = ({
  columns,
  documents,
  isLoadingData,
  allDocumentsNumber = 0,
  currentPageNumber,
  pageSize = 10,
  setPageNumber,
  allRowsSelected,
  needToToggleRecords,
  onSelectedRowsChanged,
}: TableProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { headerGroups, prepareRow, page } = useTable(
    {
      columns,
      data: documents,
      manualPagination: true,
      autoResetSelectedRows: false,
      getSubRows: useCallback((row: DocumentListItem) => row.linkedDocuments || [], []),
      getRowId: row => row.id,
    },
    useGlobalFilter,
    useExpanded,
    usePagination,
    useRowSelect
  );

  // FIXME: Current implementation of row selection is BAD
  //  but provided by react-table does not work properly in our case

  useEffect(() => {
    if (needToToggleRecords) {
      // eslint-disable-next-line no-restricted-syntax
      for (const document of documents) {
        document.selection = allRowsSelected;
      }

      const newState = documents.reduce(
        (accumulator, document) => ({
          ...accumulator,
          [document.id]: allRowsSelected ? document : undefined,
        }),
        {}
      );

      if (onSelectedRowsChanged) {
        onSelectedRowsChanged(prev => ({
          ...prev,
          ...newState,
        }));
      }
    }
  }, [allRowsSelected]);

  const handleDocumentNameClick = (id: string) => {
    navigate(constructRoute(ROUTES.DOCUMENT_DETAILS, id));
  };

  const handleCellClick =
    (cell: Cell<DocumentListItem>, row: Row<DocumentListItem>) => async () => {
      if (cell.column.id === 'selection' && onSelectedRowsChanged) {
        const value = row.original.selection ? row.original : undefined;
        onSelectedRowsChanged(prev => ({
          ...prev,
          [row.id]: value,
        }));
        return;
      }

      if (cell.column.id === 'user' && row.original.user?.id) {
        navigate(constructRoute(ROUTES.USER_ACCOUNT, row.original.user?.id));
        return;
      }

      // all cells but history, nftStatus and signatories are clickable on mobile/tablet
      if (cell.column.id !== 'expander' && cell.column.id !== 'signatories') {
        handleDocumentNameClick(row.original.id);
      }
    };

  const allPagesNumber = Math.ceil(allDocumentsNumber / pageSize);

  const hasPreviousPage = currentPageNumber !== 1;
  const hasNextPage = currentPageNumber !== allPagesNumber && allDocumentsNumber > 0;

  const getNextPage = () => {
    setPageNumber(prev => prev + 1);
    scrollToTableTop();
  };

  const getPreviousPage = () => {
    if (currentPageNumber > 1) {
      setPageNumber(prev => prev - 1);
      scrollToTableTop();
    }
  };

  return (
    <>
      {isLoadingData ? (
        <Spinner size="medium" />
      ) : (
        <div
          className={clsx(
            'overflow-hidden max-xl:w-full lg:pt-8 pt-0',
            'border-t-3 border-t-gray-secondary'
          )}
        >
          <table className="table-auto w-full border-spacing-[6px]">
            <thead className="mb-[18px]">
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map(column => {
                    return (
                      <th
                        {...column.getHeaderProps()}
                        className={clsx(
                          'section-subtitle py-[18px]',
                          'max-xl:px-[25px] max-md:text-11px 3xl:px-10',
                          'max-sm:px-[7px] first:md:pl-7.5 first:pl-[25px]',
                          'first:xl:pl-12.5 last:pr-[25px] last:md:pr-7.5',
                          'last:xl:pr-12.5 last:3xl:pl-0 !font-normal',
                          LEFT_ALIGNED.includes(column.id)
                            ? 'text-left pl-[25px]'
                            : 'text-center',
                          headerStyles[column.id as keyof TableStylesProps]
                        )}
                        key={column.id}
                      >
                        {column.render('Header')}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>
            <tbody className="bg-white-primary">
              {page.map(row => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    className={clsx('border-b-3 border-b-gray-secondary relative', {
                      'px-[25px] border-b-gray-secondary': row.depth > 0,
                      'border-t-[25px] border-t-gray-secondary border-b-[25px]':
                        row.depth > 0,
                      'history-indicator': row.depth > 0,
                    })}
                    key={row.id}
                  >
                    {row.cells.map((cell, index) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={clsx(
                            'font_primary_base',
                            '3xl:px-10 xl:px-5 max-xl:px-[25px]',
                            'py-[39px] max-sm:py-[18px] first:md:pl-7.5 max-sm:px-[7px]',
                            'first:xl:pl-12.5 last:md:pr-7.5 first:pl-25px last:2xl:pl-0',
                            'last:xl:pr-12.5 last:3xl:pr-12.5 last:pr-25px',
                            'overflow-hidden text-ellipsis whitespace-nowrap',
                            'max-w-[160px] max-xl:max-w-[120px] max-md:max-w-[80px]',
                            cellStyles[cell.column.id as keyof TableStylesProps],
                            row.depth > 0 && '!pt-[24px] !pb-[24px]',
                            !CLICKABLE_CELLS.includes(cell.column.id) &&
                              'xl:pointer-events-none',
                            LEFT_ALIGNED.includes(cell.column.id)
                              ? 'text-left'
                              : 'text-center',
                            cell.column.id === 'selection' && '!pr-[0px]'
                          )}
                          onClick={handleCellClick(cell, row)}
                          key={row.id + index}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      <div
        className={clsx(
          'flex w-full background_primary text-white-primary items-center justify-between'
        )}
      >
        {(hasPreviousPage || hasNextPage) && (
          <Button
            disabled={!hasPreviousPage}
            type="button"
            onClick={getPreviousPage}
            className={clsx(
              'flex items-center section-subtitle justify-center w-[275px] h-[107px]',
              'max-sm:h-[77px] p-0 max-sm:w-[70px]'
            )}
          >
            <img src={ArrowIcon} className="w-[12px] h-[24px]" alt="" />
            <span className="text-white-primary ml-[40px] max-sm:hidden">
              {t('Pagination.previous')}
            </span>
          </Button>
        )}
        <div
          className={clsx(
            'h-full text-18px uppercase tracking-widest pagination_page_number w-full',
            'flex items-center justify-center py-[40px] max-sm:py-[25px]',
            hasPreviousPage && 'border-l-white-secondary border-l-3',
            hasNextPage && 'border-r-white-secondary border-r-3'
          )}
        >
          {t('Pagination.pageNumber', {
            currentPage: currentPageNumber,
            allPages: allDocumentsNumber === 0 ? 1 : allPagesNumber,
          })}
        </div>
        {(hasPreviousPage || hasNextPage) && (
          <Button
            disabled={!hasNextPage}
            type="button"
            onClick={getNextPage}
            className={clsx(
              'flex items-center section-subtitle justify-center w-[275px] h-[107px]',
              'max-sm:h-[77px] p-0 max-sm:w-[70px]'
            )}
          >
            <span className="text-white-primary mr-[40px] max-sm:hidden">
              {t('Pagination.next')}
            </span>
            <img src={ArrowIcon} className="-rotate-180 w-[12px] h-[24px]" alt="" />
          </Button>
        )}
      </div>
    </>
  );
};

export default DocumentsTable;

import clsx from 'clsx';

interface ParagraphProps {
  children?: React.ReactNode;
  className?: string;
}

const Paragraph = ({ children, className }: ParagraphProps): JSX.Element => {
  return (
    <p className={clsx('font_primary_base leading-6 xl:leading-7', className)}>
      {children}
    </p>
  );
};

export default Paragraph;

export interface ConfirmationToken {
  confirmationToken: string;
}

export interface AuthState {
  action: string | null;
  accessToken: string | null;
  refreshToken: string | null;
  isAuthenticated: boolean | null;
  registeredEmail?: string | null;
  loginSessionKey?: string | null;
  setup2FaCode?: string | null;
  email?: string | null;
}

export interface SetLoginSessionKey {
  sessionKey: string;
}

export const TokenAction = 'Token';

export interface TokenResponse {
  action: 'Token';
  access: string;
  refresh: string;
}

export const Request2faAction = 'Request2FA';

export interface Request2faResponse {
  action: 'Request2FA';
  sessionKey: string;
  expireIn: number;
}

export const Setup2faAction = 'Setup2FA';

export interface Setup2faResponse {
  action: 'Setup2FA';
  code: string;
  expireIn: number;
  email: string;
}

export const RotatePasswordAction = 'RotatePassword';

export interface RotatePasswordResponse {
  action: 'RotatePassword';
  sessionKey: string;
  expireIn: number;
}

export type LoginResponse =
  | TokenResponse
  | Request2faResponse
  | Setup2faResponse
  | RotatePasswordResponse;

export interface TokenArgs {
  email: string;
  password: string;
}

export interface LogoutArgs {
  refresh: string;
}

export interface RequestResetPasswordArgs {
  email: string;
}

export interface ResetPasswordArgs {
  password: string;
  token: string;
  confirmationToken?: string;
}

export type RotateUserPasswordResponse = TokenResponse | Setup2faResponse;

export interface RotateUserPasswordArgs {
  sessionKey: string;
  password: string;
  token: string;
}

export interface AcceptInvitationArgs {
  password: string;
  token: string;
}

export interface AcceptInvitationResponse {
  email: string;
}

export interface GetInvitationArgs {
  token: string;
}

export interface GetInvitationResponse {
  email: string;
  name: string;
  surname: string;
}

export interface Setup2faArgs {
  code: string;
  token: string;
}

export interface Login2faArgs {
  token: string;
  sessionKey: string;
}

export const ResetPasswordOkAction = 'Ok';

export interface ResetPasswordOk {
  action: 'Ok';
}

export const ResetPassword2faAction = 'ResetPassword2FA';

export interface ResetPassword2FA {
  action: 'ResetPassword2FA';
}

export type ResetUserPasswordResponse = ResetPasswordOk | ResetPassword2FA;

import { Column } from 'react-table';

import { RecentActionsCellProps } from '@/components/RecentActions/RecentActionsLayout';
import {
  TableCellDate,
  TableCellDescription,
  TableCellType,
} from '@/components/TableCells/TableCells';
import i18n from '@/translations';
import { DocumentListItem } from '@/types/document';

export const RECENT_DOCUMENTS_COLUMNS = [
  {
    Header: i18n.t('Dashboard.recentDocs.description'),
    accessor: 'title',
    Cell: ({ value }: RecentActionsCellProps) => TableCellDescription(value),
  },
  {
    Header: i18n.t('Dashboard.recentDocs.date'),
    accessor: 'agreementDate',
    Cell: ({ value }: RecentActionsCellProps) => TableCellDate(value),
  },
  {
    Header: i18n.t('Dashboard.recentDocs.type'),
    accessor: 'type',
    Cell: ({ value }: RecentActionsCellProps) => TableCellType(value),
  },
] as Column<DocumentListItem>[];

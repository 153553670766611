import clsx from 'clsx';
import { FieldValues, UseFieldArrayAppend } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '../Button';
import { TextInput } from '../TextInput';
import { Title } from '../Typography/Title';
import { ShareViewFormData } from '@/forms/shareViewForm';
import { CreateDocumentFormData } from '@/forms/createDocumentForm';
import { showErrorToast } from '@/utils/toast';
import { InvitedUsersFormData } from '@/forms/inviteUsersForm';
import DeleteIcon from '@/assets/icons/delete.svg';

type FieldsProps = {
  email: string;
  name: string;
  surname: string;
  id?: string;
};

interface AddPartyFormRowProps extends FieldValues {
  fields: FieldsProps[];
  arrayName: string;
  submitButtonIcon?: string;
  submitButtonText?: string;
  append:
    | UseFieldArrayAppend<CreateDocumentFormData, 'signatories'>
    | UseFieldArrayAppend<InvitedUsersFormData, 'users'>
    | UseFieldArrayAppend<ShareViewFormData, 'recipients'>;
  remove: (index: number) => void;
  title?: string;
  limit: number;
  addButtonTitle: string;
  limitError: string;
  minNumber?: number;
}

const AddPartyFormRow = ({
  fields,
  control,
  arrayName,
  limit,
  submitButtonIcon,
  submitButtonText,
  append,
  remove,
  title,
  addButtonTitle,
  limitError,
  minNumber,
}: AddPartyFormRowProps) => {
  const { t } = useTranslation();

  const addNewParty = async () => {
    if (fields.length >= limit) {
      showErrorToast(limitError);
      return;
    }

    append({ email: '', name: '', surname: '' });
  };

  const disableRemoveButton = minNumber === fields.length;

  const removeParty = async (index: number) => {
    remove(index);
  };

  return (
    <div className="background_secondary flex flex-col">
      {title && (
        <div className="flex items-center justify-between content-layout">
          <Title level="h4" className="!max-sm:leading-6">
            {title}
          </Title>
        </div>
      )}
      {fields.map((item, idx) => (
        <div key={`row${idx}`} className="flex flex-1 flex-row mb-[25px] mx-[25px]">
          <div
            className={clsx(
              'grid lg:grid-cols-3 gap-x-[3px] md:grid-cols-2',
              'max-sm:grid-cols-1 w-full'
            )}
            key={item.id}
          >
            <TextInput
              label={t('AddParties.labels.email')}
              name={`${arrayName}.${idx}.email`}
              control={control}
              isRequired
              className={clsx(
                'md:col-span-2 lg:col-span-1 whitespace-nowrap overflow-hidden',
                'text-ellipsis'
              )}
              focus
            />
            <TextInput
              label={t('AddParties.labels.name')}
              name={`${arrayName}.${idx}.name`}
              control={control}
              isRequired
            />
            <TextInput
              label={t('AddParties.labels.surname')}
              name={`${arrayName}.${idx}.surname`}
              control={control}
              isRequired
            />
          </div>
          <div className="flex mb-[3px] ">
            <Button
              className={clsx(
                'button_add_row',
                'max-sm:p-2 max-sm:min-w-[40px] min-w-[100px]',
                disableRemoveButton && '!bg-gray-secondary'
              )}
              onClick={() => removeParty(idx)}
              disabled={disableRemoveButton}
            >
              <img src={DeleteIcon} alt="" />
            </Button>
          </div>
        </div>
      ))}
      <div className="mx-[25px] mb-[35px]">
        <Button
          className={clsx('w-full button_add_row !justify-start')}
          onClick={addNewParty}
        >
          <span className="text_primary text-16px">+ {addButtonTitle}</span>
        </Button>
        {submitButtonText && (
          <Button type="submit" className="w-full mt-[25px]">
            {submitButtonIcon && (
              <img src={submitButtonIcon} alt="" className="w-[28px]" />
            )}
            <span className="ml-[40px]">{submitButtonText}</span>
          </Button>
        )}
      </div>
    </div>
  );
};

export default AddPartyFormRow;

import Joi from 'joi';
import i18next from '@/translations';

export interface UserProfileData {
  email: string;
  name: string;
  surname: string;
  password?: string;
  confirmPassword?: string;
  confirmationToken?: string;
  has2faActive?: boolean;
}

export const updateProfileDetailsFormSchema = Joi.object({
  name: Joi.string()
    .trim()
    .max(255)
    .messages({
      'string.empty': i18next.t('Common.errors.fieldRequired'),
      'string.max': i18next.t('Common.errors.tooLong'),
    }),
  surname: Joi.string()
    .trim()
    .max(255)
    .required()
    .messages({
      'string.empty': i18next.t('Common.errors.fieldRequired'),
      'string.max': i18next.t('Common.errors.tooLong'),
    }),
  email: Joi.string()
    .trim()
    .email({ tlds: { allow: false } })
    .max(255)
    .required()
    .messages({
      'string.empty': i18next.t('Common.errors.fieldRequired'),
      'string.email': i18next.t('Common.errors.emailInvalid'),
      'string.max': i18next.t('Common.errors.tooLong'),
    }),
  password: Joi.string()
    .trim()
    .min(8)
    .max(255)
    .allow('')
    .messages({
      'string.max': i18next.t('Common.errors.tooLong'),
    }),
  confirmPassword: Joi.any()
    .valid(Joi.ref('password'))
    .messages({
      'any.only': i18next.t('Common.errors.incorrectPasswordMatch'),
    }),
  has2faActive: Joi.boolean(),
})
  .required()
  .options({ stripUnknown: true });

import clsx from 'clsx';
import { Disclosure } from '@headlessui/react';
import { ReactComponent as ArrowIcon } from '@/assets/icons/arrow-white.svg';

interface AccordionProps {
  question: string;
  answer: string;
}

export const Accordion = ({ question, answer }: AccordionProps) => {
  return (
    <Disclosure as="div" className="mb-[3px] last:mb-0">
      {({ open }) => (
        <>
          <Disclosure.Button
            className={clsx(
              'flex items-center w-full justify-between bg-white-primary',
              'content-layout'
            )}
          >
            <p className="accordion_title">{question}</p>
            <div
              className={clsx(
                'button_main rounded-full duration-200 background_primary',
                'h-[34px] w-[34px] flex items-center justify-center shrink-0'
              )}
            >
              <ArrowIcon className={clsx('duration-200', open ? 'rotate-180' : '')} />
            </div>
          </Disclosure.Button>
          <Disclosure.Panel
            className={clsx('background_secondary content-layout font_primary_base')}
          >
            {answer}
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};

import { useTranslation } from 'react-i18next';
import { Button } from '../Button';
import { Modal } from './Modal';

interface PrintModalProps {
  isOpen: boolean;
  onClose: () => void;
  onPrintDocument: () => void;
  onPrintHistory: () => void;
}

export const PrintModal = ({
  isOpen,
  onClose,
  onPrintDocument,
  onPrintHistory,
}: PrintModalProps) => {
  const { t } = useTranslation();

  const handlePrintDocumentClick = () => {
    onPrintDocument();
    onClose();
  };

  const handlePrintHistoryClick = () => {
    onPrintHistory();
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      title={t('SingleDocumentDetails.printModal.title')}
      onClose={onClose}
      size="tiny"
    >
      <div className="flex max-xl:w-full p-10 flex-col gap-2">
        <Button onClick={handlePrintDocumentClick}>
          {t('SingleDocumentDetails.printModal.printDocument')}
        </Button>
        <Button onClick={handlePrintHistoryClick}>
          {t('SingleDocumentDetails.printModal.printSummary')}
        </Button>
      </div>
    </Modal>
  );
};

import { useTranslation } from 'react-i18next';
import { Accordion } from '@/components/Accordion/Accordion';
import { ContactUs } from '@/components/ContactUs/ContactUs';
import { ContentLayout } from '@/components/ContentLayout/ContentLayout';
import { Title } from '@/components/Typography/Title';

const Help = () => {
  const { t } = useTranslation();

  const questions = [
    {
      question: 'What is Justice Technology?',
      answer: `Justice Technology is a new platform that uses patented
        technology to allow users to save and protect valuable documents.`,
    },
    {
      question: 'What types of documents can be authenticated?',
      answer: `The platform works with Word, PDF or digital image formats.
      We will include other formats shortly.`,
    },
    {
      question: 'How does it work?',
      answer: `The documents are encrypted and uploaded in a private IPFS filesystem
      hosted by Justice Technology. The decentralized system authenticates the
      uploaded document making it unique.`,
    },
    {
      question: 'My documents are signed by more than one party. What happens?',
      answer: 'We provide a process that authenticates the signature of each party.',
    },
    {
      question: 'What if I have 3 related documents?',
      answer: `You can protect the three documents as a unit provided that
      they are uploaded in one of three formats: Word, PDF or Digital Image.
      In the alternative you can protect the three documents in three separate
      units. This is your choice. `,
    },
  ];

  return (
    <ContentLayout title={t('Help.title')}>
      <div className="border-b-3 border-b-gray-secondary">
        <div className="content-layout">
          <Title level="h4">{t('Help.faq')}</Title>
        </div>
        <div>
          {questions.map((item, idx) => (
            <Accordion key={idx} question={item.question} answer={item.answer} />
          ))}
        </div>
        <div className="content-layout text-center">
          <p className="font_primary_base">{t('Help.description')}</p>
        </div>
      </div>
      <ContactUs />
    </ContentLayout>
  );
};

export default Help;

import clsx from 'clsx';
import { ControllerProps, FieldValues, Controller } from 'react-hook-form';
import { Switch as SwitchComponent } from '@headlessui/react';

interface SwitchProps<T extends FieldValues> extends Omit<ControllerProps<T>, 'render'> {
  text?: string;
  className?: string;
  textClassname?: string;
  disabled?: boolean;
  customOnChanged?: (value: boolean) => void;
}

const Switch = <T extends FieldValues>({
  name,
  text,
  className,
  textClassname,
  control,
  disabled,
  customOnChanged,
}: SwitchProps<T>) => {
  return (
    <Controller
      name={name}
      control={control}
      render={({ fieldState: { error }, field: { onChange, value } }) => (
        <>
          <div
            className={clsx(
              'flex items-center',
              disabled && 'cursor-not-allowed opacity-50'
            )}
          >
            <SwitchComponent
              checked={value}
              onChange={(checked: boolean) => {
                onChange(checked);
                if (customOnChanged) customOnChanged(checked);
              }}
              name={name}
              className={clsx(
                'relative inline-flex h-[20px] w-12.5 pl-3px bg-white-primary ',
                'focus:outline-none focus-visible:ring-opacity-75',
                'cursor-pointer duration-200 ease-in-out flex-shrink-0',
                error && 'border-red-primary border-[3px] w-[48px] box-content',
                disabled && 'pointer-events-none',
                className
              )}
            >
              <span
                className={clsx(
                  value ? 'background_primary' : 'background_tetriary',
                  value && 'translate-x-22px',
                  'my-auto pointer-events-none inline-block h-[14px] w-[22px]',
                  'transform shadow-lg transition duration-200 ease-in-out'
                )}
              />
            </SwitchComponent>
            {text && (
              <p
                className={clsx(
                  'font_primary_base leading-6 xl:leading-7',
                  'md:pl-25px pl-18px',
                  textClassname
                )}
              >
                {text}
              </p>
            )}
          </div>
          {!!error && (
            <div
              className={clsx(
                'text-left bg-red-tetriary text-13px px-5',
                'py-2.5 text-red-secondary md:text-16px xl:text-18px mt-5'
              )}
            >
              {error.message}
            </div>
          )}
        </>
      )}
    />
  );
};

export default Switch;

import React, { useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import { HistoryTreeItem } from './HistoryTreeItem';
import { HistoryTreeSubItem } from './HistoryTreeSubItem';
import { Document, SharedDocument } from '@/types/document';

interface HistoryTreeProps {
  activeDocumentId?: string;
  isActiveDocumentSignatories?: boolean;
  history: Document[] | SharedDocument[];
  className?: string;
  redirectToDocument: (id: string) => void;
  redirectToDocumentSignatures?: (id: string) => void;
  printMode?: boolean;
}

const HistoryTree = ({
  activeDocumentId,
  isActiveDocumentSignatories,
  history,
  className,
  redirectToDocument,
  redirectToDocumentSignatures,
  printMode,
}: HistoryTreeProps) => {
  const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggleTreeSize = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsExpanded(prev => !prev);
  };

  return (
    <div
      className={clsx(
        'relative history-y z-10 after:z-[-1] history-y-short',
        isExpanded || printMode
          ? 'h-full'
          : 'max-2xl:h-[100px] max-2xl:overflow-hidden 2xl:h-full',
        className
      )}
    >
      <ul className="relative mb-[20px]">
        {history.map((item, index) => (
          <React.Fragment key={item.id}>
            <HistoryTreeItem
              title={item.title}
              type={item.type}
              isMainDoc={index === 0}
              isExpanded={index === 0 && isExpanded}
              handleHideTree={handleToggleTreeSize}
              titleClassName={clsx(
                activeDocumentId === item.id.toString() &&
                  !isActiveDocumentSignatories &&
                  'history_tree_item_title_active'
              )}
              typeClassName={clsx(
                activeDocumentId === item.id.toString() &&
                  !isActiveDocumentSignatories &&
                  'history_tree_item_type_active'
              )}
              handleRedirect={() => redirectToDocument(item.id)}
            />
            {item.signatories && item.signatories.length > 0 && (
              <HistoryTreeSubItem
                title={t('SingleDocumentDetails.signatures.title')}
                type={t('SingleDocumentDetails.signatures.type')}
                itemClassName={clsx(
                  'history-double-x',
                  !redirectToDocumentSignatures && 'pointer-events-none'
                )}
                titleClassName={clsx(
                  isActiveDocumentSignatories &&
                    activeDocumentId === item.id.toString() &&
                    'history_tree_item_title_active'
                )}
                handleRedirect={() => {
                  if (redirectToDocumentSignatures) {
                    redirectToDocumentSignatures(item.id);
                  }
                }}
              />
            )}
          </React.Fragment>
        ))}
      </ul>
    </div>
  );
};

export default HistoryTree;

import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { SubmitHandler, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';

import { Title } from '@/components/Typography/Title';
import { Paragraph } from '@/components/Typography/Paragraph';
import { Button } from '@/components/Button';
import { CircularTitle } from '@/components/CircularTitle';
import { AuthContent } from '@/components/AuthContent';
import { FormData, tokenFormSchema } from '@/forms/tokenForm';
import { TextInput } from '@/components/TextInput';
import { useAppSelector } from '@/utils/hooks';
import { useLogin2FaMutation } from '../../api/authApi';
import { ErrorMessage } from '@/components/ErrorMessage';

const ConfirmToken = (): JSX.Element => {
  const { t } = useTranslation();

  const loginSessionKey = useAppSelector(state => state.auth.loginSessionKey);

  const [login2fa, { error }] = useLogin2FaMutation();

  const { handleSubmit, control, watch } = useForm<FormData>({
    resolver: joiResolver(tokenFormSchema),
    defaultValues: {
      token: '',
    },
  });

  const token = watch('token');

  const onSubmit: SubmitHandler<FormData> = data => {
    if (!loginSessionKey) {
      return;
    }
    login2fa({
      token: data.token,
      sessionKey: loginSessionKey,
    });
  };

  return (
    <div className="flex justify-end relative">
      <CircularTitle>{t('Common.login')}</CircularTitle>
      <AuthContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            className={clsx(
              'flex w-full justify-end flex-col-reverse',
              'xl:gap-3px xl:flex-row',
              'sm:pt-7 xl:pt-12'
            )}
          >
            <div className="flex flex-1 flex-col justify-between">
              <div>
                <Title
                  level="h2"
                  className="mb-6 px-25px md:px-7.5 xl:mt-0 mt-7 xl:mb-10 xl:px-10"
                >
                  {t('ConfirmToken.enterKey')}
                </Title>
                <Paragraph className="px-25px pb-9 md:pb-4 xl:pb-11 xl:px-10">
                  {t('ConfirmToken.instructions')}
                </Paragraph>
              </div>
              <TextInput
                label={t('ConfirmToken.otk')}
                name="token"
                isRequired
                control={control}
                focus
              />
              <ErrorMessage error={error} resetOn={[token]} />
              <Button isSubmit>{t('Common.login')}</Button>
            </div>
          </div>
        </form>
      </AuthContent>
    </div>
  );
};

export default ConfirmToken;

import { useGetVersionQuery } from '@/api/versionApi';

const Dashboard = (): JSX.Element => {
  const { data: backendVersion } = useGetVersionQuery();

  return (
    <div className="text-center text_primary mt-20">
      {JSON.stringify({
        client: `${import.meta.env.VERSION ?? '0.0.15'}`,
        backend: backendVersion ?? '0.0.0',
      })}
    </div>
  );
};

export default Dashboard;

import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { ServerErrors } from '@/translations/en.json';

export const isFetchBaseQueryError = (error: unknown): error is FetchBaseQueryError => {
  return typeof error === 'object' && error != null && 'status' in error;
};

export const isErrorWithMessage = (
  error: unknown
): error is { data: { code: keyof typeof ServerErrors } } => {
  return (
    typeof error === 'object' &&
    error != null &&
    'data' in error &&
    Object.keys(ServerErrors).includes((error as any).data?.code)
  );
};

export const isServerError = (error: FetchBaseQueryError) => {
  return error.status && `${error.status}`.match(/500|400|404|403|413/);
};

import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import Logo from '@/assets/images/logo-footer.svg';

interface FooterProps {
  containerClassName?: string;
}

const Footer = ({ containerClassName }: FooterProps): JSX.Element => {
  const { t } = useTranslation();

  const year = new Date().getFullYear();

  return (
    <footer
      className={clsx(
        'flex justify-between items-center py-7.5 px-6',
        'max-sm:px-3 md:px-10 xl:px-15 xl:py-12.5',
        containerClassName
      )}
    >
      <span className="font_primary_base max-sm:min-w-[130px]">
        {t('Footer.copyright', { year })}
      </span>
      <img
        src={Logo}
        alt={t('Footer.justiceTechnology')}
        className="w-[123px] xl:w-[149px]"
      />
    </footer>
  );
};

export default Footer;

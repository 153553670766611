import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

const CircularRequired = () => {
  const { t } = useTranslation();

  return (
    <div
      className={clsx(
        'bg-primary-gradient xl:w-25 xl:h-25 rounded-full flex justify-center items-center',
        'text-white-primary flex-col w-[63px] h-[63px] absolute right-0 -top-[102px]',
        'md:top-[245px] md:left-[150px] xl:top-[410px] xl:left-[240px]'
      )}
    >
      <span className=" xl:text-[53px] text-[36px] leading-none">*</span>
      <span className="xl:-mt-[25px] text-10px xl:text-12px -mt-[15px]">
        {t('Common.required')}
      </span>
    </div>
  );
};

export default CircularRequired;

import Joi from 'joi';
import i18next from '@/translations';

export interface ShareViewFormData {
  visibleSections: {
    history: boolean;
    type: boolean;
    client: boolean;
    signatories: boolean;
    signatoryPhotos: boolean;
    date: boolean;
  };
  recipients: [
    {
      email: string;
      name: string;
      surname: string;
    }
  ];
}

const visibleSectionsSchema = Joi.object({
  history: Joi.boolean(),
  type: Joi.boolean(),
  client: Joi.boolean(),
  signatories: Joi.boolean(),
  signatoryPhotos: Joi.boolean(),
  date: Joi.boolean(),
});

export const shareViewFormSchema = Joi.object({
  visibleSections: visibleSectionsSchema,
  recipients: Joi.array().items(
    Joi.object({
      email: Joi.string()
        .trim()
        .email({ tlds: { allow: false } })
        .max(255)
        .required()
        .messages({
          'string.empty': i18next.t('Common.errors.fieldRequired'),
          'string.email': i18next.t('Common.errors.emailInvalid'),
          'string.max': i18next.t('Common.errors.tooLong'),
        }),
      name: Joi.string()
        .trim()
        .max(255)
        .required()
        .messages({
          'string.empty': i18next.t('Common.errors.fieldRequired'),
          'string.max': i18next.t('Common.errors.tooLong'),
        }),
      surname: Joi.string()
        .trim()
        .max(255)
        .required()
        .messages({
          'string.empty': i18next.t('Common.errors.fieldRequired'),
          'string.max': i18next.t('Common.errors.tooLong'),
        }),
    })
  ),
}).required();

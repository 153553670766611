import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import RecentActionsLayout from './RecentActionsLayout';
import { Button } from '../Button';
import AllNftsGrayIcon from '@/assets/icons/nfts-gray.svg';
import { ROUTES } from '@/constants/routes';
import { DocumentListItem } from '@/types/document';
import { RECENT_DOCUMENTS_COLUMNS } from '@/components/Table/DocumentsTable/columns/recentDocuments';

interface RecentDocumentsProps {
  documents?: DocumentListItem[];
  isLoadingData: boolean;
  totalDocumentsNumber?: number;
}

export const RecentDocuments = ({
  documents,
  isLoadingData,
  totalDocumentsNumber,
}: RecentDocumentsProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const redirectToCreateNft = () => navigate(ROUTES.CREATE_DOCUMENT);

  const redirectToDocuments = () => navigate(ROUTES.DOCUMENTS);

  return (
    <RecentActionsLayout
      title={t('Dashboard.recentDocs.title')}
      totalLabel={t('Dashboard.recentDocs.totals')}
      totalNumber={totalDocumentsNumber}
      totalIcon={AllNftsGrayIcon}
      columns={RECENT_DOCUMENTS_COLUMNS}
      data={documents || []}
      isLoadingData={isLoadingData}
    >
      <Button
        className="w-full sm:w-[50%] 2xl:w-[25%] sm:mr-[3px]"
        onClick={redirectToDocuments}
      >
        {t('Dashboard.recentDocs.buttons.docs')}
      </Button>
      <Button
        className="w-full sm:w-[50%] 2xl:w-[25%] max-sm:mt-[3px]"
        onClick={redirectToCreateNft}
      >
        {t('Dashboard.recentDocs.buttons.authenticate')}
      </Button>
    </RecentActionsLayout>
  );
};

import { Outlet } from 'react-router-dom';
import clsx from 'clsx';

import { TopBar } from '@/components/TopBar';
import { Footer } from '@/components/Footer';
import { FixedContainer } from '@/components/FixedContainer';

const AuthView = (): JSX.Element => {
  return (
    <main>
      <TopBar />
      <div
        className={clsx(
          'bg-auth-bg bg-cover bg-no-repeat xl:w-full',
          'xl:min-h-[695px] md:min-h-[500px]',
          'mt-[95px] md:mt-[145px] xl:mt-0'
        )}
      >
        <FixedContainer>
          <div
            className={clsx(
              'xl:pt-20 xl:pb-25 xl:px-15 px-25px pb-[70px] pt-[145px]',
              'md:pt-10 md:pb-15 md:px-10 overflow-hidden'
            )}
          >
            <Outlet />
          </div>
        </FixedContainer>
      </div>
      <FixedContainer>
        <Footer />
      </FixedContainer>
    </main>
  );
};

export default AuthView;

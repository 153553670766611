import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { ROUTES } from '@/constants/routes';

interface NavigationLinksProps {
  linkClassName?: string;
}

const NavigationLinks = ({ linkClassName }: NavigationLinksProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const redirectToPage = (route: string) => () => {
    navigate(route);
  };

  const links = [
    {
      label: t('TopNavigation.login'),
      route: ROUTES.HOME,
      pathName: ROUTES.HOME,
    },
  ];

  return (
    <>
      {links.map(({ label, route, pathName }, index) => (
        <li key={index} className="xl:mr-[70px] last:mr-0">
          <button
            type="button"
            onClick={redirectToPage(route)}
            className={clsx(
              'navigation_link',
              'duration-200 relative',
              'after:content-[""] after:h-[3px] after:absolute after:w-full',
              'after:left-0 after:bottom-[-50px]',
              'after:pointer-events-none after:duration-200 after:bg-trasparent',
              'max-xl:after:hidden',
              {
                'text-red-primary xl:after:bg-red-primary max-xl:border-r-[3px]':
                  pathname === pathName,
                'max-xl:border-r-red-primary': pathname === pathName,
              },
              linkClassName
            )}
          >
            {label}
          </button>
        </li>
      ))}
    </>
  );
};

export default NavigationLinks;

import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { Spinner } from '../Spinner';
import AllNFTsIcon from '@/assets/icons/nfts-gray.svg';

interface DocumentsCountProps {
  label?: string;
  isLoading: boolean | undefined;
  total: number | undefined;
}

export const DocumentsCount = ({ label, isLoading, total }: DocumentsCountProps) => {
  const { t } = useTranslation();

  return (
    <div className="flex items-center xl:justify-end content-layout">
      <img src={AllNFTsIcon} alt="" />
      <div
        className={clsx(
          'text-18px ml-[5px] text_primary flex',
          'items-center justify-center'
        )}
      >
        {label ?? <span className="max-xl:hidden">{t('AllDocuments.total')}:</span>}
        <span className="ml-[2px]">
          {isLoading ? (
            <Spinner size="super-small" />
          ) : (
            <span className="font-bold">{total ?? 0}</span>
          )}
        </span>
      </div>
    </div>
  );
};

export default DocumentsCount;

interface AuthContentProps {
  children?: React.ReactNode;
}

const AuthContent = ({ children }: AuthContentProps): JSX.Element => {
  return (
    <div className="z-10 w-full md:w-[68%] xl:w-[69%] background_auth_content">
      {children}
    </div>
  );
};

export default AuthContent;

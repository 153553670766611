import clsx from 'clsx';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Controller, FieldValues, ControllerProps } from 'react-hook-form';

interface DatePickerInputProps<T extends FieldValues>
  extends Omit<ControllerProps<T>, 'render'> {
  label: string;
  isRequired?: boolean;
}

const DatePickerInput = <T extends FieldValues>({
  label,
  name,
  isRequired,
  control,
}: DatePickerInputProps<T>): JSX.Element => {
  return (
    <div>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value }, fieldState: { error } }) => {
          const dateValue = value ? new Date(value) : null;
          const convertedOnChangeHandler = (date: Date) => {
            const dateString = moment(date)
              .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
              .substr(0, 10);
            onChange(dateString);
          };
          return (
            <div className={clsx('mb-3px')}>
              <label
                htmlFor={name}
                className={clsx(
                  'input_label',
                  'bg-white-primary border-l-3 b py-3 px-25px md:px-7.5 text-left block',
                  'xl:px-10 xl:py-4',
                  {
                    'border-white-primary': !error,
                    'bg-red-quaternary border-red-primary': !!error,
                  }
                )}
              >
                {label}
                {isRequired && '*'}
                <div className="flex items-center justify-between">
                  <DatePicker
                    selected={dateValue}
                    onChange={convertedOnChangeHandler}
                    dateFormat="dd/MM/yyyy"
                    placeholderText="dd/mm/yyyy"
                    className={clsx(
                      'font_primary_base',
                      'block w-full h-8 xl:h-12 pr-[5px]mt-1 outline-none bg-transparent'
                    )}
                    calendarClassName="tracking-normal"
                    onKeyDown={e => {
                      e.preventDefault();
                    }}
                  />
                </div>
              </label>
              {!!error && (
                <div
                  className={clsx(
                    'error_message',
                    'bg-red-tetriary border-l-3 pl-25px border-red-primary',
                    'md:pl-7.5 py-2.5 xl:pl-12.5 text-left'
                  )}
                >
                  {error.message}
                </div>
              )}
            </div>
          );
        }}
      />
    </div>
  );
};

export default DatePickerInput;

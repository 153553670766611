import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import Logo from '@/assets/images/logo-header.svg';
import { TopNavigation } from '../TopNavigation';
import { FixedContainer } from '../FixedContainer';
import { MobileMenu } from '../MobileMenu';
import { SocialIcons } from '../SocialIcons';

const TopBar = (): JSX.Element => {
  const { t } = useTranslation();
  return (
    <FixedContainer>
      <header
        className={clsx(
          'fixed xl:static z-50 w-full bg-white-primary px-25px py-7.5 flex flex-col',
          'md:p-10 xl:px-15 xl:py-12.5 top-0'
        )}
      >
        <div className="flex max-xl:items-center justify-between">
          <img
            src={Logo}
            alt={t('Footer.justiceTechnology')}
            className="w-[219px] md:w-[316px] xl:w-[486px] xl:h-[100px]"
          />
          <div className="flex flex-col items-end justify-between">
            <SocialIcons className="hidden xl:flex" />
            <TopNavigation />
          </div>
          <MobileMenu />
        </div>
      </header>
    </FixedContainer>
  );
};

export default TopBar;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AcceptInvitationResponse,
  AuthState,
  Request2faAction,
  Request2faResponse,
  RotatePasswordAction,
  RotatePasswordResponse,
  Setup2faAction,
  Setup2faResponse,
  TokenResponse,
} from '@/types/auth';

const initialState: AuthState = {
  email: null,
  action: null,
  accessToken: null,
  refreshToken: null,
  isAuthenticated: null,
  registeredEmail: null,
  loginSessionKey: null,
  setup2FaCode: null,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setTokens: (state, { payload }: PayloadAction<TokenResponse>) => {
      const { access: accessToken, refresh: refreshToken } = payload;

      return {
        action: null,
        accessToken,
        refreshToken,
        isAuthenticated: true,
      };
    },
    clearTokens: state => {
      return {
        ...initialState,
        loginSessionKey: state.loginSessionKey,
        isAuthenticated: false,
      };
    },
    setLoginRequest2fa: (state, { payload }: PayloadAction<Request2faResponse>) => {
      const { sessionKey } = payload;

      return {
        ...state,
        action: Request2faAction,
        loginSessionKey: sessionKey,
      };
    },
    setLoginRotatePassword: (
      state,
      { payload }: PayloadAction<RotatePasswordResponse>
    ) => {
      const { sessionKey } = payload;

      return {
        ...state,
        action: RotatePasswordAction,
        loginSessionKey: sessionKey,
      };
    },
    setSetup2fa: (state, { payload }: PayloadAction<Setup2faResponse>) => {
      const { code, email } = payload;

      return {
        ...state,
        email,
        action: Setup2faAction,
        setup2FaCode: code,
      };
    },
    setIsAuthenticated: (
      state,
      { payload }: PayloadAction<{ isAuthenticated: boolean }>
    ) => {
      return {
        ...state,
        isAuthenticated: payload.isAuthenticated,
      };
    },
    setInvitationAccepted: (
      state,
      { payload }: PayloadAction<AcceptInvitationResponse>
    ) => {
      const { email: registeredEmail } = payload;

      return {
        ...state,
        registeredEmail,
      };
    },
    updateAccessToken: (state, { payload }: PayloadAction<TokenResponse>) => {
      const { access: accessToken } = payload;

      return {
        ...state,
        accessToken,
      };
    },
  },
});

export const {
  setTokens,
  setLoginRequest2fa,
  setLoginRotatePassword,
  setSetup2fa,
  updateAccessToken,
  clearTokens,
  setInvitationAccepted,
  setIsAuthenticated,
} = authSlice.actions;

import toast from 'react-hot-toast';

import { Toast } from '@/components/Toast';
import i18n from '@/translations';

const duration = 20000;

export const showErrorToast = (message?: string) =>
  toast.custom(
    t => (
      <Toast onCloseClick={() => toast.remove(t.id)} type="error">
        {message || `${i18n.t('Common.errors.somethingWentWrong')}`}
      </Toast>
    ),
    { duration }
  );

export const showSuccessToast = (message: string) =>
  toast.custom(
    t => (
      <Toast onCloseClick={() => toast.remove(t.id)} type="success">
        {message}
      </Toast>
    ),
    { duration }
  );

import clsx from 'clsx';
import TextDocumentViewer from '@/components/DocumentViewer/TextDocumentViewer';
import { Modal } from './Modal';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import ImageDocumentViewer from '@/components/DocumentViewer/ImageDocumentViewer';
import PdfDocumentViewer from '@/components/DocumentViewer/PdfDocumentViewer';
import { WebDocumentViewer } from '@/components/DocumentViewer/WebDocumentViewer';
import { isImageFile } from '@/constants/files';

interface DocumentPreviewModalProps {
  isOpen: boolean;
  onClose: () => void;
  file: File;
}

export const DocumentPreviewModal = ({
  isOpen,
  onClose,
  file,
}: DocumentPreviewModalProps) => {
  const filePath = window.URL.createObjectURL(file);
  const fileType = file.name.split('.').pop()?.toLowerCase() ?? '';

  const isImage = isImageFile(fileType);
  const isPDF = fileType === 'pdf';
  const isTxt = fileType === 'txt';

  const viewer = () => {
    if (isPDF) return <PdfDocumentViewer filePath={filePath} modalMode />;
    if (isTxt) return <TextDocumentViewer filePath={filePath} />;
    if (isImage) return <ImageDocumentViewer filePath={filePath} />;
    return (
      <WebDocumentViewer filePath={filePath} fileType={fileType} fileName={file.name} />
    );
  };

  return (
    <Modal isOpen={isOpen} title="Document preview" onClose={onClose} size="small">
      <div
        className={clsx(
          'flex justify-center items-center w-auto h-[700px] m-[40px] relative',
          'max-sm:h-[400px] max-sm:m-[10px]'
        )}
      >
        {viewer()}
      </div>
    </Modal>
  );
};

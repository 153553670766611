export const ROUTES = {
  HOME: '/',
  HELP: '/help',
  VERSION: '/version',
  LOGOUT: '/logout',
  ACCEPT_INVITATION: '/accept-invitation',
  VERIFIED_ACCOUNT: '/verify',
  NEW_PASSWORD: '/new-password',
  RESET_PASSWORD: '/reset-password',
  CONFIRM_TOKEN: '/confirm-token',
  SETUP_2FA: '/setup-2fa',
  ROTATE_PASSWORD: '/rotate-password',
  ACCOUNT: '/account',
  DOCUMENTS: '/documents',
  CREATE_DOCUMENT: '/create-document',
  DOCUMENT_DETAILS: '/documents/:documentId',
  PARTY_APPROVAL: '/documents/:documentId/party-approval',
  SHARE_VIEW: '/documents/:documentId/shared-view',
  ADMIN_DASHBOARD: '/admin-dashboard',
  USERS: '/users',
  INVITATIONS: '/invitations',
  USER_ACCOUNT: '/users/:id',
  STATISTICS: '/statistics',
  ACTIVITY: '/activity-log',
};

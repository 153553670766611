import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import { NavLink, useNavigate } from 'react-router-dom';
import clsx from 'clsx';

import { Title } from '@/components/Typography/Title';
import { Paragraph } from '@/components/Typography/Paragraph';
import { TextInput } from '@/components/TextInput';
import { Button } from '@/components/Button';
import { CircularTitle } from '@/components/CircularTitle';
import { CircularRequired } from '@/components/CircularRequired';
import { AuthContent } from '@/components/AuthContent';
import { FormData, signUpFormSchema } from '@/forms/signInForm';
import { useLogInMutation } from '../../api/authApi';
import { ErrorMessage } from '@/components/ErrorMessage';
import { ROUTES } from '@/constants/routes';
import { useAppSelector, useModal } from '@/utils/hooks';
import { TwoFactorSetupModal } from '@/components/Modal/TwoFactorSetupModal';
import { Request2faAction, RotatePasswordAction, Setup2faAction } from '@/types/auth';

const SignIn = (): JSX.Element => {
  const { t } = useTranslation();

  const requiredAction = useAppSelector(state => state.auth.action);
  const loginSessionKey = useAppSelector(state => state.auth.loginSessionKey);
  const setup2faCode = useAppSelector(state => state.auth.setup2FaCode);

  const {
    isModalOpen: is2FaModalOpen,
    openModal: open2FaModal,
    closeModal: close2FaModal,
  } = useModal();

  const [logIn, { error, isLoading }] = useLogInMutation();
  const navigate = useNavigate();

  const { handleSubmit, control, watch } = useForm<FormData>({
    resolver: joiResolver(signUpFormSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const email = watch('email');
  const password = watch('password');

  useEffect(() => {
    if (requiredAction === Request2faAction && !!loginSessionKey) {
      navigate(ROUTES.CONFIRM_TOKEN);
    }
    if (requiredAction === Setup2faAction && !!setup2faCode) {
      open2FaModal();
    }
    if (requiredAction === RotatePasswordAction && !!loginSessionKey) {
      navigate(ROUTES.ROTATE_PASSWORD);
    }
  }, [requiredAction, loginSessionKey, setup2faCode]);

  const onSubmit: SubmitHandler<FormData> = data =>
    logIn({ email: data.email.toLowerCase().trim(), password: data.password });

  return (
    <div className="flex justify-end relative">
      <CircularTitle>{t('Common.login')}</CircularTitle>
      <CircularRequired />
      <AuthContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div
            className={clsx(
              'flex w-full pt-7 justify-end flex-col-reverse',
              'xl:gap-3px xl:flex-row xl:pt-12'
            )}
          >
            <div className="flex flex-1 flex-col xl:text-center">
              <Title
                level="h2"
                className="mb-7.5 px-25px sm:px-7.5 xl:mb-10 xl:pl-[7px] ml-[2px]"
              >
                {t('SignIn.existingUsers')}
              </Title>
              <TextInput label={t('Common.email')} name="email" control={control} focus />
              <TextInput
                label={t('Common.password')}
                name="password"
                isPassword
                isRequired
                control={control}
              />
              <ErrorMessage error={error} resetOn={[email, password]} />
              <Paragraph
                className={clsx(
                  'px-[25px] sm:px-7.5 py-6 xl:px-10 xl:py-9',
                  'text-left ml-[2px]'
                )}
              >
                {t('SignIn.forgotPassword')}
                <NavLink to={ROUTES.RESET_PASSWORD} className="underline">
                  {t('SignIn.clickHere')}
                </NavLink>
              </Paragraph>
              <Button disabled={isLoading} isSubmit>
                {t('Common.login')}
              </Button>
            </div>
          </div>
        </form>
      </AuthContent>
      <TwoFactorSetupModal
        isOpen={is2FaModalOpen}
        onClose={close2FaModal}
        code={setup2faCode}
      />
    </div>
  );
};

export default SignIn;

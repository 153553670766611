import clsx from 'clsx';

interface TitleProps {
  children?: React.ReactNode;
  className?: string;
}

export const PageTitle = ({ children, className }: TitleProps): JSX.Element => {
  return (
    <h3
      className={clsx(
        'page_title',
        'text-center overflow-hidden text-ellipsis',
        'pt-[77px] max-sm:pt-[34px] max-xl:pt-[47px]',
        'mb-[75px] max-sm:mb-[35px] max-xl:mb-[49px]',
        className
      )}
    >
      {children}
    </h3>
  );
};

import { useEffect } from 'react';
import { joiResolver } from '@hookform/resolvers/joi';
import { useForm, useFieldArray, SubmitHandler } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Modal } from './Modal';
import { AddPartyFormRow } from '../AddPartyFormRow';
import { showSuccessToast } from '@/utils/toast';
import { Spinner } from '@/components/Spinner';
import { useInviteUsersMutation } from '@/api/adminUserApi';
import { InvitedUsersFormData, invitedUsersFormSchema } from '@/forms/inviteUsersForm';

interface InviteUserModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const invitedUsersLimit = 10;

export const InviteUserModal = ({ isOpen, onClose }: InviteUserModalProps) => {
  const { t } = useTranslation();

  const [inviteUsers, { isLoading, isSuccess }] = useInviteUsersMutation();

  useEffect(() => {
    if (isSuccess) {
      showSuccessToast(t('InviteUser.usersInvited'));
      onClose();
    }
  }, [isSuccess]);

  const { handleSubmit, control, trigger, reset } = useForm<InvitedUsersFormData>({
    resolver: joiResolver(invitedUsersFormSchema),
    defaultValues: {
      users: [
        {
          email: '',
          name: '',
          surname: '',
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'users',
  });

  useEffect(() => {
    if (!isOpen) reset();
  }, [isOpen]);

  const onSubmit: SubmitHandler<InvitedUsersFormData> = async formData => {
    await inviteUsers(formData);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={t('InviteUser.title')} size="medium">
      {isLoading && <Spinner fullScreen size="huge" />}
      <form onSubmit={handleSubmit(onSubmit)}>
        <AddPartyFormRow
          control={control}
          fields={fields}
          append={append}
          remove={remove}
          trigger={trigger}
          arrayName="users"
          submitButtonText={t('InviteUser.buttons.invite')}
          addButtonTitle={t('InviteUser.buttons.addUser')}
          limit={invitedUsersLimit}
          limitError={t('InviteUser.errors.usersLimit', {
            count: invitedUsersLimit,
          })}
          minNumber={1}
        />
      </form>
    </Modal>
  );
};

import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

interface RequiredProps {
  customClassName?: string;
  inline?: boolean;
}

const Required = ({ customClassName, inline }: RequiredProps): JSX.Element => {
  const { t } = useTranslation();

  return (
    <p
      className={clsx(
        'font_primary_base text-right',
        !inline && 'absolute h-14 w-30 max-sm:-top-6 -top-8 right-0',
        customClassName
      )}
    >
      * {t('Common.required')}
    </p>
  );
};

export default Required;

import { Column } from 'react-table';

import { TableCellName, TableCellStorageSize } from '@/components/TableCells/TableCells';
import i18n from '@/translations';
import { UserInfo } from '@/types/user';

export interface CustomRowProps {
  original: UserInfo;
}

export const STORAGE_STATISTIC_COLUMNS = [
  {
    Header: i18n.t('AllUsers.columns.name'),
    accessor: 'name',
    Cell: ({ row }: { row: CustomRowProps }) =>
      TableCellName(`${row.original.name} ${row.original.surname}`),
  },
  {
    Header: i18n.t('AllUsers.columns.email'),
    accessor: 'email',
  },
  {
    Header: i18n.t('AllUsers.columns.documentsSize'),
    accessor: 'documentsSize',
    Cell: ({ value }: { value: number }) => TableCellStorageSize(value),
  },
] as Column<UserInfo>[];

import { useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import AllNFTsIcon from '@/assets/icons/nfts-gray.svg';
import { ContentLayout } from '@/components/ContentLayout/ContentLayout';
import { Spinner } from '@/components/Spinner';
import SortInput, { SortOrders } from '@/components/SortInput/SortInput';
import { useGetUsersQuery } from '@/api/adminUserApi';
import { Title } from '@/components/Typography/Title';
import UserTable from '@/components/Table/UserTable/UserTable';
import TextFilter from '../../components/TextFilter/TextFilter';
import { scrollToTableTop } from '@/utils/scroll';
import { UserStatus } from '@/types/user';
import { ALL_USERS_COLUMNS } from '@/components/Table/UserTable/columns/activeUsers';

export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_PAGE_NUMBER = 1;
export const DEFAULT_USERS_SORT_FIELD = 'name';
export const DEFAULT_USERS_SORT_ORDER = 'asc';

const Users = () => {
  const { t } = useTranslation();

  const [userFilter, setUserFilter] = useState<string | undefined>(undefined);
  const [emailFilter, setEmailFilter] = useState<string | undefined>(undefined);
  const [pageNumber, setPageNumber] = useState<number>(DEFAULT_PAGE_NUMBER);
  const [sortBy, setSortBy] = useState<string>(DEFAULT_USERS_SORT_FIELD);
  const [sortOrder, setSortOrder] = useState<SortOrders>(DEFAULT_USERS_SORT_ORDER);

  const SORT_BY_OPTIONS = [
    { value: 'name', label: t('AllUsers.columns.name') },
    { value: 'email', label: t('AllUsers.columns.email') },
  ];

  const {
    data,
    isLoading: isUsersLoading,
    isFetching: isUsersFetching,
  } = useGetUsersQuery({
    filters: {
      offset: DEFAULT_PAGE_SIZE * (pageNumber - 1),
      limit: DEFAULT_PAGE_SIZE,
      sortBy,
      sortOrder,
      name: userFilter || undefined,
      email: emailFilter || undefined,
      status: UserStatus.Active,
    },
  });

  const getNextPageData = () => {
    setPageNumber(prev => prev + 1);
    scrollToTableTop();
  };

  const getPreviousPageData = () => {
    if (pageNumber > 1) {
      setPageNumber(prev => prev - 1);
      scrollToTableTop();
    }
  };

  const onUserChanged = (value: string) => {
    setPageNumber(1);
    setUserFilter(value);
    scrollToTableTop();
  };

  const onEmailChanged = (value: string) => {
    setPageNumber(1);
    setEmailFilter(value);
    scrollToTableTop();
  };

  const onSortByChanged = (value: string) => {
    setPageNumber(1);
    setSortBy(value);
    scrollToTableTop();
  };

  const onSortOrderChanged = (value: SortOrders) => {
    setPageNumber(1);
    setSortOrder(value);
  };

  return (
    <ContentLayout title={t('AllUsers.title')}>
      <div className={clsx('grid-cols-2 grid grid-rows-auto w-full pt-0')}>
        <Title level="h2" className="px-25px py-7.5 md:px-7.5 xl:py-[42px] xl:px-10">
          {t('AllUsers.activeUsers')}
        </Title>
        <div className="flex items-center justify-end content-layout">
          <img src={AllNFTsIcon} alt="" />
          <div
            className={clsx(
              'text-18px ml-[5px] text_primary flex',
              'items-center justify-center'
            )}
          >
            <span className="max-xl:hidden">{t('AllUsers.activeUsers')}:</span>
            <span className="ml-[2px]">
              {isUsersLoading ? (
                <Spinner size="super-small" />
              ) : (
                <span className="font-bold">{data?.total ?? 0}</span>
              )}
            </span>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 md:gap-x-[3px] gap-y-[3px]">
        <TextFilter
          label={t('AllUsers.filters.user')}
          placeholder={t('AllUsers.filters.userPlaceholder')}
          onSubmit={onUserChanged}
        />
        <TextFilter
          label={t('AllUsers.filters.email')}
          placeholder={t('AllUsers.filters.emailPlaceholder')}
          onSubmit={onEmailChanged}
        />
      </div>
      <div
        className={clsx(
          'grid grid-cols-1 md:grid-cols-3',
          'mt-[3px] md:gap-x-[3px] lg:mb-[37px] mb-0'
        )}
      >
        <div className="col-span-2 max-md:hidden 2xl:block"> </div>
        <div className="mt-[10px]">
          <SortInput
            label={t('AllUsers.filters.sortBy')}
            name="sortBy"
            options={SORT_BY_OPTIONS}
            sortBy={sortBy}
            setSortBy={onSortByChanged}
            sortOrder={sortOrder}
            setSortOrder={onSortOrderChanged}
            isOneLine
          />
        </div>
      </div>
      {isUsersFetching ? (
        <Spinner size="medium" />
      ) : (
        <UserTable
          columns={ALL_USERS_COLUMNS}
          data={data?.users ?? []}
          isLoadingData={isUsersFetching}
          showNextPage={getNextPageData}
          showPreviousPage={getPreviousPageData}
          total={data?.total}
          currentPageNumber={pageNumber}
          pageSize={DEFAULT_PAGE_SIZE}
        />
      )}
    </ContentLayout>
  );
};

export default Users;
